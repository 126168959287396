/* Market Place Css Start */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap");
/* font-family:'Montserrat', sans-serif; */

html body.marketplace {
  font-family: "Montserrat", sans-serif !important;
}

.left-block-w30 {
  max-width: 35%;
  width: 100%;
  background: #fff;
  padding: 0.5em;
}

form.configure-store-form .form__item p {
  padding-left: 0.75rem;
}

.right-block-w70 {
  max-width: 65%;
  width: 100%;
}

.top-small-title-black {
  font-size: 1em;
  font-weight: 700;
  color: #000;
}

h5.top-small-title-black a {
  font-size: 1em;
  font-weight: 700;
  color: #000;
}

.my-store a {
  color: #00afdb;
  font-weight: 600;
}

.site_search.inner-search .top-search input {
  border-radius: 0.2rem;
  height: 4.766em;
}

.site_search.inner-search.mt-3 {
  width: 100%;
}

.store-box .icon-img {
  text-align: center;
  width: 100%;
  height: auto;
}

.store-box {
  padding: 1em;
  color: #000;
  box-shadow: 0 3px 3px 0 rgb(218 220 230 / 60%);
  background: #fff;
  border: 1px solid #c5bebe;
  border-radius: 0.375rem;
}

.product-type ul {
  margin: 0 -0.5em;
}

.prdcr-store-title {
  text-align: center;
}

.producer-store a:hover {
  color: #000;
}

h4.prdcr-store-title {
  font-size: 0.8em;
  font-weight: 600;
  line-height: 1.2em;
  color: #000;
  margin-top: 0.4em;
}

/*.producer-store {
  margin: 1em -0.4em;
}
.producer-store .w-4\/12{
  margin: 0 0.4em;
}*/

.store-box .icon-img .fa {
  color: #00b4a3;
}

h5.my-store a {
  font-size: 0.7em;
  font-weight: 700;
}

.store-box.circle {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.5em;
}

.store-box.circle .icon-img .fa {
  margin-top: 0.8em;
}

.right-block-bg .right-banner img {
  width: 100%;
  border-radius: 0.5em;
  height: 100%;
}

.right-block-bg {
  padding: 0.5em;
}

/*.right-banner {
  height: 14em;
}*/

/* .product-list ul li {
  margin: 0.5rem;
} */

.product-title {
  color: #32a093;
  font-weight: 700;
  font-size: 1.25rem;
  margin: 1em 0;
}

.product-desc h4 {
  text-align: left;
  font-weight: 700;
  font-size: 1.125em;
  color: #000;
}

.product-desc p {
  font-size: 1.125em;
  color: #666666;
  font-weight: normal;
}

.product-type li {
  height: 100%;
}

.product-offer .product-img {
  /*height: 22.8125em;*/
  height: 18em;
  position: relative;
}

.product-offer {
  margin: 0 -0.3em;
}

.product-desc {
  margin-left: 0.5em;
  margin-right: 0.5em;
}

.product-offer .product-img img {
  height: 100%;
}

.product-offer a:hover {
  color: initial;
}

.offer-detail .offer-name {
  font-size: 1em;
  font-weight: 600;
  color: #fff;
}

.offer-detail .offer-amt {
  font-size: 1.5em;
  font-weight: 700;
  color: #cca061;
}

.offer-detail p {
  font-size: 0.7em;
  color: #fff;
}

.product-offer .offer-bg {
  position: relative;
  margin: 0 0.3em;
}

.product-offer .offer-detail {
  position: absolute;
  left: 0;
  top: 50%;
  text-align: center;
  width: 100%;
  transform: translateY(-50%);
}

.producer-store ul {
  margin-left: -0.5em;
  margin-right: -0.5em;
}

.shop-now-btn {
  width: 100%;
  margin-top: 1em;
}

.shop-now-btn a {
  background: #d3d3d3;
  padding: 0.5em 0;
  border-radius: 2em;
  color: #000;
}

.site_search.inner-search .top-search button[type="submit"] {
  left: 0.2em;
  top: 0.5em;
  height: 1.5em;
  font-size: 1.5rem;
}

.site_search.inner-search input::placeholder {
  padding-left: 1%;
}

.right-block-bg .right-banner img {
  border-radius: 0;
}

.bold-heading {
  font-size: 2em;
  font-weight: 700;
}

.mrkt-place p {
  margin-bottom: 0.2em;
}

.mrkt-place .bold-heading {
  margin-bottom: 0.5em;
}

.inner-btn-box {
  /*
  margin-top: 1em;*/
  display: flex;
  padding: 0.15em;
}

.inner-btn-box .btn-blue {
  color: #fff !important;
  border-radius: 0.3rem;
  padding: 1rem 1rem;
  font-size: 1rem;
  min-width: 9rem;
  text-align: center;
  width: 100%;
  background: #37a282;
  text-transform: none;
}

a.btn-white {
  padding: 0.8em 4em;
  color: #000;
  font-size: 0.8em;
  text-transform: uppercase;
}

.marketplace .save-btn {
  background-color: #adb5bdb8;
  color: #fff;
  padding: 0.5em 3.5em;
  border-radius: 0.5em;
  opacity: 0.5;
  margin-top: 2em;
}

.marketplace .card-title h1 {
  font-size: 1.75em;
  font-weight: 700;
  color: #2a2a2a;
}

.card-title h1 a {
  left: 0;
  font-size: 1.5em;
}

.google-map iframe {
  border-radius: 0.5em;
}

.right-banner.bg-gray-200 {
  height: 19em;
  position: relative;
}

.marketplace
  .tab-content-left-section
  .right-banner.bg-gray-200.bg--banner--img {
  /*background-image: url('https://i.pinimg.com/originals/95/3a/80/953a80e98438e470729a1d301c2464e4.jpg');*/
  background-repeat: no-repeat;
  background-size: cover !important;
  border-radius: 0.9375em;
  box-shadow: 0 1px 3px rgba(245, 245, 245, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border: 1px solid;
}

/* .profile-upload-circle {
    position: absolute;
    bottom: -22%;
    left: 10%;
    width: 9.87em;
    height: 9.87em;
    border-radius: 50%;
    border: 2px solid #fff;
    background: #ddd;
    box-shadow: 0px 3px 24px #00000026;
} */

/* .bg--img-center {
    position: relative;
    height: 9.87em;
    border-radius: 50%;
    overflow: hidden;
} */

.bg--img-center img {
  position: absolute;
  left: 50%;
  height: 100%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  object-fit: cover;
  width: 100%;
}

.right-camera-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  font-size: 0.7em;
  color: #fff;
  width: 1.9em;
  border-radius: 50%;
  height: 1.9em;
  background: #808080;
  cursor: pointer;
}

.right-camera-icon label {
  cursor: pointer;
}

.profile-upload-circle .fa {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  line-height: 2;
  font-size: 0.7em;
  color: #fff;
  width: 1.9em;
  border-radius: 50%;
  height: 1.9em;
  right: 5%;
  left: inherit;
  background: #808080;
  transform: translate(-50%, -30%);
}

.w-6\/12.col-padding {
  padding: 0 1em;
}

.mrkt-place.form .main-row {
  margin: 0 -1em;
}

/* New Market Place CSS */

.site__header.mrkt-place-header {
  background: #fff;
}

/*.marketplace .banner__form{
  background: #fff;
}*/

.marketplace .site__width {
  width: 100%;
}

.marketplace .form__item label {
  text-align: left;
  font-weight: 600;
  text-transform: unset;
}

.marketplace .configure-store-form .form__item input {
  border-radius: 0.5em;
  /* border: 1px solid #ddd; */
  background: #fff;
  height: 3em;
}

.marketplace .form__item select {
  border-radius: 0.5em;
  border: 1px solid #ddd;
  background: #fff;
  height: 3em;
  font-size: 0.9rem;
}

.marketplace .form__item input::placeholder {
  font-size: 0.8em;
  color: #2a2a2a;
}

.marketplace .form__item select::placeholder {
  font-size: 0.8em;
}

.btn-blue-bg button {
  background: #4bb3fd;
  color: #fff;
  padding: 0.89em 1.269em;
  border-radius: 0.5em;
  width: 100%;
}

.reset a {
  color: #a1a1a1;
  font-size: 0.875em;
  font-weight: normal;
}

.product-desc.no-margin {
  margin: 0 0.5em;
}

.newly li .product-img {
  margin: 0 0.5em;
}

.product-type .newly {
  margin: 0 -1em;
}

/*.newly li{
  padding: 0 1em;
}*/

.shop-now-btn span {
  background: #ddd;
  padding: 0.8em 1em;
  border-radius: 2em;
  font-size: 0.7em;
  color: #000;
}

.marketplace .top-search button[type="submit"] {
  left: 0;
  top: 0.125rem;
}

.marketplace .site_search input {
  padding-left: 3.5em;
  height: 2.5rem;
  border-radius: 0.5em;
}

.marketplace .icon-bg {
  background: #e5e5e5;
  width: 2.5em;
  height: 2.5em;
  border-radius: 50%;
}

.marketplace .icon-bg .fa {
  color: #616161;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 0.8em;
}

.marketplace .noti {
  background: #4bb3fd;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.6em;
  padding: 0.7em;
  position: absolute;
  right: 0;
  top: 0;
}

.marketplace .country-flag {
  border: 1px solid #ddd;
  margin-left: 1em;
}

.marketplace ul.main_menu-list.flex li {
  padding: 0.4em;
}

.marketplace .banner-heading h4 {
  font-size: 1.875em;
  font-weight: 700;
  max-width: 20.2em;
  width: 100%;
  text-align: right;
}

.marketplace .banner-heading {
  padding-bottom: 2em;
}

.marketplace .site-align.justify-content-center {
  justify-content: center;
}

.page-haeding {
  font-size: 1.75em;
  font-weight: 600;
  text-align: center;
  color: #2a2a2a;
  margin-bottom: 0.3em;
}

.page-padding {
  /* padding: 4em 0; */
  margin: auto;
  max-width: 36.375em;
}

.page-padding p {
  color: #818181;
}

/*ul.Pay-Box li {
  max-width: 33.3333%;
  background: #fff;
  height: 11.125em;
  display: flex;
  justify-content: center;
  align-items: center;
}*/

.btn--w.btn-blue-bg a {
  background: #4bb3fd;
  padding: 1.099em 1em;
  color: #fff !important;
  border-radius: 0.5em;
  min-width: 12rem;
  display: inline-block;
  text-align: center;
}

.btn--w.btn-blue-bg {
  /* max-width: 30.5em; */
  text-align: right;
  width: 100%;
}

ul.Pay-Box li {
  max-width: 11.125em;
  width: 100%;
  background: #fff;
  height: 11.125em;
  box-shadow: 0px 16px 32px #919eab3d;
  margin: 0 0.5em;
  padding: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

ul.Pay-Box li .icon img {
  margin: auto;
}

ul.Pay-Box li h4 {
  margin-top: 1em;
}

.Pay-Box .icon .fa {
  color: #4bb3fd;
}

.Pay-Box.mt-15 {
  margin-top: 5em;
  margin-left: -0.5em;
  margin-right: -0.5em;
}

.configure {
  max-width: 76em;
  margin: auto;
}

.bg--white {
  background: #fff;
  padding: 1rem;
}

.marketplace .main__content {
  padding-top: 3.5rem;
}

.marketplace .configure h1 {
  font-size: 1.75em;
  font-weight: 700;
  color: #2a2a2a;
}

.marketplace .menu-list-item .fa {
  color: #a1a1a1;
}

.site__banner.banner-wrapper {
  position: relative;
  /* height: 27.5em;
    overflow: hidden; */
}

.site__banner.banner-wrapper .banner__image {
  position: relative;
  height: 21rem;
  overflow: hidden;
}

.marketplace .banner__image img {
  width: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  object-fit: cover;
  border-radius: 0.5rem;
}

/* .marketplace .banner__image img {
    width: 100%;
    position: absolute;
    left: 50%;
    left: 5;
    top: 50%;
    transform: translate(-50%, -50%);
} */

.top_left-header.site-align.w-full {
  justify-content: initial;
}

/* .configure-store-form .form__item{
  border: 2px solid #e8e8e8;
  border-radius: 0.3125em;
} */

.configure-store-form .form__item input {
  /* border: none; */
  padding-top: 0;
  height: auto;
}

.configure-store-form .form__item label,
textarea {
  /* padding-left: 0.75rem;
    padding-right: 0.75rem; */
  padding-top: 0.75em;
  color: #666666;
  font-size: 0.875em;
}

.configure-store-form .form__item input::placeholder {
  color: #000;
  font-size: 1em;
  font-weight: 700;
}

.configure-store-form .form__item select {
  color: #000;
  font-size: 1em;
  font-weight: 700;
}

.configure-store-form textarea {
  min-height: 14.643em;
  @apply h-full bg-white;
}

.add-product-page .form__item label {
  @apply px-0;
}

/* .configure-store-form .form__item input, .form__item select, .form__item textarea, .form__item button{
  border-bottom: none;
} */

.marketplace .configure-store-form .form__item select {
  /* border: none; */
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  height: 2.65rem;
  font-weight: 400;
  color: #9ca3af;
}

.configure.inner-box-padding {
  padding: 0.9375em;
  background: #fff;
  border-radius: 0.9375em;
}

.add--cover--photo {
  height: 14.6875em;
  border: 1px solid #e8e8e8;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.3125em;
  padding: 0.5rem;
  background: #fff;
}

.add--cover--photo .fa {
  color: #5b5b5b;
  margin-right: 1.4375em;
  font-size: 2em;
}

.add--cover--photo input {
  display: none;
}

.add--cover--photo label {
  display: flex;
  align-items: center;
  cursor: pointer;
  /*font-size: 1.5em;*/
}

.hide-input input {
  display: none;
}

.w-full.col-padding {
  padding: 0 1em;
}

.mrkt-place-product {
  margin-left: -1em;
}

.font--23 {
  font-size: 1.4375em;
  font-weight: 700;
}

.mrkt-place-product.mt-5 .mrkt-place.form {
  padding: 2.5em 0 0 1em;
}

.mrkt-place-product .form__item label,
textarea {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

.mrkt-place-product .form__item select {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

.marketplace.add-product .mrkt-place-product input {
  padding-top: 1.45em;
  padding-bottom: 1.45em;
}

.marketplace.add-product .mrkt-place-product select {
  padding-top: 1.45em;
  padding-bottom: 1.45em;
}

.marketplace.add-product .mrkt-place-product select option {
  color: #666666;
  font-weight: 400;
}

.marketplace.add-product .mrkt-place-product input::placeholder {
  color: #666666;
  font-weight: 400;
}

.user__pic {
  max-width: 4em;
  width: 100%;
  height: 4em;
  border-radius: 50%;
}

.user__pic img {
  border-radius: 50%;
}

.mrkt-place-product .user__pic {
  width: 4em;
  height: 4em;
  border-radius: 50%;
}

.mrkt-place-product .font--23 {
  padding-left: 1.5625em;
}

.mrkt-place-product .user__pic img {
  width: 4em;
  height: 4em;
  border-radius: 50%;
}

.width--submitation {
  max-width: 52.4375em;
  width: 100%;
  margin: auto;
  text-align: center;
  background: #fff;
  box-shadow: #919eab3d;
  border-radius: 0.625em;
  padding: 4em;
}

.width--submitation .icon {
  margin-bottom: 2.25em;
}

.width--submitation .fa {
  color: #37a282;
  font-size: 7.5em;
}

.width--submitation h3 {
  font-size: 1.75em;
  color: #2a2a2a;
  font-weight: 600;
  font-family: "Montserrat", sans-serif !important;
}

.marketplace.submitation .page-padding {
  max-width: 100%;
}

.inner-btn-box.btn-style-2 .btn-blue {
  color: #fff;
  border-radius: 0.3em;
  padding: 1.25em;
  font-size: 0.875em;
  min-width: 17.572em;
  text-align: center;
}

/*New Css*/

body.marketplace {
  background: #fff;
}

.product-title span {
  color: #2a2a2a;
  font-weight: normal;
}

p.rating span {
  background: #ffc847;
  color: #fff;
  padding: 0.5em;
  font-size: 0.75em;
}

p.rating span .fa {
  font-size: 0.75em;
}

p.food-product {
  background: #84ff76;
  padding: 0.5em 1.5em;
  font-size: 0.75em;
}

.store-name p strong {
  color: #666666;
}

.store-name p .fa {
  color: #37a282;
}

a.back__btn {
  color: #2a2a2a;
}

.our__gallery {
  margin: 7.25em 0;
}

.slider__gallery img {
  border-radius: 0.9375em;
  height: 100%;
}

/* .slider__gallery ul li {
    max-width: 10.25%;
    height: 5em;
    width: 100%;
    margin: 0 0.5em;
} */

.product-title.black {
  color: #2a2a2a;
}

.reviw-border {
  border-right: 1px solid #ddd;
  padding-right: 1em;
}

.blue-btn-full a {
  background: #4bb3fd;
  color: #fff;
  border-radius: 0.5em;
  width: 100%;
  line-height: 3em;
  text-align: center;
}

.contact-icon ul li {
  text-align: center;
}

.contact-icon ul {
  display: flex;
  justify-content: space-between;
  margin: 1.5em 0;
}

.icon__circle {
  width: 3em;
  height: 3em;
  border: 1px solid #4bb3fd;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-icon h6 {
  color: #000000;
  font-size: 0.875em;
  font-weight: 700;
}

.font-25 {
  font-size: 1.5625em;
}

.font-20 {
  font-size: 1.25em;
}

.product-title.black.mt--0 {
  margin-top: 0;
}

body.marketplace span.user__name a {
  color: #000;
  font-weight: 700;
}

body.marketplace .mb--0 {
  margin-bottom: 0;
}

body.marketplace .icon__circle .fa {
  color: #4bb3fd;
}

body.marketplace .large_image {
  margin-top: 2.25em;
  border: 1px solid #ddd;
  border-radius: 0.9375em;
}

.about__product h4 {
  margin: 0.25em 0;
}

.about__product ol {
  padding-left: 1em;
}

body.marketplace.add-product.bg-gray {
  background: #f2f2f2;
}

.mrkt-place.form .form__item:not(:last-child) {
  margin: 1.25rem 0;
}

.mrkt-place.form form label {
  color: #000000;
  font-weight: 700;
}

.inner-btn-box.right-btn button {
  width: auto;
}

.marketplace .tabs__wrapper span.btn {
  padding-top: 1rem;
  padding-bottom: 1rem;
  justify-content: space-evenly;
}

.marketplace .tabs__wrapper span.btn {
  max-width: 13.178rem;
}

.marketplace .tabs__wrapper em {
  font-style: normal;
  font-size: 0.935rem;
  color: #000;
}

.marketplace .tabs__wrapper {
  display: flex;
}

.marketplace .tabs__wrapper {
  margin-bottom: 0;
  border-bottom-width: 0;
}

.marketplace .tabs__wrapper span.btn.active-button {
  background: #fff;
}

/* .marketplace .tabs__wrapper span.btn {
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
    background: #e8e7e7;
    border-bottom: 0;
     max-width: 13.178rem;
    width: 100%;
    text-align: center;
    padding-top: 1.20rem;
    padding-bottom: 1.20rem;
    margin-right: 0.7rem;
    font-size: 1rem;
    font-weight: 600;
    display: flex;
    align-items: center;
} */

.marketplace .tab-content-left-section {
  width: 100%;
}

.marketplace .middle-long__section-block {
  margin-left: 0;
}

.marketplace .banner__form input {
  background: transparent;
  border-radius: 0.5em;
  border: 1px solid #ddd;
  background: #fff;
  height: 3em;
}

.marketplace.mrkt-place-three .site__banner.banner-wrapper {
  height: auto;
}

.marketplace.mrkt-place-three .site__banner.banner-wrapper .banner__content {
  position: inherit;
  top: inherit;
  bottom: inherit;
  left: inherit;
  right: inherit;
}

.marketplace.mrkt-place-three .active-tab {
  padding-bottom: 0;
}

.marketplace.mrkt-place-three .tabs__wrapper span.btn.active-button {
  background: #37a282;
}

.marketplace.mrkt-place-three .tabs__wrapper span.btn.active-button svg path {
  fill: #fff;
}

.marketplace.mrkt-place-three .tabs__wrapper span.btn.active-button em {
  color: #fff;
}

.marketplace h4.product-title.black.mt-0 {
  margin-top: 0;
}

.marketplace p {
  /* color: #818181; */
  color: #000;
}

.marketplace .mb--5 {
  margin-bottom: 4.5em;
}

.marketplace.mrkt-place-four .store__detail {
  margin-top: 0.5em;
}

.page_w_right .prdcr-store-title {
  font-size: 1.375em;
}

.review {
  align-items: center;
}

.reviw-border:not(:first-child) {
  padding-left: 1em;
}

.review.justify-start {
  justify-content: start;
}

.our__gallery.mt--5 {
  margin: 3em 0;
}
/* 
.flex.justify-between.items-center a {
  color: #000;
} */

.product-type.pt--5 {
  margin-top: 4em;
}

.marketplace
  .tabs__wrapper
  span.btn.active-button.active-shadow
  span.iconWidth
  svg
  path {
  fill: #fff;
}

.marketplace
  .tabs__wrapper
  span.btn.active-button.active-shadow
  span.iconWidth {
  width: 2rem;
  height: 2rem;
  background: #37a282;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/*.mt--2.mb--2 {
    margin-bottom: 0.25em;
}*/
