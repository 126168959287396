@tailwind base;
@tailwind components;
@tailwind utilities;

/* npx tailwindcss-cli@latest build src/assets/tailwind.css -o src/assets/style.css */
/* Global styling */
html{
	@apply text-base;
}
.text-color{
	color: #212529;
}
.form--color{
	color: #495057;
}
.title-color{
	color: #004577;
}
.title-color-2{
	color: #868e96;
}
.light-border-color{
	border-color: #e1e1e1;
}
.ex-light-border{
	border-color: #f2f2f2;
}
.border-color-2{
	border-color: #e8e8e8;
}
.md-border-color{
	border-color: #dfdfdf;
}
.ex-light-color{
	color: #f2f2f2;
}
.ex-bg-light-color{
	background-color: #f2f2f2;
}
.bg-light-color{
	background-color: #f6f6f6;
}
.bg-light-color-5{
	background-color: #fafafa;
}
.bg-color-blue,
.main__content a.bg-color-blue{
	background-color: #4bb3fd;
	color: #fff;
}
.bg-bright-blue{
	background-color: #007bff;
	color: #fff;
}
.light-color-menu{
	color: #e8e8e8;
}
.bg-light-color-2{
	background-color: #eBeBeB;
}
.text-green-color{
	color: #37a282;
}
.border-green-color{
	border-color: #37a282;
}
.text-muted{
	color: #6c757d;
}
.h-15{
	height: 3.75rem;
}
body{
	@apply text-color ex-bg-light-color relative min-h-screen;
}
#root{
	@apply relative;
}
.site__width{
	@apply w-full max-w-6xl m-auto h-full;
}
.green-gradient{
	background: #33a386;
    background: -moz-linear-gradient(-45deg, #33a386 0%, #33a386 30%, #2f97c1 100%);
    background: -webkit-linear-gradient(-45deg, #33a386 0%,#33a386 30%,#2f97c1 100%);
    background: linear-gradient(135deg, #33a386 0%,#33a386 30%,#2f97c1 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#33a386', endColorstr='#2f97c1',GradientType=1 );
}
.bg-site__blue{
	@apply text-white;
	background-color: #004577;
}
.bg-royal{
	background: #004577;
}
.royal-color{
	color: #2f97c1;
}
.site-align{
	@apply flex items-center justify-between h-full;
}
.site-align.top-align{
	@apply items-start;
}
a.btn{
	@apply rounded-2xl py-2 px-3 leading-none inline-block; 
}
a, button, input[type="submit"]{
	@apply transition-all;
}

/* Form Styling */
.form__item label {
    @apply block mb-1 text-sm uppercase;
}
.form__item {
	@apply mb-6;
}
.form__item input,
.form__item select,
.form__item textarea,
.form__item button{
	@apply form--color w-full bg-light-color py-2 px-3 border-b-2 border-solid md-border-color;
}
.form__item input[type="checkbox"],
.form__item input[type="radio"] {
    @apply w-auto;
}
.form__item input[type="checkbox"] + label,
.form__item input[type="radio"] + label {
    @apply ml-2 mb-0;
}
button:focus, input:focus, optgroup:focus, select:focus, textarea:focus{
	@apply outline-none; 
}
.select--field{
	@apply relative bg-light-color;
}
.select--field select{
	@apply relative bg-transparent z-40 appearance-none;
}
.select--field .select--field-arrow{
	@apply absolute flex inset-y-0 items-center px-3 right-0 text-gray-700 cursor-pointer;
}
.select--field .select--field-arrow.up-arrow{
	@apply transform rotate-180;
}
.form-desc {
	@apply text-sm my-2.5;
}
.pwd-hints {
	@apply flex items-center justify-between my-2 text-center;
}
.hint-list {
	@apply bg-light-color py-6 px-9 text-sm;
}
.hint-list i.far.fa-check-circle {
	@apply block mb-2;
}
.form__action button{
	@apply bg-blue-500 bg-site__blue uppercase rounded-2xl font-semibold shadow-none;
	font-size: .625rem;
}
.form__item.form__checkbox {
    @apply flex;
}
.form__item.form__checkbox input{
    @apply w-8 h-8;
}
label a{
	@apply royal-color;
}
.form__grid{
	@apply flex items-center justify-between w-full;
}
.form__grid .form__item{
	max-width: 48%;
}

/* Header Styling */
.site__header{
	@apply bg-white h-15 w-full fixed z-50 top-0;;
}
.site__logo{
	@apply w-24;
}
.site__logo img{
	@apply w-full;
}
.login__form form{
	@apply flex items-center;
}
.login__form .form__item{
	@apply mx-1 mb-0;
}
.login__form input, .login__form optgroup, .login__form select, .login__form textarea{
	@apply py-1.5 text-base h-10 border-transparent bg-gray-100 rounded-lg; 
	min-width: 15rem;
}
.login__form button{
	@apply py-1.5 text-base h-10 border-transparent rounded-lg w-32; 
}
.form__forgot-link{
	@apply text-white ml-5 text-base;
	color: #818181;
}

/* Footer Styling */
.site__footer{
	@apply bg-royal royal-color pt-10 pb-10 absolute bottom-0 w-full;
}
.site__footer ul{
	@apply site-align;
}
.footer-nav{
	@apply w-full max-w-xs;
}
.footer-social--nav{
	@apply w-full max-w-lg;
}
.footer-social--nav li a{
	@apply site-align;
}
.footer-social--nav li a i{
	@apply text-xl mr-1 leading-none;
}

/* Main Content Styling */
.main__content{
	@apply pt-12 pb-20 relative;
}
.register_form {
	@apply max-w-lg mx-auto my-16;
}
.card--block {
	@apply border-solid border rounded-md bg-white p-12;
}
.card--block + .card--block {
	@apply mt-6;
}
.main-forms-list{
	@apply text-center;
}
.card-title h1{
	@apply title-color text-3xl font-semibold mb-2 relative pl-14;
}
.main-forms-list.card-title h1{
	@apply pl-0;
}
.card-title h1 a{
	@apply text-muted text-3xl font-semibold mb-2 absolute left-5;
}
.card-title h2{
	@apply title-color-2 text-base font-semibold;
}
.card-list{
	@apply mt-6 border rounded-md border-solid ex-light-border overflow-hidden;
}
.card-list li{
	@apply border-b border-solid ex-light-border;
}
.card-list li:last-child{
	@apply border-b-0;
}
.card-list li a{
	@apply text-xs text-color p-3 flex w-full justify-between items-center;
}
.card-list li a:hover{
	@apply ex-bg-light-color;
}

/* Register Forms */
hr{
	@apply my-4;
}
.small-text{
	@apply text-xs text-muted mb-2;
}
.form-check {
    @apply flex items-center;
}
.form-checkbox-container {
	@apply border border-solid md-border-color p-4;
}
.form-checkbox-item {
	@apply py-3 px-8;
    background: #e9ecef;
    margin: 0 -1rem;
}
.form-checkbox-item + .form-checkbox-item {
	@apply mt-2;
}
.form-checkbox-title {
	@apply font-semibold mb-1 text-sm;
}
.form-checkbox-contents {
	@apply ml-4;
}
.form-checkbox-blocks{
	@apply hidden;
}
.selected .form-checkbox-blocks{
	@apply block;
}
.card--block .form__action {
	@apply text-center;
}
.form__action.left-button {
	@apply text-left;
}
.card--block .form__action button{
	@apply text-base w-4/5;
}
.card--block .form__action.left-button button{
	@apply text-xs w-auto py-1.5;
}

/* after login header */
.site_menu ul {
	@apply site-align light-color-menu ml-6;
}
li.menu-list-item {
	@apply mr-4 relative;
}
li.menu-list-item:last-child{
	@apply mr-0;
}
.main_menu-list li.menu-list-item a{
	font-size: 0.93rem;
	color: #5c6771;
}
.min_menu-list li.menu-list-item a{
	color: #5c6771;
}
span.user_profile-pic {
    @apply overflow-hidden flex rounded-full mr-2 w-8;
}
.site_search{
	@apply relative w-52;
}
.top-search input{
	@apply pt-1 pb-1.5 pl-2 pr-10 w-full rounded-2xl bg-light-color-2 leading-none;
	font-size: 0.8rem;
}
.top-search button[type="submit"]{
	@apply w-8 h-8 overflow-hidden bg-transparent absolute right-0 text-xs;
	top: -0.125rem;
	color: #979797;
}

.drop__messages{
	top: 2.7rem;
	width: 17rem;
	@apply hidden absolute -right-4 bg-white border border-solid border-color-2;
}
.drop__messages:before{
	content: '';
	border-left: 0.625rem solid transparent;
	border-right: 0.625rem solid transparent;
	border-bottom: 0.625rem solid #f2f2f2;
	top: -0.7rem;
	@apply absolute right-4 w-0 h-0;
}
.drop__list-open .drop__messages{
	@apply block;
}
.drop__title{
	@apply p-3 ex-bg-light-color title-color-2;
}
.drop__messages .msg_listing{
	min-height: 14rem;
	@apply m-0 max-h-96 overflow-auto flex-wrap items-start;
}
.drop_list-item{
	@apply w-full title-color-2;
}
.drop_list-item a{
	@apply block py-3.5 px-3 border-b border-solid border-color-2 text-sm w-full;
}
.drop_list-item a:hover{
	@apply bg-light-color;
}
.drop_list-item .user_name{
	@apply royal-color;
}
.drop_list-item .user_name:hover{
	@apply title-color-2;
}
.view-all-btn{
	@apply p-2 title-color-2 border-t border-solid border-color-2 text-center text-sm;
}
.view-all-btn a:hover{
	@apply royal-color;
}
span.noti_user-name {
    @apply flex items-center font-semibold relative pl-4;
}
span.noti_user-name img {
	@apply w-8 rounded-full mr-2;
}
.noti_msg {
	@apply uppercase text-xs font-semibold mt-1;
}
.noti_user-name:before{
	content: '';
	@apply w-2 h-2 rounded-full bg-royal absolute left-0;
}
.user_profile-drop{
	@apply ex-bg-light-color py-4 text-center title-color-2;
}
.user_profile-drop img{
	@apply w-20 rounded-full inline-block;
}
.user_profile-drop .user_profile-name span{
	@apply block title-color font-semibold mt-2;
}
.drop__messages .user__profile-options{
	@apply block mt-6 ml-0 mb-0;
}
.drop__messages .user__profile-options .profile_list{
	@apply mx-4;
}
.drop__messages .user__profile-options .profile_list a{
	@apply block py-3 pl-8 pr-3 title-color-2;
}
.drop__messages .user__profile-options .profile_list a i{
	@apply mr-2;
}
.drop__messages .user__profile-options .profile_list a:hover{
	@apply ex-bg-light-color;
}
.drop__messages .logout_button{
	@apply m-4 border-t border-solid ex-light-border pt-4 pb-1 text-right;
}
.drop__messages .logout_button a{
	@apply py-2 px-4 inline-block rounded-2xl bg-color-blue uppercase font-semibold text-sm;
}
.drop__messages .logout_button a:hover{
	@apply opacity-80;
}

.user__pic img{
	@apply w-16 rounded-full mr-2;
}


.main__content a{
	@apply royal-color font-semibold;
}
.main__content a:hover{
	@apply text-black;
}
/* Left section block */
.left__section-block{
	max-width: 24%;
	@apply w-full;
}
.left__section-block .card--block{
	@apply p-0 border-0;
}
.left__section-block .user_profile-detail{
	@apply bg-light-color-2 p-4 flex items-center;
}
.left__section-block .user__pic{
	@apply w-10;
}
.left__section-block .user__name{
	@apply text-sm ml-3;
}
.left__section-block .user__name span{
	@apply block;
}
.user_profile--status{
	@apply p-4;
}
.user__connections a i{
	@apply text-green-color;
}
.user__connections a span{
	@apply font-semibold;
}
.user__concerns ul li{
	@apply mt-1;
}
.user__concerns ul li a{
	@apply flex items-center;
}
.user__concerns ul li a .nav-icon{
	@apply w-10 mr-3;
}

/* Middle section block */
.middle__section-block{
	max-width: 49%;
	@apply w-full;
}
.middle__section-block .card--block{
	@apply p-6 border-0;
}
.middle-long__section-block .card--block{
	@apply p-6;
}
form.feed-post-form{
	@apply p-4 relative border-2 border-solid border-green-color rounded-md; 
}
form.feed-post-form textarea{
	@apply w-full;
}
form.feed-post-form hr{
	@apply mt-0;
}
form.feed-post-form input[type="file"]{
	@apply w-16 absolute h-6 cursor-pointer;
    text-indent: -200rem;
    left: 1rem;
}
form.feed-post-form i,
form.feed-post-form input[type="file"] + label{
	@apply royal-color text-sm;
}
.middle__section-block .card--block + .card--block{
	@apply border-l-4 border-solid border-green-color pb-0;
}
.feed-user-info{
	@apply flex items-center;
}
.middle-long__section-block .user__pic,
.middle__section-block .user__pic{
	@apply w-12 mr-3;
}
.user__detail-data span{
	@apply block;
}
.user__detail-data span.time_posted{
	@apply text-sm;
}
.user__feed {
    @apply my-4;
}
.user__feed-item {
    @apply mb-0;
}
.user__feed-nav{
	@apply my-2;
}
.user__feed-nav a{
	@apply inline-block ex-bg-light-color py-1 px-2;
	color: #DD2E44;
}
.user__comment-feed {
	@apply border-t border-solid border-color-2;
}
ul.cmnt-tab {
	@apply flex items-center;
}
.cmnt-tab li a {
	@apply p-3 block text-sm;
}
.cmnt-tab li a:hover {
	@apply royal-color bg-light-color-5;
}
.user__comment-feed--list {
	@apply -mx-6 p-6 border-b border-solid border-color-2;
}
/*.user__comment-feed--list:last-child {
	@apply pb-0 border-b-0;
}*/
.feed-list--user {
	@apply flex;
}
.feed-list--user + .feed-list--user {
	@apply mt-6;
}
.feed_msg-detail .feed-cmnt {
	@apply p-3 bg-light-color-5 rounded-md inline-block text-sm;
}
span.feed-post-time {
	@apply block mt-2 text-sm title-color-2;
}
.feed_msg-detail {
	@apply w-full;
}
.cmnt-feed-listing{
	@apply mb-6;
}
.feed-cmnt-form {
    @apply flex items-start text-sm;
}
.feed-cmnt-form .user_pic {
	@apply rounded-full overflow-hidden mr-3;
    width: 2.678rem;
}
form.cmnt-form {
    @apply p-3 border border-solid border-color-2 w-full rounded-md;
}
form.cmnt-form textarea{
    @apply w-full;
}
.post-icons-option {
	@apply text-right;
}
.post-icons-option a {
	@apply text-black mx-1;
}
.post-icons-option a:hover {
	@apply royal-color;
}

/* Right section block */
.right__section-block, .tab-content-right-section{
	max-width: 24%;
	@apply w-full;
}
@media screen and (min-width: 1921px){
	html{
		font-size: 0.875vw;
	}
}
/* Profile page styling */
.profile__cover {
    @apply relative;
}
.cover__pic {
	@apply overflow-hidden relative -mt-10 mb-6;
	height: 24em;
}
.cover__pic img {
	@apply w-full absolute;
    transform: translate(0, -50%);
    top: 50%;
}
form.cover__upload,
form.profile__upload{
	@apply absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
form.cover__upload input[type="file"],
form.profile__upload input[type="file"]{
	@apply hidden;
}
form.cover__upload label,
form.profile__upload label{
	@apply hidden bg-color-blue text-white w-40 py-2 text-center px-4 cursor-pointer uppercase text-xs transition-all;
    border-radius: 5em;
}
.profile__cover:hover form.cover__upload label,
.user__pic:hover form.profile__upload label{
	@apply block;
}
form.cover__upload label:hover,
form.profile__upload label:hover{
	@apply bg-site__blue;
}

.profile__left-section{
	@apply pt-20;
}
.profile__left-section .user_profile-detail{
	@apply bg-transparent block;
}
.profile__left-section .user_profile-detail .user__pic{
	@apply w-11/12 mx-auto -mt-40 relative;
}
.profile__left-section .user_profile-detail .user__pic:hover:before{
	@apply absolute bg-black opacity-50 absolute rounded-full;
    content: '';
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.profile__left-section .user_profile-detail .user__pic img{
	@apply w-full;
}
.profile__left-section .user__name {
	@apply ml-0;
}
.profile__left-section .user__name span{
	@apply text-xl;
}
.profile__left-section .user__name .user_cate{
	@apply bg-bright-blue font-semibold text-xs inline-block rounded-xl px-2;
}
.block--section{
	@apply relative p-4;
}
.block__title {
    @apply font-semibold pr-6;
}
form.product__upload {
    position: absolute;
    top: 1rem;
    right: 1rem;
}
form.product__upload input{
    @apply hidden;
}
form.product__upload label{
    @apply cursor-pointer;
}
.edit__profile {
	@apply flex text-xs uppercase cursor-pointer;
}
.edit__profile span {
    @apply mt-1 bg-color-blue py-2 px-4 leading-none;
}
.edit__profile span:hover {
    @apply opacity-80;
}
span.edit__profile-btn {
    border-radius: 1rem 0 0 1rem;
}
span.edit__pics {
    border-radius: 0 1rem 1rem 0;
}
.edit__options .profile__upload{
	top: inherit;
	left: inherit;
	transform: inherit;
	@apply bg-white shadow z-10;
}
.edit__options .profile__upload label{
	@apply block bg-transparent border-b border-solid text-black text-left;
	border-radius: 0;
	width: 15em;
}
.img_container{
	@apply relative mt-4;
}
.img_container img{
	@apply w-full;
}
span.featured-product-delete {
    @apply absolute bg-white py-1 px-2 rounded-full right-0 top-0 text-red-500 hidden cursor-pointer;
    transform: translate(50%, -50%);
}
.img_container:hover span.featured-product-delete{
	@apply block;
}
/* Tabs styling */
.middle-long__section-block {
    @apply w-full ml-6;
}
.tabs__wrapper {
	@apply border-b border-solid md-border-color mb-6;
}
span.btn {
	@apply p-4 inline-block leading-none cursor-pointer border-b-4 border-solid border-transparent;
}
span.btn.active-button,
span.btn.active-button:hover{
    border-bottom: 4px #96d1fc solid;
    border-radius: 0;
    color: #96d1fc;
}
.tab {
	@apply hidden;
} 
.active-tab, .active-button{
	transition:transform 0.2s,background 0.2s,color 0.2s;
}  
.active-tab{
	@apply flex z-50;
}

/*.tab-content-right-section {
    @apply w-1/3 ml-6;
}*/

.tab-content-left-section {
	@apply w-2/3 relative;
}

.options-list-item {
	@apply pb-3 mb-3 border-b border-solid ex-light-border;
}
.options-list-item:last-child {
	@apply border-b-0 pb-0 mb-0;
}
.options-list-item .list-title{
	@apply title-color;
}
.options-list-item .list-item-name{
	@apply text-muted;
}
.badge{
	@apply text-color font-bold ex-bg-light-color text-xs rounded-full py-1 px-2 leading-none inline-block;
}

.middle-long__section-block .coming-soon {
	@apply text-center mt-20;
}
.middle-long__section-block .coming-soon span.card--block{
    @apply bg-white inline-block px-6 py-2 mx-auto;
}

.card-title h5{
    @apply title-color-2 capitalize border-transparent pb-3 font-semibold;
}
.contact-section .options-list-item .list-title{
	@apply text-black;
}
.contact-edit__form{
	@apply w-full;
}
.contact-edit__form input{
	@apply py-3 text-sm;
}
.contact-section .card--block .form__action{
	@apply text-left;
}
.contact-section .card--block .form__action button{
	@apply w-auto m-0 leading-none text-sm;
}
.contact-section span.edit__profile-btn {
    @apply absolute m-0;
	border-radius: 1rem;
    right: 1.5rem;
    top: 1.5rem;
}
.form-visible + .contact-detail_list{
	@apply hidden;
}

/* Membership page css */
.big_title {
	@apply text-4xl mb-6;
}
.big_text {
	@apply text-xl font-light;
}
.left-long__section-block {
	@apply w-2/3;
}
.latest_noti {
	@apply mt-6;
}
.noti_list li {
	@apply border-0 flex items-center;
}
.latest_noti .noti_list li{
	@apply border-solid border-t mb-6 pt-6;
    border-color: #dee2e6;
}
.noti_icon {
	@apply text-4xl mr-6;
}
.noti_time,
.noti_desc{
	@apply mb-1;
}
.noti_time {
	@apply text-black flex items-center;
}
.noti_time i.fas {
	@apply mx-2;
	font-size: .4rem;
}
.noti_content {
	@apply w-full;
}
.noti_status {
	@apply text-xs uppercase;
}
.left-long__section-block + .right__section-block{
	width: 100%;
	max-width: 30%;
}
.small-padding .card--block{
	@apply p-6;
}
.medium_title{
	@apply text-2xl mb-2 font-medium;
}

/* banner css */
.banner_slider {
    @apply mt-12;
}
.banner_slider ul {
	@apply flex justify-center px-12;
}
.banner_slider ul li {
	@apply mx-3;
    max-width: 6rem;
}
.slider_img {
	@apply rounded-full overflow-hidden w-20 mx-auto mb-3;
}
.slider-title {
	@apply font-semibold text-xl leading-none;
}

/* recipe pages */

.round-full{
  border-radius: 100%;
}

.html{
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.fltl{
  float: left;
}

.fltr{
  float: right;
}

.rec_mar{
  margin-bottom: 2rem;
  padding-bottom: 2rem;
  border-bottom: 2px solid #e8e8e8;
}

.rec_canc a{
  font-weight: 800;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.rec_data-cir p{
  --tw-border-opacity: 1;
  border-color: rgba(5, 150, 105, var(--tw-border-opacity));
  border-radius: 9999px;
  border-width: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 3rem;
  /* margin-top: 1rem; */
  /* margin-bottom: 1rem; */
  --tw-text-opacity: 1;
  color: rgba(5, 150, 105, var(--tw-text-opacity));
  width: 3rem;
}

.rec_data-head p{
  font-weight: 700;
  font-size: .875rem;
  margin-left: 1rem;
  margin-right: 1rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}


.data-2_btn a{
  --tw-bg-opacity: 1;
  font-size: .875rem;
  line-height: 2rem;
  background: #f2f2f2;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 3rem;
  padding-right: 3rem;
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
  display: inline-block;
}

.rec_img{
  height: 7rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  margin-right: 2rem;
  width: 7rem;
}

.rec_img img{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 7rem;
  border-radius: 100%;
  max-width: 7rem;
}

.rec_img  p{
  font-weight: 500;
  text-align: center;
}

.rec_img a{
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.negmar{
  margin-right: -1.5rem;
}

/*  FORM PAGE CSS  */

.formnxt{
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.formnxt a{
  --tw-bg-opacity: 1;
  background-color: rgba(254, 226, 226, var(--tw-bg-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(5, 150, 105, var(--tw-bg-opacity));
  font-size: 1.5rem;
  line-height: 2rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 3rem;
  padding-right: 3rem;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.form_info{
  width: 100%;
}

.form_info label p{
  font-weight: 700;
}

.rec_textbox{
  background-color: transparent;
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));
  border-style: solid;
  border-top-width: 0px;
  border-right-width: 0px;
  border-left-width: 0px;
  border-bottom-width: 2px;
  height: 1.5rem;
  outline: 2px solid transparent;
  outline-offset: 2px;
  width: 50%;
}

.rec_dd1 select{
  font-weight: 300;
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.name{
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.rec_name{
  height: 2rem;
}

.rec_dropdown{
  width: 16rem;
}

.dd1{
  float: left;
  margin-right: 1rem;
  width: 18rem;
}

.dd2{
  float: left;
  width: 15rem;
}

.rec_ddmenu{
  width: 100%;
}

.rec_time{
  height: 5rem;
}

.srev_time{
  width: 50%;
}

.prep_time{
  width: 50%;
}

.time{
  font-weight: 300;
  margin-right: 5rem;
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.time_ph{
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.25rem;
  margin-right: 5rem;
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.prep_time input{
  font-weight: 300;
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
  width: 2rem;
}

.rec_n1{
  margin-right: 5rem;
}

.srev_time {
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding-left: 1rem;
  padding-right: 1rem;
}

.srev_time input{
  font-weight: 300;
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
  width: 2rem;
}

.serv_ph{
  font-weight: 300;
  padding-right: 2rem;
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}

.form_upload {
  height: 100%;
  padding: 1.5rem;
  width: 100%;
  max-width: 40%;
}

.rec_uploadimg{
  display: none;
}

.form-file-upload{
  border-radius: 0.5rem;
  border-style: dashed;
  border-width: 2px;
  border-color: #33A287;
  text-align: center;
  background: #f2f2f2;
}

.h-68{
  height:17rem;
}

.upload_footer{
  display: flex;
  justify-content: space-between;
  background: #f6f6f6;
}

.upload_content{
  font-size: 1.25rem;
  line-height: 2.5rem;
  padding: 3rem;
}

.upload_subtxt{
  font-size: 1rem;
  line-height: 1.75rem;
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}
.foot_alg{
  text-align: left;
  width: 6rem;
  font-size:1rem;
}
.foot_pic{
  font-size: 1.5rem;
  line-height: 2rem;
}
.foot_alg1 i{
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
  width: 2rem;
}
.foot_alg1 p{
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}
.foot_alg2 p{
  text-align: right;
  --tw-text-opacity: 1;
  color: rgba(156, 163, 175, var(--tw-text-opacity));
}
.foot_font{
  font-size:1rem;
}
.rec_data-1 {
    display: flex;
    align-items: center;
    width: 100%;
}
.rec_data {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1.5rem;
}
.rec_data-2 {
    width: 100%;
    max-width: 18.75em;
}
.form_upload .fa-cloud-upload-alt {
    font-size: 2rem;
    margin-bottom: 1.25rem;
}
body.recipe__page{
	background: #fff;
}
.bg__light button{
	background: transparent;
	border: 1px solid #f2f2f2;
	border-radius: 1rem;
	padding: 0.3rem 0.7rem;
	text-transform: uppercase;
	color: #adb5bd;
	font-size: 0.7rem;
	background: #f2f2f2;
}
/* .card.card--block {
    padding: 0;
} */
.main__content.p-0{
	padding: 0;
}
.rounded-lg.img_container{
	margin-top: 0;
	border-top: 8px solid #33a386;
}
.view__hub__btn button{
	background: #ffffff;
    color: #4bb3fd;
    border-color: #96d1fc;
    border-width: 2px;
    width: 100%;
    padding: 0.2rem;
    text-transform: uppercase;
    border-radius: 1rem;
}

/* Notification CSS Start */
.view_profile{
	font-size: 0.7em;
}
.view_profile a{
	color: #5C6771;
	font-size: 0.8em;
}
.view_profile:hover a{
	color: #0056b3;
}
.upper_case_title {
    font-size: 0.688em;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.upper_case_title a{
	color: #868e96;
	font-weight: 700;
}
.upper_case_title a:hover{
	color: #2f97c1;
}
.channels li a {
    font-size: 0.75em;
    color: #979797;
    font-weight: 200;
    display: flex;
    align-items: center;
    vertical-align: middle;
    height: 100%;
}
.card.card--no-radius {
    background: #fff;
    padding: 1.4em;
    margin-bottom: 2em;
    display: flex;
}
.filter-btn .fa {
    font-size: 0.6em;
}
.filter-btn .Filter {
    background: #4bb3fd;
    border-top-left-radius: 1em;
    color: #fff;
    border-bottom-left-radius: 1em;
    padding: 0.2em 0.2em 0.3em 1em;
}
.filter-btn .Edit {
    background: #4bb3fd;
    border-top-right-radius: 1em;
    color: #fff;
    border-bottom-right-radius: 1em;
    padding: 0.2em 1em 0.3em 0.2em;
}
.card.card--no-radius {
    color: #808080;
    display: flex;
    justify-content: space-between;
}
.card.card--no-radius{
	color: #808080;
}
.card.card--no-radius span {
    border-left: 1px solid #F5F5F5;
    font-weight: 200;
    margin-left: 0.5em;
    color: #333;
    font-size: 1.375em;
    padding-left: 0.5em;
}
.filter-btn a:hover {
    color: #fff;
}
.card.card--block.text-small{
	padding: 0.5em;
	border-radius: 0;
	border: none;
	margin-top: 0;
	border-bottom: 1px solid rgba(0,0,0,.125);
}
.user__pic.small{
	width: 4rem;
	margin-right: 0.5rem;
}
.user__detail-data.Impoter a {
    font-size: 0.8em;
}
.show-time {
    text-align: right;
    width: 100%;
}
.show-time a{
	font-size: 0.6em;
	color: #5C6771;
}
.show-time a:hover{
	color: #5C6771;
}
.row-flex{
	display: flex;
}
.heart {
    display: flex;
    justify-content: center;
    vertical-align: middle;
    height: 100%;
    padding-right: 0.4em;
}
.heart .fa{
	color: #DD2E44;
}
.notification-title{
	text-transform: uppercase;
	font-size: 0.7em;
	margin-left: 1em;
}
.post-title{
	font-size: 1.5em;
	color: #2f97c1;
    padding: 0.5em 0.5em 0.3em 0.5em;
    margin: 0;
}
.user_post_main{
	border-left: 6px solid #37A182; 
	padding: 0.313em;
	background: #fff;
}
.card.card--block.text-small:hover {
    background: transparent;
}
.active .fa {
    font-size: 0.7em;
    padding-right: 0.4em;
}
.card.card--block.text-small .user__detail-data.Impoter {
    max-width: 50%;
    width: 100%;
}
a.view-btn {
    background: #004577;
    padding: 0.7em 1.5em;
    color: #fff;
    border-radius: 2em;
    font-size: 0.5em;
}
body.notification .left__section-block .card.card--block {
    background: transparent;
}
body.notification .left__section-block .user_profile-detail{
	background: transparent;
}
body.notification .left__section-block{
	max-width: 17%;
	margin-top: 2rem;
}
body.notification .middle__section-block{
	max-width: 56%;
}
body.notification .main__content{
	padding-top: 3.8rem;
}
body.notification .user_profile--status{
	padding-top: 0;
}
body.notification .left__section-block .user_profile-detail{
	padding: 0;
}
body.notification .card.card--block.text-small{
	padding: .75rem 1.25rem;
}
body.notification .upper_case_title.connect {
    font-size: 1em;
    margin: 0 0 0.313em 0;
}
span.bg-icon-color {
    background: #868e96;
    border-radius: 50%;
    width: 1.5em;
    align-items: center;
    display: flex;
    justify-content: center;
    height: 1.5em;
}
span.bg-icon-color .fa{
	color: #fff;
	padding: 0.5em;
	font-size: 0.6em;
}
body.notification .user__concerns{
	background: transparent;
}
body.notification .user__concerns ul li a .nav-icon{
	width: 1rem;
}
body.notification .user_profile--status{
	padding: 0;
}
/* Notification CSS End */

/* Message CSS Start */
body.messages .tabs.middle-long__section-block {
    display: flex;
}
.messages span.btn{
	display: block;
}
body.messages .tab-content-left-section .card--block{
	border-radius: 0;
	padding: 0;
}
body.messages .tab-content-left-section{
	width: 100%;
}
body.messages .feed-user-detail {
    padding: 1em;
    height: calc(100vh - 185px);
}
body.messages .profile__left-section .card--block{
	padding: 0;
	border-radius: 0;
}
/*body.messages .left__section-block.profile__left-section .card--block{
	padding: 0.5em;
}*/
body.messages .profile__left-section{
	padding-top: 0;
}
body.messages .middle-long__section-block{
	padding-left: 0;
}
body.messages .tab.home-section{
	max-width: 100%;
	width: 100%;
}
body.messages .middle-long__section-block{
	margin-left: 0;
}
body.messages .msg-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    height: 60px;
    background: #fafafa;
}
body.messages .msg-title h3 {
    font-size: 1.375em;
    color: #333;
    font-weight: 200;
}
body.messages .msg-title .fa{
	color: #808080;
}
.msg-title h6 a{
	color: #808080;
}
.feed-user-info.chat-profile .user__pic{
	width: 2rem;
}
.user__detail-data.msg-header span {
    display: inline;
}
.user__detail-data.msg-header span {
    font-size: 0.813em;
}
body.messages span.btn.user-name {
	display: flex !important;
	justify-content: space-between;
	align-items: baseline;
}
.chat-time{
	font-size: 0.7em;
}
body.messages span.btn.user-name.active-button {
    background: #007bff !important;
    border-bottom: 0;
    padding: 1em 1em;
}
body.messages span.btn.user-name:hover {
    background: #f2f2f2;
}
body.messages span.btn.user-name.active-button a{
	color: #fff;
	font-weight: 400;
}
body.messages span.btn.user-name.active-button .chat-time{
	color: #fff;
}
.preview-msg {
    width: max-content;
    color: #212529;
    background-color: #004577;
    padding-right: 3px;
    padding-left: 3px;
    font-size: 0.8em;
    padding-top: 0.3em;
    padding-bottom: 0.3em;
    border-radius: 3px;
}
.user__detail-data.msg-header .user__feed.user__feed-text {
    padding: 0;
    margin: 0;
}
.user__detail-data.msg-header .user__feed.user__feed-text span {
    
}
.notification-preview {
    background: #007bff;
    color: #fff;
    padding: 0.3em 0.5em;
    border-radius: 1em;
    font-size: 0.9em;
    width: max-content;
}
body.messages .user__comment-feed{
	border: none;
}
body.messages .site__width{
	z-index: 1;
    position: relative;
}
body.messages .tabs.middle-long__section-block{
	background: #fff;
}
body.messages .main__content{
	background: #004577;
	padding-top: 3.8rem;
}
body.messages .user__comment-feed--list{
	margin: 0;
	padding: 0;
}
body.messages form.cmnt-form{
	border-radius: 0;
    display: flex;
    justify-content: space-between;
}
body.messages .post-icons-option{
	display: flex;
}
body.messages .main__content{
	padding-bottom: 0;
}
/* Message CSS End */

/* Settings CSS Start */
.notification.settings{

}
.card-form.settings .small-text{
	text-transform: uppercase;
}
.card-form.settings {
    margin-top: 1em;
}
.form__item.less-margin label {
    font-size: 0.7em;
    font-weight: 500;
    color: #6c757d;
}
.form__item.less-margin {
    margin-bottom: 1.25em;
}
p.form-text {
    font-size: 0.8em;
    padding: 0.625em 0 0 0.313em;
}
.save-btn {
    background-color: #fafafa;
    color: #adb5bd;
    border-color: #f2f2f2;
    padding: 0.7em 1.3em;
    font-size: 0.625em;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 1.5em;
}
/* .form__item.less-margin.mb-1 {
    margin-bottom: 0.5em;
} */
/* Settings CSS End */

/* Company CSS Start */
.notification.company .middle__section-block .card--block{
	padding: 1em;
}
.notification.company .card-form.settings{
	margin-top: 0;
}
/* Company CSS End */

/* Privacy CSS Start */
.card-form.settings h5 {
    font-weight: 600;
    color: #868e96;
}
.card.card--block h5{
	font-weight: 600;
    color: #868e96;
}
body.notification .card-form.settings select{
	font-size: 0.8em;
}
.notification.privacy .middle__section-block .card--block{
	padding: 1em;
}
.cmnt-form.privacy {
    border-radius: 0.375rem;
    border-style: solid;
    border-width: 1px;
    padding: 0.75rem;
    width: 100%;
    border-color: #e8e8e8;
    margin-bottom: 1.25em;
}
span.btn-box.bg-blue{
	margin-top: 2em;
}
a.bg-blue {
    background: #004577;
    color: #fff;
    padding: 0.7em 1.5em;
    border-radius: 1.5em;
    font-size: 0.625em;
}
body.notification.privacy .card.card--block {
    border-left: none !important;
}
body.notification.privacy .card-form.settings span.ml-2 {
    font-size: 0.688em;
}
/* Privacy CSS End */

/* Password CSS Start */
.left-border {
    border-left: 4px #868e96 solid;
    padding: .75em 0.658em;
    font-size: 0.9em;
}
/* Password CSS End */

/* Blocking CSS Start */
.center-para {
    text-align: center;
    padding: 0.5em;
    font-size: 0.813em;
    color: #5C6771;
    font-weight: 200;
}
/* Blocking CSS End */

/* Membership CSS Start */
.table-block{

}
.table-block table{
	width: 100%;
}
.table-block table tr th{
	font-size: 0.688em;
    color: #515365;
    background: #fafbfd;
    font-weight: bold;
    text-transform: uppercase;
    text-align: left;
}
.table-block table th{
	border-top: 0;
    border-bottom: 0.188em #f4f5f8 solid;
    padding: 1.25em 1.5em;
    background: transparent;
    vertical-align: bottom;
}
.table-block thead th:first-of-type{
	border-top-left-radius: 0.375rem;
}
.table-block thead th:last-of-type {
    border-top-right-radius: 0.375rem;
}
.table-block tbody td:first-of-type{
	border-bottom-left-radius: 0.375rem;
}
.table-block tbody td:last-of-type {
    border-bottom-right-radius: 0.375rem;
}
.table-block tr:first-of-type td {
    border-top-color: transparent;
}
.table-block td:nth-child(1) {
    color: #2f97c1;
}
.table-block tbody{
	background: #fff;
}
.table-block tbody tr td{
    border-color: #f4f5f8;
    vertical-align: middle;
    padding: 10px 20px;
    font-size: 13px;
    color: #868e96;
}
/* Membership CSS End */
/* Billing CSS Start */
.table-left-title{
	color: #868e96;
    text-transform: capitalize;
    border-color: transparent;
    padding-bottom: 0;
    font-size: 0.875em;
    padding: 0.625em 0.938em;
    margin: 0;
    font-weight: 500;
    line-height: inherit;
}
.table-block.billing{
	background: #fff;
	margin-top: 5%;
}
.notification.company.billing input{
	font-size: 0.9em;
}
.btn-box.small input{
	font-size: 0.625em !important;
}
/* Billing CSS End */

/* Your Data CSS Start */
a.bg-primary {
    background: #4bb3fd;
    border-radius: 1.5em;
    color: #fff;
    padding: 0.6em 1em;
    float: right;
}
/* Your Data CSS End */

/* Chicago CSS Start */
.bg-banner{
	width: 100%;
	height: 18.750em;
}
section.bg-banner img {
    width: 100%;
    height: inherit;
}
.card-hub-img img {
    width: 100%; 
    margin: auto;
    border: 1px solid #fff;
    border-radius: 50%;
}
.card--body h4 {
    font-size: 1.3em;
}
.card--body h4:hover{
    color: #60676E;
}
.card-hub-img {
    margin-top: -66%;
}
.card--body p {
    font-size: 0.7em;
}
.Unsubscribe-btn {
    background: #fff;
    border: 3px solid #f9f9f9;
    color: #000;
    border-radius: 2em;
    padding: 0.8em 2em;
    width: 100%;
    text-align: center;
    font-size: 0.6em;
    text-transform: uppercase;
    font-weight: 600;
}
.Unsubscribe-btn a{
	color: #000;
	font-weight: 400;
}
body.chicago .main__content {
    padding-top: 0;
}
.item-group {
    width: 80px;
    line-height: 80px;
    height: 80px;
    background: #37A182;
    color: #fff;
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    border-radius: 50%;
    margin-top: 20px;
}
.item-group-content{
	padding: 1.25em;
}
.item-group-content h4 a{
	color: #37A182;
	font-size: 1em;
}
.travel-agencies{
	text-align: center;
}
.inner-main-div{
	display: flex;
	justify-content: center;
}
.item-group-content p {
    font-size: 0.7em;
    color: #6c757d;
    height: 5.5em;
}
.view__hub__btn a {
    display: block;
    font-size: 0.6em;
}
/* Chicago CSS End */

/* Notification Photos Css Start 27 april */
.text-name .title {
    max-width: 5em;
    display: inline-block;
    word-break: break-word;
}
.list-box .card.card-block:hover{
    box-shadow: none;
}
.card.card-block:hover {
    box-shadow: -2px 3px 28px -9px rgb(0 0 0 / 63%);
}
.right__section-block.sugges{
    margin-top: 2em;
}
.inner-card-box {
    padding: 0.9em 0.5em;
    border-bottom: 1px solid #ddd;
    cursor: pointer;
}
.view-all{
    padding: 0.5em;
    text-align: center;
    font-weight: bold;
    text-transform: uppercase;
    opacity: 0.6;
}
.view-all a{
    font-size: 0.7em;
    color: #5C6771;
    font-weight: bold;
}
.view-all:hover a {
    opacity: 1;
}
.card.card--block .card-title h5 {
    padding-bottom: 0;
    padding-left: 0.5em;
    padding-right: 0.5em;
}
.card.card--block .card-title h5 a{
    color: #000;
}
.user-bg-gray {
    background: #868e96;
    width: 2.2em;
    height: 2.2em;
    border-radius: 50%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5em;
}
span.text-name {
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
}
span.user-link-username {
    color: #5C6771 !important;
    font-weight: 400 !important;
    font-size: 0.7em !important;
}
.user-bg-gray .fa {
    font-size: 0.8em;
}
.user-title{
    color: #2f97c1;
}
.user-link-username {
    display: block;
    margin: 0 !important;
    font-size: 11px;
}
.row.align-items-center{
    display: flex;
    align-items: unset;
}
.text-name span {
    color: #2f97c1;
    font-weight: 500;
    font-size: 0.9em;
}
.follow-icon .fa {
    color: #dee2e6;
    font-size: 1.1em;
}
.inner-card-box:hover {
    background: #f2f2f2;
}

/* Suggestions Css Start */
.full-width-bg-white {
    background: #fff;
    padding-top: 3.5em;
}
.body.notification .main__content.sugges{
    padding-top: 0;
    padding-bottom: 0;
}
.main__content.sugges {
    padding-top: 0 !important;
}
.form__item.form-mb-0{
    margin-bottom: 0;
}
.btn-blue{
    background: #004577;
    border-radius: 1em;
    text-transform: uppercase;
    padding: 0.2em 1em;
}
.btn-blue a{
    color: #fff;
    font-size: 0.6em;
}
.dummy-title {
    text-align: center;
    padding: 2em 0;
    color: #5C6771;
    font-size: 0.8em;
    font-weight: 300;
}
.btn-blue a:hover {
    color: #fff;
}
.card.card-gallery {
    display: flex;
    flex-wrap: wrap;
    margin: 0rem -1.5% 0;
}
.card.card-gallery .card {
    padding: 0.4em;
    margin: 0.5rem 0.3em 0;
    max-width: 30.3333%;
    width: 100%;
    background: #fff;
    border-radius: 0.4em;
    cursor: pointer;
    position: relative;
    /* padding-bottom: .75rem; */
}
.card.card-gallery .img_container {
    border: none;
    margin-top: 0;
}
.card.card-gallery .card-img img{
    width: 100%;
}
.card.card-gallery .img_container{
    border: none;
    margin-top: 0;
}
.card.card-block:hover .card-form{
    display: block;
}
.card.card-gallery .card-form {
    display: none;
    position: absolute;
    left: 0;
    background: #fff;
    width: 100%;
    padding: 0.1em 0.5em 0.4em 0.5em;
    z-index: 9999;
}
.card.card-gallery .main__content.p-0{
    padding-top: 0 !important;
    padding-bottom: 0;
}
.card.card-gallery .main__content a {
    font-size: 0.8em;
    font-weight: 700;
}
.user__concerns span.nav-icon img {
    border-radius: 50%;
}
.card.card-gallery .main__content a :hover{
    color: #868e96;
}
.card.card-gallery .view__hub__btn a{
    color: #868e96;
}
.card.card-gallery .view__hub__btn:hover a{
    color: #2f97c1;
}
.card.card-gallery .card-form p:hover{
    color: #2f97c1;
}

/* 01 May Notification List */
.card.card-gallery.card-list .card.card-block{
    min-height: 12em;
    margin: 0;
}
.list-box:hover a {
    color: #2f97c1;
}
.card.card-gallery.card-list{
    justify-content: initial;
}
.card.card-gallery.card-list a{
    max-width: 100%;
}
.card.card-gallery.card-list .list-box {
    max-width: 25%;
    width: 100%;
    margin: 0.5rem 0.3em 0;
    border: 1px #E1E1E1 solid;
    border-radius: 0.5em;
}
.card.card-gallery.card-list .card{
    max-width: 100%;
    border-radius: 0.5em;
}
.img_container.bottom-text{
    border: none;
    margin-top: 0;
}
.img_container.bottom-text {
    position: absolute;
    bottom: 1em;
    text-align: center;
    width: 100%;
    font-size: 0.8em;
    font-weight: normal;
}
.img_container.bottom-text p{
    text-align: center;
}
.card.card--no-radius.mb-0{
    margin-bottom: 1.5em;
}
.file-bg {
    width: 3em;
    height: 3em;
    background: #868e96;
    border-radius: 50%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.section-header {
    min-height: 3em;
    background: #fff;
    border-bottom: 1px transparent solid;
    box-shadow: none;
    color: #808080;
    margin-left: 0.5em;
    /*
    margin-top: -30px;
    padding: 10px 0;*/
}
.section-header a{
    color: #808080;
}
.breadcrumb {
    background: transparent;
    padding: 0;
    font-size: 0.8em;
}
.breadcrumb {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;/*
    padding: .75rem 1rem;*/
    list-style: none;
    background-color: transparent;
    border-radius: .25rem;
}
.section-header h1 a{
    font-size: 1.3em;
    font-weight: 200;
    letter-spacing: 1px;
    margin: 0;
    color: #333;
}
.card.card--no-radius.width-head {
    padding: 0.5em 1em;
}
.card.card--no-radius.width-head span{
    padding: 0;
    border: none;
}
.list-detail{
    font-size: 0.7em;
    margin-top: 0.9em;
    margin-left: 1em;
}
.list-detail a {
    font-size: 0.5em;
}
.card.card--no-radius.width-head span {
    padding: 0;
    border: none;
    margin: 0;
}
.card.card--no-radius.width-head.mb-1 {
    margin-bottom: 1em !important;
}
.list-post ul {
    display: flex;
}
.list-post ul li a {
    padding: 0.5em 0.5em;
    font-size: 0.8em;
    font-weight: 200;
    text-transform: uppercase;
    color: #979797;
}
.counter{
    font-weight: 600;
}
.edit__profile.ellipsis{
    align-items: center;
}
.edit__profile.ellipsis .fa {
    color: #808080;
}
.edit__profile.ellipsis:hover .fa {
    color: #2f97c1;
}
.edit__profile.ellipsis {
    background: #fff !important;
    border: 1px #f2f2f2 solid;
    border-radius: 100%;
    position: relative;
    width: 3em;
    height: 3em;
    display: flex;
    justify-content: center;
    align-items: center;
}
/*.edit__profile.ellipsis .edit__options .profile__upload {
    position: absolute;
    right: 0;
    top: 90%;
}*/
.edit__profile.ellipsis span {
    background: #fff;
}
.edit__profile.ellipsis .edit__options .edit-details {
    position: absolute;
    right: 0;
    top: 90%;
    background: #fff;
    box-shadow: 0 6px 15px 2px rgb(0 0 0 / 20%);
}
ul.edit-details li {
    background-color: transparent;
    border-style: solid;
    border-bottom-width: 1px;
    display: block;
    text-align: left;
    --tw-text-opacity: 1;
    color: rgba(0, 0, 0, var(--tw-text-opacity));
    border-radius: 0;
    width: 14em;
    padding: 1.2em 1em 1.2em 2em;
}
ul.edit-details li a{
    text-transform: capitalize;
    font-size: 1.175em;
    font-weight: 400; 
}
ul.edit-details li a.Edit {
    color: #000;
}
ul.edit-details li a.Delete {
    color: #dc3545;
}

/* ============= */
.servics_blocks form button{
	border: 0.0875rem solid #f2f2f2;
	border-radius: 1rem;
	padding: 0.3rem 0.7rem 0.5rem;
	text-transform: uppercase;
	font-size: 0.7rem;
}
.servics_blocks form button:hover{
	opacity: .85;
}
/* .card.card--block {
    padding: 0;
} */
.main__content.p-0{
	padding: 0;
}
.rounded-lg.img_container{
	margin-top: 0;
	border-top: 8px solid #33a386;
}
.view__hub__btn {
	margin-top: 1rem;
}
.view__hub__btn button,
.view__hub__btn a{
	background: #ffffff;
    color: #4bb3fd;
    border-color: #96d1fc;
    border-width: 0.125rem;
    width: 100%;
	text-align: center;
    padding: 0.5rem;
    text-transform: uppercase;
    border-radius: 1.5rem;
	font-size: .7rem;
}
.view__hub__btn a{
	display: block;
}
.medium_title h1{
	font-size: 1.75em;
    font-weight: 500;
}
.servics_blocks .left__section-block{
	margin-right: 1%;
	margin-top: 1.5rem;
}
.servics_blocks .middle-long__section-block .card--section{
	margin: 0 -2%;
}
.servics_blocks + hr{
	margin-top: 1.75rem;
}
.servics_blocks .middle-long__section-block .card--block{
	margin: 1.5rem 2% 0;
	max-width: 29.3333%;
	width: 100%;
	padding-bottom: .75rem;
}
.servics_blocks form {
    font-size: .875rem;
}
.form-checkbox-container{
	max-height: 12rem;
	overflow-y: auto;
}
.form-checkbox-container-items:not(:last-child){
	margin-bottom: .5rem;
}
.book_icon{
	display: none;
}

/* Recipes Pages */
body.recipe__page{
	background: #fff;
}
body.recipe__page .main__content{
	padding-top: 3.75rem;
}
body.recipe__page .book_icon{
	display: block;
}

/* Recipe banner CSS */
.site__banner {
    position: relative;
}
.banner__image img{
	width: 100%;
}
.banner__content {
    position: absolute;
    left: 0;
    right: 0;
    text-align: center;
    padding: 1.5rem;
    bottom: 3%;
}
.banner__search-form {
    max-width: 32em;
    margin: auto;
	display: flex;
	justify-content: space-between;
}
.banner__search-form form{
	width: 100%;
	max-width: 21em;
}
.banner__search-form .top-search{
	position: relative;
}
.banner__search-form .top-search span.search_icon {
    position: absolute;
    top: 50%;
    color: #777;
    left: .5rem;
    transform: translateY(-50%);
}
.banner__search-form .top-search input {
    background-color: #fff;
    padding: .75rem .75rem .75rem 2rem;
    box-shadow: 0 0 15px rgb(29 29 29 / 50%);
    border-radius: .25rem;
}
.create_recipe-btn{
	width: 100%;
    max-width: 10em;
}
.create_recipe-btn a{
	background: #32A093;
    color: #fff;
    padding: .672rem;
    line-height: 1;
    border-radius: .25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 15px rgb(29 29 29 / 50%);
}
.create_recipe-btn a img{
	margin-right: .25rem;
    width: 1.25rem;
}
.banner_small-title p {
    text-transform: uppercase;
    color: #000;
}
.banner-title p {
    font-size: 2rem;
    font-weight: 600;
    line-height: 1;
}

.recipe__section{
	margin-top: 3rem;
}
/* Suggested Recipes Css Start */
.section_heading .section_title {
    font-size: 1.5em;
    font-weight: 600;
}
ul.recipe__innerdiv {
    margin: 0 -1rem;
    display: flex;
    flex-wrap: wrap;
}
.recipe__detail {
    max-width: 20%;
    margin-top: 1.5em;
    width: 100%;
    padding: 0 2%;
}
li.recipe__detail.selected:before {
    position: absolute;
    content: '';
    background: #33A287;
    height: 6rem;
    width: 6rem;
    border-radius: 100%;
}
li.recipe__detail.selected:after {
    position: absolute;
    content: '';
    width: 1rem;
    height: 3rem;
    border-bottom: 0.25rem solid #fff;
    border-right: 0.25rem solid #fff;
    top: 1.25rem;
    left: 2.5rem;
    transform: rotate(45deg);
}
.recipe__detail img{
	width: 100%;
	border-radius: 0.875em;
}
.review{
	display: flex;
	justify-content: space-between;
}
.review .fa.fa-star{
	color: #FFD000;
}
.review .fa.fa-star-o{
	color: #E6E6E6;
}
.recipe__name {
    font-size: 1em;
    font-weight: 600;
}
.light__text{
	font-size: 0.813em;
	color: #232323;
}
.description{
	font-size: 1em;
	line-height: 1.188em;
	margin-top: 0.5em;
}
.review_title{
	font-size: 0.813em;
}
/* Suggested Recipes Css End */

/* Just For You Css Start */
ul.recipe__innerdiv.min__margin .recipe__detail {
    margin-top: 1em;
}
.review span .fa {
    font-size: 0.75em;
}
/* Just For You Css End */


.carousel-open:checked + .carousel-item {
	position: static;
	opacity: 100;
}
.carousel-item {
	-webkit-transition: opacity 0.6s ease-out;
	transition: opacity 0.6s ease-out;
}
#carousel-1:checked ~ .control-1,
#carousel-2:checked ~ .control-2,
#carousel-3:checked ~ .control-3 {
	display: block;
}


/* Add Ingredients Css Start */
.ingredient__width{
	max-width: 70%;
	width: 100%;
	padding: 0 2rem;
}
.recipe__detail h4 {
    text-align: center;
    font-size: 1rem;
    line-height: 1.3em;
    margin-top: 0.5em;
    font-weight: 500;
}
.top__div a{
	color: #000;
}
.semi__bold__title {
    font-size: 1rem;
    font-weight: 700;
    width: 100%;
}
.top__div {
    padding: 1.5em 0;
    margin-bottom: 3rem;
}
.cancel {
    margin-bottom: 0.5em;
}
.step{
	display: flex;
	align-items: center;
}
.right__align_btn a {
    background: #0000000D;
    color: #000;
    padding: 0.7em 3em;
    border-radius: 0.3em;
}
.right__align_btn {
    width: 100%;
    text-align: right;
}
.bg__shadow {
    box-shadow: 0 4px 7px 0 rgb(218 220 230 / 60%);
    margin-bottom: 2em;
}
.search-box {
    display: flex;
}
.search-box .top-search {
    max-width: 70%;
    width: 100%;
    position: relative;
}
.search-box .form__item {
    max-width: 30%;
    width: 100%;
    margin-left: 2%;
}
.search-box .top-search input {
    border: 1px solid #ddd;
    border-radius: 0.5em;
    background: #fff;
    height: 3.3em;
	padding: .5rem .5rem .5rem 2.5rem;
}
.search-box .top-search input::placeholder{
	/*padding-left: 10%;*/
}
span.search_icon {
    position: absolute;
    left: 2%;
    top: 16%;
}
.search-box .form__item .select--field{
	background: #fff;
}
.search-box .select--field select{
	border-bottom: none;
	border: 1px solid #ddd;
	background: #fff;
	border-radius: 0.3em;
}

/* Add Ingredients Css Start */
span.circle {
    width: 2em;
    height: 2em;
    border: 2px solid #33A287;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    padding-top: 0.1em;
    color: #33A287;
	margin-right: 0.75rem;
}
.top__div .bg__color__btn{
	background: #33A287;
	color: #fff;
}
h4.semi__bold__title.add-recipe span {
    float: right;
}
.ingredient__main {
    display: flex;
}

.ingredient__main .recipe__detail {
    max-width: 6rem;
    width: 100%;
    margin: 1rem;
    position: relative;
	padding: 0;
}
.ingredient__main .recipe__detail img{
	width: 100%;
	border-radius: 100%;
	height: 6rem;
	max-width: 6rem;
}
.ingredients-list {
    max-width: 30%;
    width: 100%;
    padding: 0 2rem;
}
.ingredient-detail {
    width: 100%;
    display: flex;
    border-bottom: 1px solid #ddd;
    padding-bottom: 0.5em;
    align-items: center;
    justify-content: space-between;
}
.ingredient-left img {
    height: 100%;
    border: 1px solid #ddd;
    border-radius: 50%;
}
.ingredient-left {
    max-width: 70%;
    width: 100%;
    display: flex;
}
.ingredient-left span {
    margin-left: 0.5rem;
    display: flex;
    align-items: center;
}
.ingredient-right {
    max-width: 20%;
    width: 100%;
    display: flex;
    justify-content: space-around;
}
.icon-img {
    width: 2rem;
    height: 2rem;
}
a.edit {
    color: #000;
}
a.close{
	color: #FF4343;
}

/* Receipe form */
.receipe__form .form__item label {
    font-weight: 700;
    text-transform: none;
}

.receipe__form .form__item input, .receipe__form .form__item select, .receipe__form .form__item textarea {
    background: transparent;
}

.receipe__form .form__item input {
    padding-left: 0;
    max-width: 40%;
}

.receipe__form .form__item input[type="number"] {
    border: none;
}

.receipe__form .form__item .select--field {
    background: transparent;
}

.receipe__form .form__item .select--field select {
    border: 1px solid #dfdfdf;
}

.receipe__form .form__grid .form__item{
	width: 100%;
}

.receipe__form .form__grid span + label,
.receipe__form .form__grid span + .form__item label,
.receipe__form .form__grid span + .form__item + .form__item label {
    font-weight: 300;
}

.option__text h1 {
    font-size: 1.3125rem;
    color: #004577;
    font-weight: 700;
}
.bg-white:hover h1{
	color: #fff;
}
.center-heading{
	text-align: center;
}
.center-heading p {
    color: #5C6771;
    font-size: 1rem;
    padding: 0 3rem;
	font-weight: 400;
}
.center-heading h1{
	color: #004577;
	font-weight: 700;
    font-size: 1.4rem;
    line-height: 2.25rem;
    position: relative;
}
.card-form h3{
	color: #004577;
	font-weight: 700;
    font-size: 1rem;
    margin: 0.5em 0;
}
.hub-selection .form__item.form__checkbox {
    /*background: #f2f2f2;*/
    display: block;
    position: relative;
}
.form__item.form__checkbox input {
    width: auto;
    height: auto;
    margin-left: auto;
    display: flex;
    position: absolute;
    right: .5rem;
    top: .5rem;
}
.country-check label{
	width: 100%;
	display: flex;
	align-items: center;
}
.hub-selection .form__item input[type="checkbox"] + label, .form__item input[type="radio"] + label {
    margin-left: 0;
    color: #004577;
}
.country-check span {
    font-weight: 700;
    text-transform: capitalize;
    font-size: 0.6rem;
    padding-left: 1em;
}
label.flex.p-4.rounded-lg {
    background: #f2f2f2;
    color: #004577;
}
.main-center-heading h1 {
    font-size: 2.2em;
    font-weight: 700;
    color: #004577;
	line-height: 1.5;
}
.main-center-heading {
    text-align: center;
    max-width: 50%;
    margin: auto;
}
.register_form.options{
	max-width: 24rem;
}
.register_form.options .card--block{
	padding-top: 0;
	padding-right: 0;
	padding-left: 0;
	padding-bottom: 3em;
	border-radius: 0;
}
.form__item{
	margin-bottom: 0.5em;
}
.hub-selection .main__content{
	padding-top: 1rem;
}
.center-heading h1 a{
	color: #f2f2f2;
}
a.bg-blue-800.bg-btn {
    color: #fff;
    padding: 0.2em 5em;
    text-align: center;
    border-radius: 0.5em;
    background: #004577;
}
.hub-selection .btn-box{
	display: flex;
	justify-content: center;
}
.hub-selection .form__item label {
    font-size: 0.8em;
    text-transform: capitalize;
}
.hub-selection .form__item span {
    /*color: #004577;*/
    font-weight: 600;
}
.hub-selection .form__item{
	margin-bottom: 1.5em;
}
.text-small{
	font-size: 0.9em;
}
.hub-selection .center-heading .p-0{
	padding: 0;
}
.main__content a {
    font-weight: 500;
    color: #232323;
}
.card-form .form-title {
    color: #004577;
    font-weight: 500;
}
/*.center-heading h1::before {
    content: "\f053";
    font-family: fontawesome;
    left: 0;
    position: absolute;
    color: #e5e7eb;
}*/
.hub-selection .form__item input[type="checkbox"]:checked + label {
    background: #004577;
    color: #fff;
}
.center-heading h1 a {
    color: #f2f2f2;
    position: absolute;
    left: 0;
    font-size: 0.8em;
}
.main-center-heading p {
    font-size: 0.9em;
    color: #5C6771;
}
.card-form .w-1\/2{
    width: 45%;
}
.option__text p {
    font-size: 0.9em;
    color: #5C6771;
}
.alysei-web .producer__main:hover {
    background: #004577;
}
.alysei-web .producer__main:hover p{
    color: #fff;
}
.para.p-0{
    padding: 0;
}
.city-list {
    background: #f2f2f2;
    height: 7em;
    overflow-y: scroll;
}
ul.city-list li {
    font-size: 0.8em;
    padding: 0.6em;
    font-weight: 600;
    color: #5C6771;
}
.register_form.options .card--block{
    padding-bottom: 0;
}
.hub-selection.pb-0 .main__content{
    padding-bottom: 0;
}
.hub-btn .bg-white-btn {
    color: #7a7474;
    padding: 0.5em 1.7em;
    text-align: center;
    border-radius: 0.5em;
    font-size: 0.7em;
    border: 1px solid #b9b9b9;
    font-weight: 600;
}
.hub-btn .bg-blue-btn {
    color: #fff;
    padding: 0.5em 2em;
    text-align: center;
    border-radius: 0.5em;
    background: #004577;
    float: right;
    font-size: 0.7em;
}
.edit-delete .edit{
    font-size: 0.8em;
}
.remove{
    font-size: 0.8em;
    color: red !important;
    float: right;
}
.outer-border{
    border: 1px solid #ddd;
    padding: 1em;
    font-size: 0.8em;
}

/* New css 07-06-2021 */
.user_profile-detail.less-padding{
	padding: 0;
	background: #fff;
}
.user_profile-detail.less-padding .active{
	background: #004577;
}
.user_profile-detail.less-padding ul li{
	padding: .5rem 1rem;
}
.user_profile-detail.less-padding .active a{
	color: #fff;
}
.user_profile-detail.less-padding ul li a{
	font-size: 0.875em;
}
.user_profile-detail.less-padding ul li:hover a{
	color: #0056b3;
}
.user_profile-detail.less-padding ul{
	width: 100%;
}
.right__section__block{
	max-width: 74%;
	width: 100%;
}
.card.card--block.p--1{
	padding: 1em;
}
.w-36.coln-border{
	border: 1px #E1E1E1 solid;
	border-radius: 0.2em;
	padding: 0.5em;
}
.user-text-name.text-center{
	width: 100%;
    font-weight: 200;
    font-size: 14px;
    color: #2f97c1;
    text-decoration: none;
    padding: 10px 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.btn--1 button {
    border-radius: 2em;
    background-color: #4bb3fd;
    color: #fff;
    border-color: #4bb3fd;
    margin: 0 auto 0.625em auto;
    padding: 0.4em 0.9em;
    font-size: 0.625em;
    text-transform: uppercase;
    font-weight: 600;
}
.middle__section-block.margin-auto {
    margin: auto;
}
.pending .user--profile{
	width: 3.75em;
	height: 3.75em;
	border-radius: 50%;
	background: #868e96;
}
.pending .user--profile img{
	border-radius: 50%;
}
.pending .user-link, .user-link:hover {
    font-weight: 600;
    color: #2f97c1;
}
.pending .user-link-name {
    align-items: center;
    display: inline-flex;
}
.pending .text-name {
    word-break: break-all;
}
.pending .text-name > span:not(:first-of-type) {
    margin-left: 3px;
}
.pending .text-muted {
    color: #6c757d!important;
}
.pending .text-muted{
	font-size: 0.6875em;
}
.pending .ml-auto button {
    padding: 0.2em 0.8em;
    font-size: 0.625em;
    text-transform: uppercase;
    font-weight: 600;
    border-radius: 0.55em;
}
.pending .btn-outline-secondary {
    color: #6c757d;
    border: 1px solid #6c757d
}
.pending .card.card--block.p--0 {
    padding: 0;
}
.pending .list-group {
    border-top: 1px solid #f2f2f2;
    border-bottom: 1px solid #f2f2f2;
    padding: 0.9375em;
}
.card--block:hover h1{
	color: inherit !important;
}

/* Recipe CSS Start */
.banner__search-form .top-search .filter_icon {
    position: absolute;
    top: 50%;
    color: #777;
    right: .5rem;
    transform: translateY(-50%);
} 
.create_recipe-btn a {
    color: #fff;
}
.banner__search-form.justify-center {
    justify-content: center;
}
body.recipe__page.bg-gray {
    background: #f2f2f2;
}
.section__wrapper.bg-white.mt--5{
	margin-top: 4rem;
}
.recipe__page .banner_slider .checked_bg {
    width: 5rem;
    height: 5rem;
    background: rgb(50 160 147 / 0%);
    border-radius: 50%;
    position: absolute;
    top: 0;
    left: 50%;
    transition: background .2s ease;
    transform: translateX(-50%);
    /* opacity: 0; */
    /* left: 0%; */
}
.recipe__page .banner_slider .checked_bg::before {
    font-size: 2rem;
    content: "\f00c";
    font-family: fontawesome;
    color: #fff;
    top: 50%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity .2s ease;
}
.recipe__page .banner_slider input[type="checkbox"]:checked + label .checked_bg:before {
    opacity: 1;
}
.recipe__page .banner_slider input[type="checkbox"]:checked + label .checked_bg {
    /* opacity: 70%; */
    background: rgb(50 160 147 / 70%);
}
.recipe__page .banner_slider input[type="checkbox"]{
	display: none;
}
.recipe__page .banner_slider input[type="checkbox"] + label{
	position: relative;
}
.recipe__page .banner_slider input[type="checkbox"]:checked + label .checked_bg{
    display: block;
}
.banner__search-form.Ingredients {
    margin-left: 0;
} 	
.Ingredient span.btn.active-button, span.btn.active-button:hover{
	border-bottom: 2px #32A093 solid;
    color: #32A093;
    font-weight: 600;
    padding: 0.5em;
}
.Ingredient .tabs__wrapper{
	border-bottom-width: 0;
    margin-bottom: 0.25em;
    width: 100%;
}
.recipe__section.Ingredient span.btn{
	font-weight: 600;
}
.cuisines input[type="checkbox"]{
	display: none;
}
.cuisines input[type="checkbox"] + label{
	position: relative;
}
.form-item-checkbox.Cuisines input[type="checkbox"]:checked + label::after {
    content: "\f00c";
    position: absolute;
    font-family: fontawesome;
    right: 0;
    top: 50%;
    transform: translate(-50%, -50%);
    color: #32A093;
    margin-right: -2rem;
}
.form-item-checkbox.Cuisines input[type="checkbox"]:checked + label{
	color: #32a093;
}
.cuisines label {
    font-weight: 600;
    color: #000;
}
.cuisines {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}
.cuisines .form-item-checkbox {
    max-width: 25%;
    width: 100%;
    padding: 0.3em 0;
}
.form-item-checkbox.time-check input[type="checkbox"]:checked + label{
    content: "";
    position: absolute;
    background: #32A093;
    color: #fff;
    padding: 0.5rem;
    border-radius: 1rem;
}
.form-item-checkbox.time-check input[type="checkbox"] + label {
    content: "";
    position: absolute;
    background: #fff;
    color: #000;
    padding: 0.5rem;
    border-radius: 1rem;
    box-shadow: 0 0 15px rgb(13 0 5 / 29%);
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    min-width: 4.5rem;
    text-align: center;
}
.cuisines.time-checkbox {
    flex-wrap: initial;
}
.tab-tap-div {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}
.top-reset input {
    background: transparent;
    font-weight: 600;
}
.banner__search-form__center {
    max-width: 27em;
    margin: auto;
}
.banner__search-form__center form {
    width: 100%;
    max-width: 21em;
}
.banner__search-form__center .top-search {
    position: relative;
}
.banner__search-form__center .top-search span.search_icon {
    position: absolute;
    top: 50%;
    color: #777;
    left: .5rem;
    transform: translateY(-50%);
}
.banner__search-form__center .top-search input {
    background-color: #fff;
    padding: .75rem .75rem .75rem 2rem;
    box-shadow: 0 0 15px rgb(29 29 29 / 50%);
    border-radius: .25rem;
}
.banner__search-form__center .top-search .filter_icon {
    position: absolute;
    top: 50%;
    color: #777;
    right: .5rem;
    transform: translateY(-50%);
}
.selected-no{
	width: 1em;
	height: 1em;
	border-radius: 50%;
	color: #fff;
	background: #32a093;
}
.selected-no {
    width: 0.78rem;
    height: 0.78rem;
    border-radius: 50%;
    color: #fff;
    background: #32a093;
    display: inline-flex;
    justify-content: center;
    font-size: 0.5rem;
    align-items: center;
    position: absolute;
    right: -0.35rem;
    top: 0.5rem;
}
.recipe__section.Ingredient span.btn{
	position: relative;
}
.banner__search-form.left {
    max-width: 30%;
    width: 100%;
    display: block;
    margin-left: initial;
}
.tab-data-block.left {
    width: 100%;
}
.tab.ingredients-section.active-tab .banner__search-form {
    max-width: 18em;
    display: block;
    margin: inherit;
}
.tab.ingredients-section.active-tab {
    display: block;
}
/*body.recipe__page .ingredient__main .recipe__detail img {
    border-radius: 0;
}*/
.ingredient__main .recipe__detail input[type="checkbox"] + label{
	position: relative;
}
.ingredient__main .recipe__detail input[type="checkbox"]{
	display: none;
}
.ingredient__main .recipe__detail input[type="checkbox"]:checked + label::before {
    position: absolute;
    left: 0;
    top: 0;
    content: " ";
    width: 6rem;
    height: 6rem;
    border-radius: 100%;
    background: transparent;
    z-index: 9;
    border: 0.25rem solid #37A282;
}
.ingredient__main .recipe__detail input[type="checkbox"]:checked + label::after {
    content: '\f00c';
    position: absolute;
    top: 0;
    font-family: fontawesome;
    width: 2em;
    left: 50%;
    height: 2em;
    transform: translate(70%, 70%);
    color: #fff;
    font-size: 1.5em;
    z-index: 9;
}


.ingredient__main .recipe__detail input[type="radio"] + label{
	position: relative;
}
.ingredient__main .recipe__detail input[type="radio"]{
	display: none;
}
.ingredient__main .recipe__detail input[type="radio"]:checked + label::before {
    position: absolute;
    left: 0;
    top: 0;
    content: " ";
    width: 6rem;
    height: 6rem;
    border-radius: 100%;
    background: #32A093;
    z-index: 9;
}
.ingredient__main .recipe__detail input[type="radio"]:checked + label::after {
    content: '\f00c';
    position: absolute;
    top: 0;
    font-family: fontawesome;
    width: 2em;
    left: 50%;
    height: 2em;
    transform: translate(70%, 70%);
    color: #fff;
    font-size: 1.5em;
    z-index: 9;
}
span.close_icon {
    position: absolute;
    right: 2%;
    top: 15%;
}
span.close_icon .fa {
    color: #32A093;
}
/*.ingredient__main .recipe__detail input[type="checkbox"]:checked + label h4 {
    display: none;
}*/
.ingredient-detail.justify-center {
    justify-content: center;
}
.close.icon_red .fa{
	color: #f00;
}
form.recipe_form .form__item label {
    font-size: 1rem;
    color: #000;
    font-weight: 700;
    text-transform: capitalize;
}
form.recipe_form .form__item input {
    background: transparent;
    border: none;
    height: 2.75rem;
    border: 1px solid #D2D2D2;
    border-radius: 0.3125em;
}
form.recipe_form .form__item select {
    background: transparent;
    border: none;
    height: 2.75rem;
    border: 1px solid #D2D2D2;
    border-radius: 0.3125em;
}
form.recipe_form .form__item textarea{
	background: transparent;
    border: none;
    min-height: 10rem;
    border: 1px solid #D2D2D2;
    border-radius: 0.3125em;
    margin-bottom: 1rem;
}
form.recipe_form .form__item input, textarea {
    margin-bottom: 2rem;
}
body.recipe__page .ingredient__width{
	padding-left: 0;
}
.ingredient__main .used_ingredient .recipe__detail input[type="checkbox"]:checked + label h4 {
    display: block;
}
.ingredient__main .used_ingredient .recipe__detail input[type="checkbox"]:checked + label::after {
    content: '\f00c';
    position: absolute;
    top: 0;
    font-family: fontawesome;
    width: 1.5em;
    left: 50%;
    height: 1.5em;
    transform: translate(80%, 80%);
    color: #fff;
    font-size: 1.5em;
    background: #32A093;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #fff;
}
.ingredient__main .used_ingredient .recipe__detail input[type="checkbox"]:checked + label::before{
	content: " ";
	display: none;	
}
ul.recipe__innerdiv.used_ingredient h4{
	margin: 0 1em;
}
.btn__save__add{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.btn__save__add a{
    background: #32A093;
    color: #fff;
    padding: 0.7em 3em;
    border-radius: 0.3em;
    min-width: 7em;
}
.btn__save__add .add__step{
	background: transparent;
	color: #32A093;
}
.icon-img .icon-box-bg {
	height: 100%;
    border-radius: 50%;
    background: #32A093;
    display: flex;
    justify-content: center;
    color: #fff;
    align-items: center;
    font-size: 0.75rem;
}
.ingredient-right i.fa.fa-pencil {
	color: #32A093;
}
.ingredient__main.full-width .ingredient__width {
    max-width: 100%;
}
.form_upload.recipe .form-file-upload {
    border: none;
    background: transparent;
}
.form_upload.recipe p {
    bottom: 16%;
    background: #00000069;
    color: #fff;
    /* left: 0; */
    /* right: 0; */
    width: 100%;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    padding: 0.5rem 0;
}
.form_upload.recipe img{
    border-top-left-radius: 0.5rem;
    border-top-right-radius: 0.5rem;
}
.form_upload.recipe .upload_content{
	padding: 0;
}
.quantity {
    border: 1px solid #ddd;
    padding: 0.5rem;
    margin-top: 0.5rem;
    border-radius: 0.3125em;
    box-shadow: 0px 0px 2px rgb(145 158 171 / 24%), 0px 0px 6px -4px #000000;
    background: #fff;
}
.quantity .title{
	font-weight: 700;
}
.col-left-side {
    max-width: 48%;
    width: 100%;
}
.full_w_btn button {
    background: #32a093;
    color: #fff;
    width: 100%;
    padding: 0.7em 3em;
    border-radius: 0.3rem;
}
form.recipe_form .quantity .form__item input{
	margin-bottom: 0;
}
.ingredient-detail.border-bottom-0 {
    border-bottom: none;
}
.ingredient__main.mt--5{
	margin-top: 2rem;
}
a.Remove{
	color: #FF4343;
}
span.close_icon.top_search {
    right: 7%;
    top: 20%;
}
body.recipe__page .tabs__wrapper span.btn:nth-child(1) {
    padding-left: 0;
}
/* Recipe CSS End */



.report-post .problems {
    @apply flex justify-between;
    padding: 0.625rem 0;
}

.report-post input.select-checkbox {
    display: unset;
}

.reportModal .headerTitle {
    color: #3296ff;
    font-size: 1.5rem;
}

.ReactModal__Content.ReactModal__Content--after-open.reportModal {
    padding: 10px !important;
}

.report-post {
    padding: 20px;
}

textarea.my_reason {
    border: 1px solid #d2d2d2;
    @apply w-full;
    padding: 0.375rem;
}

.submitReport {
    @apply text-center;
    padding: 0.75rem;
}

button.savedReport {
    background: #47AAF0;
    padding: 5px 20px 5px 20px;
    color: #FFF;
    border-radius: 4px;
}

h4.problem-post.font-18.font-bold {
    font-size: 14px;
    text-align: center;
    color: #333;
    padding-top: 10px;
}

.reportModal .errorMsg {
    color: red;
    font-size: 12px;
}
