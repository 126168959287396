

.left-block-w30 {
  max-width: 35%;
  width: 100%;
  background: #fff;
  padding: 0.5rem;
}
.right-block-w70{
  max-width: 65%;
  width: 100%;
}
.top-small-title-black{
  font-size: 1rem;
  font-weight: 700;
  color: #000;
}
h5.top-small-title-black a {
  font-size: 1rem;
  font-weight: 700;
  color: #000;
}

.my-store a{
  color: #00afdb;
  font-weight: 600;
}
.site_search.inner-search .top-search input {
    border-radius: 0.2rem;
    height: 3.52rem;
}
.site_search.inner-search.mt-3 {
  width: 100%;
}

.product-type ul {
  margin: 0 -0.5rem;
}
.prdcr-store-title{
  text-align: center;
}
.producer-store a:hover{
  color: #000;
}
h4.prdcr-store-title {
  font-size: 0.8rem;
  font-weight: 600;
  line-height: 1.2rem;
  color: #000;
  margin-top: 0.4rem;
}

.store-box .icon-img .fa{
  color: #00B4A3;
}
h5.my-store a {
  font-size: 0.7rem;
  font-weight: 700;
}
.store-box.circle{
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4.5rem;
}
.store-box.circle .icon-img .fa{
  margin-top: 0.8rem;
}
.right-block-bg .right-banner img {
  width: 100%;
  border-radius: 0.5rem;
  height: 100%;
}
.right-block-bg {
  padding: 0.5rem;
}

.product-type.mt-45{
  margin-top: 2.6rem;
}
.product-desc p {
   font-size: 1.125rem;
   color: #666666;
   font-weight: normal;
}
.product-type li{
  height: 100%;
}
.product-offer .product-img{
  height: 18rem;
  position: relative;
}
.product-offer{
  margin: 0 -0.3rem;
}
.product-desc {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.product-offer .product-img img{
  height: 100%;
}
.product-offer a:hover{
  color: initial;
}
.offer-detail .offer-name{
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
}
.offer-detail .offer-amt{
  font-size: 1.5rem;
  font-weight: 700;
  color: #cca061;
}
.offer-detail p{
  font-size: 0.7rem;
  color: #fff;
}
.product-offer .offer-bg{
  position: relative;
  margin: 0 0.5rem;
}
.product-offer .offer-detail {
  position: absolute;
  left: 0;
  top: 50%;
  text-align: center;
  width: 100%;
  transform: translateY(-50%);
}
.producer-store ul {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.shop-now-btn {
  width: 100%;
  margin-top: 1rem;
}
.shop-now-btn a {
  background: #d3d3d3;
  padding: 0.5rem 0;
  border-radius: 2rem;
  color: #000;
}
.site_search.inner-search .top-search button[type="submit"]{
  left: 0.2rem;
  top: 50%;
  font-size: 1.5rem;
}
.site_search.inner-search input::placeholder{
  padding-left: 0%;
}
.right-block-bg .right-banner img{
  border-radius: 0;
}
.bold-heading{
  font-size: 2rem;
  font-weight: 700;
}

.mrkt-place p{
  margin-bottom: 0.2rem;
}
.mrkt-place .bold-heading{
  margin-bottom: 0.5rem;
}
.inner-btn-box{
  display: flex;
  min-width: 11.699rem;
}
.inner-btn-box.footer{
  text-align: left;
}
.inner-btn-box.footer {
    text-align: left;
    min-width: 11.699rem;
    width: fit-content;
}
.inner-btn-box .btn-blue {
    color: #fff;
    border-radius: 0.3rem;
    padding: 1.1422rem 1rem;
    font-size: 0.6rem;
    text-align: center;
     width: 100%; 
}
a.btn-white {
  padding: 0.8rem 4rem;
  color: #000;
  font-size: 0.8rem;
  text-transform: uppercase;
}
.marketplace .save-btn {
  background-color: #adb5bdb8;
  color: #fff;
  padding: 0.5rem 3.5rem;
  border-radius: 0.5rem;
  opacity: 0.5;
  margin-top: 2rem;
}
.marketplace .card-title h1 {
    font-size: 1.75rem;
    font-weight: 700;
    color: #2A2A2A;
}
.card-title h1 a {
  left: 0;
  font-size: 1.5rem;
}
.google-map iframe{
  border-radius: 0.5rem;
}
.right-banner.bg-gray-200 {
  height: 19rem;
  position: relative;
}
.right-banner.bg-gray-200.bg--banner--img{
  background-repeat: no-repeat;
  background-size: cover !important;
  border-radius: 0.9375em;
  box-shadow: 0 1px 3px rgb(245 245 245 / 12%), 0 1px 2px rgb(0 0 0 / 24%);
  border: 1px solid;
}

.profile-upload-circle {
    position: absolute;
    bottom: -22%;
    left: 10%;
    width: 9.87rem;
    height: 9.87rem;
    border-radius: 50%;
    border: 2px solid #fff;
    background: #ddd;
    box-shadow: 0px 3px 24px #00000026;
}
.bg--img-center {
    position: relative;
    height: 9.87rem;
    border-radius: 50%;
    overflow: hidden;
}
.bg--img-center img {
    position: absolute;
    left: 50%;
    height: 100%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
}
.right-camera-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  font-size: 0.7rem;
  color: #fff;
  width: 1.9rem;
  border-radius: 50%;
  height: 1.9rem;
  background: #808080;
}
.profile-upload-circle .fa {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: 0;
  line-height: 2;
  font-size: 0.7rem;
  color: #fff;
  width: 1.9rem;
  border-radius: 50%;
  height: 1.9rem;
  left: 50%;
  background: #808080;
  transform: translate(-50%, -30%);
}
.w-6\/12.col-padding {
  padding: 0 1rem;
}

.mrkt-place.form .main-row{
  margin: 0 -1rem;
}

.site__header.mrkt-place-header{
  background: #fff;
}

.marketplace .site__width{
  max-width: 91.5rem;
  width: 95%;
}
.marketplace .form__item label{
  text-align: left;
  font-weight: 600;
  text-transform: unset;
}
.marketplace .form__item input {
    border-radius: 0.5rem;
    border: 1px solid #ddd;
    background: #fff;
    height: 3rem;
}
.marketplace .form__item select{
  border-radius: 0.5rem;
  border: 1px solid #ddd;
  background: #fff;
  height: 3rem;
}
.marketplace .form__item input::placeholder{
  font-size: 0.8rem;
  color: #2A2A2A;
}
.marketplace .form__item select::placeholder{
  font-size: 0.8rem;
}
.btn-blue-bg button {
    background: #4BB3FD;
    color: #fff;
    padding: 0.8rem 1.269rem;
    border-radius: 0.5rem;
    width: 100%;
}

.reset a {
    color: #A1A1A1;
    font-size: 0.875rem;
    font-weight: normal;
}
.product-desc.no-margin {
    margin: 0 0.5rem;
}
.newly li .product-img {
    margin: 0 0.5rem;
}
.product-type .newly{
  margin: 0 -0.5rem;
}

.shop-now-btn span {
    background: #ddd;
    padding: 0.8rem 1rem;
    border-radius: 2rem;
    font-size: 0.7rem;
    color: #000;
}
.marketplace .top-search button[type="submit"]{
  left: 0;
  top: 0.125rem;
}
.marketplace .site_search input{
  padding-left: 3.5em;
  height: 2.5rem;
  border-radius: 0.5em;
}
.marketplace .icon-bg{
  background: #E5E5E5;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
}
.marketplace .icon-bg .fa{
  color: #616161;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 0.8rem;
}
.marketplace .noti{
  background: #4BB3FD;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.6rem;
  padding: 0.7rem;
  position: absolute;
  right: 0;
  top: 0;
}
.marketplace .country-flag {
  border: 1px solid #ddd;
  margin-left: 1rem;
}
.marketplace ul.main_menu-list.flex li {
  padding: 0.4rem;
}
.marketplace .banner-heading h4 {
    font-size: 1.875rem;
    font-weight: 700;
    width: 100%;
    text-align: right;
}
body.marketplace .banner__content{
  padding: 1.5rem 4rem;
}
.marketplace .banner-heading {
    padding-bottom: 3rem;
}
.marketplace .site-align.justify-content-center{
  justify-content: center;
}
.page-haeding {
    font-size: 1.64rem;
    font-weight: 600;
    text-align: center;
    color: #2A2A2A;
    margin-bottom: 0.8rem;
}
.font-size--19 {
    font-size: 1.115rem;
}
.page-padding {
    margin: auto;
    max-width: 58.5rem;
}
.page-padding p{
  color: #818181;
}

.btn--w.btn-blue-bg a {
    background: #4BB3FD;
    padding: 1.099rem 3rem;
    color: #fff;
    border-radius: 0.5rem;
}
.btn--w.btn-blue-bg {
    max-width: 30.5rem;
    text-align: right;
    width: 100%;
}
ul.Pay-Box li {
    max-width: 11.125rem;
    width: 100%;
    background: #fff;
    height: 11.125rem;
    box-shadow: 0px 16px 32px #919EAB3D;
    margin: 0 0.5rem;
    padding: 4rem;
}
ul.Pay-Box li h4{
  margin-top: 1rem;
}
.Pay-Box .icon .fa {
    color: #4BB3FD;
}
.Pay-Box.mt-15 {
    margin-top: 5rem;
    margin-left: -0.5rem;
    margin-right: -0.5rem;
    justify-content: center;
}
.configure {
    max-width: 52.5rem;
    margin: auto;
}
.bg--white{
  background: #fff;
}
.marketplace .main__content{
  padding-top: 3.5rem;
}
.marketplace .configure h1{
  font-size: 1.27rem;
  font-weight: 700;
  color: #2A2A2A;
}
.marketplace .menu-list-item .fa{
  color: #A1A1A1;
}
.inner-btn-box.close {
    min-width:  initial;
}

.top_left-header.site-align.w-full {
    justify-content: initial;
}
.configure-store-form .form__item{
  border: 2px solid #e8e8e8;
  border-radius: 0.3125rem;
}
.configure-store-form .form__item input{
  border: none;
  padding-top: 0;
  height: auto;
}
.configure-store-form .form__item label, textarea{
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.75rem;
    color: #666666;
    font-size: 0.875rem;
}
.configure-store-form .form__item input::placeholder{
  color: #000;
  font-size: 1rem;
  font-weight: 700;
}
.configure-store-form .form__item select{
  color: #000;
  font-size: 1rem;
  font-weight: 700;
}
.configure-store-form textarea{
  background: #fff;
  min-height: 14.643rem;
  height: 100%;
}
.configure-store-form .form__item input, .form__item select, .form__item textarea, .form__item button{
  border-bottom: none;
}
.marketplace .configure-store-form .form__item select{
  border: none;
  padding-top: 0;
  height: auto;
}
.configure.inner-box-padding{
  padding: 0.9375rem;
  background: #fff;
  border-radius: 0.9375rem;
}
.add--cover--photo .fa{
  color: #5B5B5B;
  margin-right: 1.4375rem;
  font-size: 2rem;
}
.add--cover--photo input{
  display: none;
}
.add--cover--photo label{
  display: flex;
  align-items: center;
}
.hide-input input{
  display: none;
}
.w-full.col-padding {
    padding: 0 1rem;
}
.mrkt-place-product{
  margin-left: -1rem;
}
.font--23{
  font-size: 1.4375rem;
  font-weight: 700;
}
.mrkt-place-product.mt-5 .mrkt-place.form {
  padding: 2.5rem 0 0 1rem;
}
.mrkt-place-product .form__item label, textarea{
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.mrkt-place-product .form__item select{
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.marketplace.add-product .mrkt-place-product input{
  padding-top: 1.45rem;
  padding-bottom: 1.45rem;
}
.marketplace.add-product .mrkt-place-product select{
  padding-top: 1.45rem;
  padding-bottom: 1.45rem;
}
.marketplace.add-product .mrkt-place-product select option{
  color: #666666;
  font-weight: 400;
}
.marketplace.add-product .mrkt-place-product input::placeholder{
  color: #666666;
  font-weight: 400;
}
.user__pic{
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}
.user__pic img{
  border-radius: 50%;
}
.mrkt-place-product .user__pic{
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}
.mrkt-place-product .font--23{
  padding-left: 1.5625rem;
}
.mrkt-place-product .user__pic img{
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
}
.width--submitation{
  max-width: 49.1rem;
  width: 100%;
  margin: auto;
  text-align: center;
  background: #fff;
  box-shadow: #919EAB3D;
  border-radius: 0.625rem;
  padding: 4rem;
}
.width--submitation .icon{
  margin-bottom: 2.25rem;
}
.width--submitation .fa{
  color: #37A282;
  font-size: 7rem;
}
.width--submitation h3{
  font-size: 1.75em;
  color: #2A2A2A;
  line-height: 1.7rem;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif !important;
}
.width--submitation p {
    font-size: 1.5rem;
}
.marketplace.submitation .page-padding{
  max-width: 100%;
}
.inner-btn-box.btn-style-2 .btn-blue {
    color: #fff;
    border-radius: 0.3rem;
    padding: 1.25rem;
    font-size: 0.875rem;
    max-width: 14.4rem;
    text-align: center;
    margin-top: 2rem;
}
.marketplace .tab-content-left-section{
  width: 100%;
}
.marketplace .tabs__wrapper{
  margin-bottom: 0;
  border-bottom-width: 0;
}
.marketplace .tabs__wrapper span.btn {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  background: #e8e7e7;
  border-bottom: 0;
  max-width: 8rem;
  width: 100%;
  text-align: center;
  padding-top: 1.20rem;
  padding-bottom: 1.20rem;
  margin-right: 0.7rem;
  font-size: 1rem;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}
.marketplace .tabs__wrapper span.btn.active-button span.tab-list-data {
  display: block;
}
.marketplace .tabs__wrapper span.btn.active-button {
  background: #fff;
}
.marketplace .middle-long__section-block{
  margin-left: 0;
}
.marketplace .tabs__wrapper span.btn.active-button, span.btn.active-button:hover{
  border-bottom: none;
}
.marketplace .tabs__wrapper span.btn.active-button, span.btn.active-button:hover{
  color: #000;
}
.marketplace .tabs__wrapper span.tab__icon .fa {
  color: #37A282;
  font-size: 1.65rem;
}
.marketplace .tabs__wrapper {
  display: flex;
}
.marketplace .tabs__wrapper span.btn span.tab-list-data {
  display: none;
}
.configure.header-align {
  max-width: 72rem;
}
.back-btn-small{
  font-size: 1rem;
  font-weight: normal;
}
.back-btn-small a{
  color: #000;
}
.marketplace .tabs__wrapper span.btn.active-button.active-shadow{
  box-shadow: 0px 3px 6px #00000029;
  background: #fff;
}
.show-total-no{
  float: right;
  margin-top: 2rem;
}
.total__products {
  margin-top: 6rem;
}
.total__products h6 {
    font-weight: 700;
    font-size: 1.47rem;
}
.box-bg-color {
    padding: 2rem 1rem;
    border-radius: 1rem;
    color: #fff;
    margin-right: 3rem;
    height: 21em;
}
.select-bg-color{
  color: #4BB3FD;
  font-size: 1.25rem;
}

.bg-primary{
  background-color: #2594FF;
}
.bg-green{
  background-color: #4AAE4E;
}
.bg-orange{
  background-color: #FF9025;
}
.bg-red{
  background-color: #FF3B25;
}

.download--icon img{
  margin-left: auto;
}
.marketplace .total__products p {
  color: #fff;
}


.tab.home-section.active-tab.no-padding {
  padding: 0;
}
.total__products h6 .color-teal {
    color: #37A282;
}
.total__products .add-product .fa{
  color: #37A282;
}
.total__products .add-product .fa{
  color: #37A282;
}
.total__products .add-product a{
  color: #000;
  font-weight: 700;
  font-size: 1.47rem;
}
.total__products.mt--0{
  margin-top: 0;
}
.total__products.mt--0 .mt--10{
  margin-top: 3.3rem;
}
.show-total-no p {
  font-size: 1.25rem;
  color: #666666;
  font-weight: 300;
}
body.marketplace.analytics .profile-upload-circle .fa{
  right: -20%;
  left: initial;
}
.show-total-no p strong {
    font-size: 1.25rem;
    font-weight: 700;
    color: #000;
    padding-left: 1.5rem;
}
.box-bg-color:nth-last-child(1){
  margin-right: 0;
}
.marketplace .tabs__wrapper span.btn{
  max-width: 13.178rem;
}
.marketplace .tabs__wrapper span.btn{
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  justify-content: space-evenly;
}
.marketplace .site__header, .marketplace .site__footer{
}
.tabs__wrapper em {
    font-style: normal;
    font-size: 0.935rem;
}
h4.heading--w.text-white.text-center {
    text-align: center;
}
.tabs__wrapper.multi-tabs span.btn.active-button em {
    display: block;
}
.tabs__wrapper.multi-tabs{
  flex-wrap: wrap;
  justify-content: space-between;
}

.tabs__wrapper.multi-tabs .btn em {
    display: none;
}
.tabs__wrapper.multi-tabs span.btn.active-button {
    max-width: 13.2em;
    width: 100%;
}
.tabs__wrapper.multi-tabs span.btn {
    max-width: 5.3rem;
}
.tabs__wrapper.multi-tabs span.btn:nth-last-child(1){
  margin-right: 0;
}
body.marketplace.analytics span.btn.active-shadow{
  padding-top: 1rem;
  padding-bottom: 1rem;
  background: #F8F8F8;
}
.prdt-coln-w{
  max-width: 9.1rem;
  width: 100%;
  position: relative;
  margin-right: 1rem;
}
.prdt-coln-w .edit-del {
    position: absolute;
    top: 0;
    padding: 1rem;
    width: 100%;
    left: 0;
    right: 0;
}
.prdt-coln-w p.bg-edit .fa {
    color: #fff;
    font-size: 0.6rem;
}
.prdt-coln-w p.bg-edit {
    width: 1rem;
    height: 1rem;
    background: #b5b1b1;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.5rem;
}
.prdt-coln-w ul.rating{
  display: flex;
  margin: 0;
}
.prdt-coln-w ul.rating li .fa {
    color: #FEB303;
    padding: 0 0.1rem;
    font-size: 0.7rem;
}
li.prdt-coln-w h4 {
    font-size: 0.82rem;
    margin-bottom: 0;
}
li.prdt-coln-w .product-desc p {
    font-size: 0.766rem;
}
.tab.no-padding.active-tab {
    padding: 1rem 0;
}
.product-type .neg--margin{
  margin-right: -1rem;
}
.mrkt-place.form.padding--5 {
  max-width: 79%;
  width: 100%;
  margin: auto;
}
.page-padding.text-center.page-list .Pay-Box.mt-15 {
    background: #fff;
    max-width: 13.188rem;
    margin-left: auto;
    width: 100%;
    padding: 1rem;
    text-align: left;
}
.page-padding.text-center.page-list .Pay-Box.mt-15 ul li{
  font-size: 1.01rem;
  padding-bottom: 1.45rem;
}
.page-padding.text-center.page-list .Pay-Box.mt-15 ul li:nth-last-child(1){
  padding-bottom: 0;
}
.store-banner-image {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
  border-radius: 0.313rem;
}
.store-logo-image{
  width: 9.87rem;
  height: 9.87rem;
  border-radius: 50%;
}