.navBar{
    @apply flex justify-between items-center w-full px-10 py-3 fixed top-0 z-50 transition-all ;
}
.navBar.scrolled{
    @apply bg-white  shadow-md;
}
.navBar.top{
    @apply bg-transparent text-white;
}
.innerPage.navBar{
    background-color: white;
}

.innerPage.navBar .navBar-list li, .navBar.scrolled .navBar-list li{
    color: black;
}
.innerPage.navBar .navBtn-group a, .navBar.scrolled .navBtn-group a{
    color: #004577;
    border-color: #004577;
}
.innerPage.navBar .navBtn-group .btn-green-accent, .navBar.scrolled .navBtn-group .btn-green-accent{
    border-color: #37A282;
}
.landing-banner:before {
    content: '';
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
.btn-royal{
    background-color: #004577;
    color: #fff !important;
    border: 2px solid #004577;
    border-radius: 4px;
    font-weight: 600;
    @apply px-3 py-2
}
.btn-green-accent{
    background-color: #37A282;
    color: #fff !important;
    border: 2px solid #37A282;
    border-radius: 4px;
    font-weight: 600;
    @apply px-3 py-2
}
.text-green-accent{
 color: #37A282;
}
.border-btn-royal{
    @apply  rounded-md;
    border: 2px solid #004577;
    color: #004577;
    background-color: white;
 }
.text-royal{
    color: #004577;
}
.border-royal{
    border-color: #004577;
}
.leading-heading{
    line-height:85px;
}
.text-dim{
    color: #5C5D5F;
}
.bg-skyblue{
    background-color: #E4F4FF;
}
.w-45{
    width: 45%
}
.w-55{
    width: 55%
}
.demo{
    background-image: url("../assets/landing-page/blue-bg.svg");
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    bottom: 0;
}
.demo-btn{
    background-color: #37A282;
    color:  #fff;
    padding: .8rem .5rem;
    width: 12rem;
    font-size: 16px;
    display: inline-block;
    text-align: center;
    font-weight: 600;
}
.bg-dim{
    background-color: #F4F4F4;
}
.arrowUp{
    transform: rotate(320deg);
}
.arrowDown{
    transform: rotate(45deg);
}
.market-place-img{
    max-height: 32rem;
}
.translate-middle{
    transform: translate(-50%, -50%);
}
.brands-logo-container img{
    width: 9rem !important;
    mix-blend-mode: multiply !important;
    margin: auto;
}
.testimonial-card{
    background-color: #F9F9F9;
    margin-right: 1rem;
    padding: 1rem;
}
 /* the slides */
 .slick-slide {
    margin: 0 10px;
}

/* the parent */
.slick-list {
    margin: 0 -10px;
}
.blog-container{
    background: radial-gradient(circle at 50% 80%, #0091d2, #349bd7, #4fa6db, #66b0e0, #7abae4, #8ec4e8, #a1ceec, #b4d8f0, #c7e1f4, #daebf8, #ecf5fc, #ffffff);
}
.bg-green-accent{
    background:  #37A282;
}
.accordion-title{
    background-color: #EFEFF0;
    color: #5C5D5F;
    padding: 1rem;
}
.faq-container{
    background-color: #F7F7F8;
}
.newsletter-container{
    background: url("../assets/landing-page/Newsletter.png");
    background-repeat: no-repeat;
    background-size: coverbtn;
}
.newsletter-input{
    background-color: white;
    border-radius: 40px;
    display: flex;
}
.newsletter-input input:-webkit-autofill,
.newsletter-input input:-webkit-autofill:hover, 
.newsletter-input input:-webkit-autofill:focus, 
.newsletter-input input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}
.newsletter-input input{
    color: black;
    outline: none;
    width: 100%;
    border: none;
    background-color: transparent;
    padding: .5rem 1rem;
    border-radius: 40px;
}
.newsletter-input button{
    background-color: #4BB3FD;
    color: white;
    border-radius: 40px;
    padding: .8rem 1rem;
    min-width: 8rem;
}
.service-banner{
    background: url("../assets/landing-page/service-banner-bg.png");
    background-size: cover;
}
.contact-container{
    background: url("../assets/landing-page/contact-bg.png");
    background-size: cover;
    top: 4rem;
    height: calc(100vh - 4rem);
    overflow-y: auto;
}
.contact-bg{
    background: white;
    top: 4rem;
    height: calc(100vh - 4rem);
    overflow-y: auto;
}
.input-container .input-group{
    @apply flex flex-col gap-2 
}
.input-container .input-group label{
    font-weight: 500;
}
.input-container .input-group input,.input-container .input-group textarea{
    @apply w-full px-3 py-2 rounded-md;
    border: 1.6px solid #1F1F1F4D;
}
.flexRow{
    flex-direction: row;
}
.flexRowReverse{
    flex-direction: row-reverse;
}
.animate-from-bottom {
    opacity: 0;
    transform: translate3d(0, 100px, 0);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }
  
  .animate-from-bottom.animate {
    opacity: 1;
    transform: translateZ(0);
  }
  .elipsis-4{
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
  }
  .footer-logo{
    @apply h-10 absolute left-1/2 top-1/2 ;
    transform: translate(-50%, -50%);
  }
div#conservation_method span {
    display: none;
}

div#product_categories span {
    display: none;
}

div#product_properties span {
    display: none;
}

div#region span {
    display: none;
}

.search-wrapper {
    border: none !important;
}

.form__item #brand_labels option {
    text-transform: none;
}

.tab-content-right-section .ingredients__List {
    overflow-y: auto;
    overflow-x: hidden;
    height: 28rem;
}

/* Buyer Css Start */
.buyerDashboard .headerBlock {
    padding-left: 13rem;
    background: #fff;
    padding-top: 1rem;
    padding-bottom: 1rem;
    height: 3.5rem;
    position: fixed;
    top: 0;
    width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.05);
    z-index: 9;
}

.buyerDashboard .centerContent {
    padding-left: 13rem;
    padding-top: 4.25rem;
}

.buyerDashboard .sideBarBlock {
    max-width: 12rem;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background: #fff;
    z-index: 9;
    border-right: 0.063rem solid #ddd;
}

.sideBarBlock ul li a {
    font-size: 1rem;
    display: flex;
    align-items: center;
    padding: 0.75rem;
    color: #000;
    font-weight: 500;
    gap: 0.5rem;
}

.sideBarBlock ul li a .Icon {
    width: 1.125rem;
    height: 1.125rem;
}

.sideBarBlock ul li a .Icon svg {
    width: 100%;
    height: 100%;
}

.sideBar ul li a.Active {
    background: #004577;
    color: #fff;
}

.sideBar ul li a.Active svg path {
    fill: #fff;
}

.sideBar .site__logo {
    padding: 0.5rem;
    width: 100%;
    height: 3.5rem;
    display: flex;
    align-items: center;
}

.pageTitle h4 {
    font-size: 1rem;
    font-weight: 700;
}

.secTitle {
    font-size: 1.25rem;
    font-weight: 700;
    color: #004577;
    margin-bottom: 0.5rem;
}

.fs-18 {
    font-size: 0.75rem;
    ;
}

.boxTitle {
    font-size: 1rem;
    font-weight: 700;
    color: #000;
}

.centerContent {
    padding: 0.75rem;
    padding-left: 0;
}

.reqBlock .fs-14 {
    font-size: 0.875rem;
    font-weight: 700;
}

.reqBlock .blockAlign {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.elipsis {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.table-responsive {
    overflow-x: auto;
}

.reqBlock .blockLeft {
    flex-direction: column;
    max-width: 70%;
    text-transform: capitalize;
}

.dummyDataBlock {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    flex-direction: column;
    gap: 1rem;
}

.grid2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.notificationView .noti_no {
    top: -0.5rem;
}

.faqImage {
    padding-left: 0.5rem;
}

/* .faqImage img {
    width: 100%;
} */
.chooseAlysei .bgBlue {
    background: #4E95D9;
    padding: 1rem;
    border-radius: 0.35rem;
    color: #fff;
    text-align: center;
    margin-top: 2rem;
}

.ReactModal__Body--open .forModal {
    height: 100vh;
    overflow: hidden;
}

.msgReciver.right_message {
    text-align: right;
    margin-left: auto;
}

.msgReciver.right_message p.importertitle {
    margin-left: auto;
}

.msgReciver.right_message .chatTime .chatDate {
    text-align: right;
}

.msgReciver.right_message {
    text-align: right;
    max-width: 100%;
}

.messageQuery .right-msg .msg-box {
    margin-left: auto;
}

.messageQuery .chatDate {
    font-size: 0.75rem;
    color: #666666;
}

.messageQuery .right-msg .chatDate {
    text-align: right;
}

.msg-box {
    background: #EDEDED;
    padding: 0.35rem;
    border-radius: 0.25rem;
    width: max-content;
}

.uploadImg.extra {
    position: relative;
    display: flex;
}

.uploadImg.extra .show_all_icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-weight: 500;
    color: #fff;
    font-size: .875rem;
    font-weight: 500;
    cursor: pointer;
}

.uploadImg.extra .show_all_icon .img_count_no {
    font-size: 1.2rem;
}

.attachedFileGroup.grid-col-2 {
    grid-template-columns: repeat(2, 1fr);
}

.attachedFileGroupflex {
    display: flex;
    gap: 1rem;
}

.right_message .attachedFileGroupflex {
    justify-content: end;
}

.recentReq {
    max-width: 21rem;
    width: 100%;
}

.graphView {
    max-width: 43.45rem;
    width: 100%;
}

.graphViewCard .card,
.graphViewCard {
    height: 100%;
}

.topLayout {
    display: flex;
    gap: 1rem;
}

.card-gap {
    padding: 0.75rem;
}

.reqBlock .fs-13 {
    font-size: 0.75rem;
}

.reqBlock .blockRight {
    max-width: 30%;
}

.btnBlue {
    background: #004577;
    color: #fff;
    border-radius: 3rem;
    min-width: 5rem;
    display: inline-block;
    width: 100%;
    font-size: 0.7rem;
    font-weight: 600;
    padding: 0.35rem 0.5rem;
}

.customTab ul {
    display: flex;
    align-items: center;
}

.customTab ul li {
    font-size: 0.875rem;
    font-weight: 600;
    padding: 0.25rem 0.5rem;
    cursor: pointer;
}

.recentReq .boxTitle {
    color: #000;
    font-size: 1rem;
    font-weight: 700;
}

.whyChoose .cardGroup .cardItem .card {
    height: 100%;
}

.chooseAlysei .cardGroup .cardItem .card {
    text-align: center;
}

.reqBlock {
    height: 16rem;
    overflow-y: auto;
    padding-right: 0.5rem;
}

.customTab ul li.react-tabs__tab--selected {
    color: #004577 !important;
    border-bottom: 0.12rem solid #004577;
}

.customTabContent {
    margin-top: 1rem;
}

.sectionGap {
    padding: 0.5rem 0;
}

.boxTitle {
    font-size: 1.25rem;
    color: #004577;
    font-weight: 400;
}

.secTitleLarge {
    font-size: 2rem;
    font-weight: 700;
    color: #004577;
}

.whereToStart .secBlockLeft {
    max-width: 70%;
    width: 100%;
    padding-right: 2rem;
}

.whereToStart .secBlockRight {
    max-width: 30%;
    width: 100%;
}

.whereToStart .flexBox {
    align-items: center;
}

.whereToStart ul {
    margin-top: 1rem;
    padding-left: 1.5rem;
}

.whereToStart ul li:not(:last-child) {
    margin-bottom: 0.5rem;
}

.whereToStart ul li {
    font-size: 1rem;
    font-weight: 500;
    color: #000;
    list-style: disc;
}

.secTitleCenter .fs-24 {
    color: #004577;
    text-align: center;
    font-size: 1.125rem;
    font-weight: 500;
    margin-bottom: .5rem;
}

.bg-blue-content {
    font-size: 1.1rem;
}

.secTitleCenter h5 {
    font-size: 2rem;
    font-weight: 700 !important;
    color: #004577 !important;
}

.secTitleCenter {
    text-align: center;
    width: 100%;
    margin: auto;
}

.secTitleCenter p {
    font-size: 1.1rem;
    font-weight: 500;
}

.subTitle {
    font-size: 1.1rem;
    font-weight: 500;
}

.secTitleCenter h5 {
    font-size: 1.6rem;
}

.infoIconCard .iconBlock {
    max-width: 6.5rem;
    width: 100%;
    height: 6.5rem;
    background: #f4f4f4;
    border-radius: 0.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

.infoIconCard .iconBlock svg {
    width: 3rem;
    height: 3rem;
}

.infoIconCard .titleBlue {
    font-size: 1.25rem;
    color: #004577;
    font-weight: 700;
}

.infoIconCard p {
    font-size: 1rem;
}

.infoIconCard {
    max-width: 33.33%;
    width: 100%;
    text-align: center;
    margin-top: 2.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
}

.infoIconBlock {
    margin-top: 1.75rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.cardGroup {
    margin-top: 2rem;
    display: flex;
    flex-wrap: wrap;
}

.whyChoose .newSiteWidth {
    max-width: 90%;
    width: 100%;
    margin: auto;
}

.whyChoose .cardGroup .cardItem {
    max-width: 33.33%;
    width: 100%;
    padding: 0.5rem;
}

.whyChoose .cardGroup .cardItem h4 {
    font-size: 1.25rem;
    color: #004577;
    font-weight: 700;
}

.whyChoose .cardGroup .cardItem p {
    margin-top: 0.5rem;
    color: #000;
    font-weight: 500;
}

.partnerView h5 {
    color: #004577 !important;
    text-align: center;
    font-size: 1.25rem;
    font-weight: 700 !important;
}

.partnerLogo {
    max-width: 9rem;
    width: 100%;
    margin: auto;
}

.Partners .flexBox {
    align-items: center;
}

.footerView p {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.footerView p span {
    max-width: 4rem;
    width: 100%;
}

.graphImage img {
    width: 100%;
}

.leftData {
    max-width: 24rem;
    width: 100%;
}

.aboutView .flexBox {
    column-gap: 1rem;
}

.centerData {
    max-width: 20rem;
    width: 100%;
}

.pendingStatus.resolved {
    color: #fff;
}

.buyerDashboard .btn.btn--gray {
    background: #EAEAEA;
    min-width: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    border-radius: 0;
    font-size: 0.927rem !important;
    padding: 0.65rem;
}

.noCount.Teal {
    background: #33A386;
    max-width: 1.5rem;
    width: 100%;
    height: 1.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 0.65rem;
    padding-left: 0;
}

.buyerDashboard .about_profile h4 span {
    display: flex;
}

.buyerDashboard .about_profile h4 span.text-green {
    justify-content: flex-end;
}

.text-green {
    color: rgba(67, 160, 71, 1);
}

.text-teal.chatDone {
    color: #000 !important;
    font-weight: 400;
}

.chatFooter .btn-blue.style2 {
    padding: 0.5rem;
}

.buyerDashboard .dashboardView .middleBlockBg,
.buyerDashboard .dashboardView .sendInquiry form.cmnt-form.replay_cmnt {
    max-width: 100%;
}

.buyerDashboard .dashboardView .sendInquiry .cmnt-form.replay_cmnt {
    border: none;
    /* gap: 1rem; */
    padding-bottom: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    bottom: -4rem;
}

.buyerDashboard .card.card--block.modal_box.notification {
    top: 3.5rem;
    right: 1rem;
}

.noDataImage {
    max-width: 20rem;
    width: 100%;
    margin: auto;
}

.noChatData {
    text-align: center;
}

.rounded2 {
    border-radius: 0.35rem !important;
}

.btn--gray.p-2 {
    padding: 0.5rem !important;
}

.btn--gray.reset {
    padding: 0.445rem !important;
    min-width: 8rem !important;
}

.buyerDashboard .mrktInquiry .inquiryLeftBlock {
    padding-top: 0;
    max-width: 14.5rem;
}

.buyerDashboard .dashboardView .middleBlockBg {
    padding: 0;
}

.mrktInquiry.gap-0 {
    gap: 0;
}

.border-left-0 {
    border-left: none !important;
}

.buyerDashboard .inquiryChatBody {
    padding-left: 1rem;
    padding-right: 1rem;
}

.buyerDashboard .inquiryChatBody .showData {
    border-bottom: 1px solid #ddd;
    margin-left: -1rem;
    margin-right: -1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 0.5rem;
}

.mt-0 {
    margin-top: 0 !important;
}

.buyerDashboard .cmnt-form.replay_cmnt .cmnt-write {
    max-width: 100% !important;
    padding: 0.5rem;
    height: 2.35rem;
    border: 1px solid #ddd;
    padding-right: 2rem;
}

.buyerDashboard .post-icons-option {
    position: absolute;
    right: 10rem;
}

.countryCode {
    position: absolute;
    /* left: 0.5rem; */
    left: 3rem;
    /* top: 50%;
    transform: translateY(-50%) */
}

.countryCode+input[name="contact_number"] {
    padding-left: 2.5rem;
}

.buyerDashboard .msgReciver p.importertitle {
    background: #EDEDED;
    padding: 0.35rem;
    border-radius: 0.25rem;
    max-width: max-content;
}

section.buyerDashboard .pageContent.admin_approval.approved {
    height: calc(100vh - 5rem);
    overflow: hidden;
}

.msgReciver .prdtDes {
    padding-left: 10px;
    padding-right: 10px;
}

.msgReciver .prdctHead {
    padding-bottom: 3px;
}

.buyerDashboard .feed-user-info.home_page {
    align-items: center;
}

.buyerDashboard .msgReciver {
    box-shadow: none;
    background: transparent;
    /* max-width: 20rem; */
    width: 100%;
    margin: inherit;
    margin-top: .5rem;
    padding: 0;
}

.buyerDashboard .chatTime .chatDate {
    text-align: left;
    font-size: 0.75rem;
}

.row-gap-2 {
    row-gap: 0.5rem !important;
}

.buyerDashboard .showData .flex {
    column-gap: 1.5rem;
    row-gap: 0.5rem;
}

.buyerDashboard .width--submitation {
    /* margin-top: 7rem; */
    margin-top: 1rem;
}

.buyerDashboard .headerView {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 0.5rem;
}

.buyerDashboard .searchBar input[type="text"] {
    border: none !important;
}

/* Title CSS Start */

/* Title CSS End */


select:disabled {
    -webkit-appearance: none;
    -moz-appearance: none;
    min-width: 5rem;
    text-align: center;
}

.bgRed.newBadge {
    background: #004577;
    max-width: 70%;
    padding: 0.5rem;
    border-radius: 0.35rem;
    color: #fff;
    margin: auto;
    text-align: center;
}

.bgRed.newBadge p {
    color: #fff !important;
    font-weight: 500;
}

.rightData {
    max-width: 18rem;
    width: 100%;
}

.faqItem h4 {
    font-size: 1.125rem;
    font-weight: 600;
    color: #000;
    /* margin-bottom: 1rem; */
    cursor: pointer;
}

.faqImage img {
    max-width: 100%;
}

.accordionRow {
    margin-bottom: .725rem;
}

.faqItem p {
    display: none;
}

.faqItem.Active p {
    display: block;
}

.rightData {
    border-left: 0.125rem solid #ddd;
}

.rightData .faqItem.Active::before {
    border-left: 0.125rem solid #37A282;
    content: "";
    position: absolute;
    left: 0rem;
    height: 100%;
}

.faqItem {
    padding-left: 0.5rem;
    margin-left: -0.125rem;
    transition: 0.5s ease;
    position: relative;
}

.aboutUs .secTitleLarge {
    font-size: 1.25rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.centerData .bgBlue {
    background: #004577;
    padding: 0.875rem;
    border-radius: 0.5rem;
    color: #fff;
}

.centerData h4 {
    font-size: 1.125rem;
    font-weight: 700;
    margin: 0.45rem 0;
}

.centerData .cardBadge h4 {
    margin: 0;
    color: #000;
}

.smallBadge {
    width: 3.5rem;
    height: 1.5rem;
    background: #37A282;
    border-radius: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
}

.centerData .Circle {
    max-width: 3rem;
    width: 100%;
    height: 3rem;
    border-radius: 50%;
    background: #37A282;
    display: flex;
    align-items: center;
    justify-content: center;
}

.centerData .card-gap {
    padding: 0.65rem;
}

a.btn.storeBtn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    width: 5rem;
}

.inner-btn-box.btnWidth {
    min-width: 5rem;
}

.bgTeal {
    background: #37A282;
    color: #fff;
}

.centerDate .leftBox {
    max-width: 5rem;
    width: 100%;
}

.subscriptionCard .pricingTableRow .fa.fa-times {
    color: #F44336;
}

.centerData .leftBox {
    max-width: 16%;
    width: 100%;
}

.centerData .leftBox h4 {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0;
    line-height: 1;
}

.centerData .leftBox h4 span {
    font-size: 1rem;
    font-weight: 4;
}

.centerData .rightBox p {
    font-size: .95rem;
    text-align: center;
}

.centerData .bgTeal .flexBox {
    align-items: center;
}

.customAccordion {
    background: #fff;
}

.customAccordion .accordion__heading .accordion__button {
    background: #fff;
    padding: 0.5rem;
    font-weight: 600;
    color: #000;
    font-size: 1rem;
}

.accordion__panel {
    padding: 0.5rem 0.5rem 0.75rem 0.5rem !important;
}

.accordion__item {
    border-left: 0.125rem solid #ddd;
}

.accordion__button[aria-expanded='true'],
.accordion__button[aria-selected='true'] {
    border-left: 0.125rem solid #37A282 !important;
    margin-left: -0.15rem;
}

.customAccordion .accordion__heading .accordion__button::before {
    display: none;
}

.accordion__item+.accordion__item {
    border-top: none !important;
}

.innerTitle {
    text-align: center;
}

.innerTitle h4 {
    font-size: 1.5rem;
    font-weight: 700;
}

.innerTitle h4 {
    font-size: 1.5rem;
    font-weight: 700;
}

.filterData {
    margin-top: 2rem;
}

.form__item.whiteBg select,
.form__item.whiteBg input,
.form__item.whiteBg textarea {
    background: #fff;
    border: 1px solid #CACACA;
    border-radius: 0.25rem;
}

.form__item.whiteBg input[type="text"]::placeholder {
    color: #495057;
}

.btn-blue.style2 {
    padding: 0.875rem;
    color: #fff;
    border-radius: 0.25rem;
    min-width: 10rem;
    text-transform: capitalize;
}

.text-blue {
    color: #004577 !important;
}

.bgGray {
    background: rgba(0, 69, 119, 0.05);
}

.bgGray.infoIconData {
    padding: 2rem;
    margin-top: 5rem;
    text-align: center;
}

.bgGray.infoIconData .iconBlock svg {
    margin: auto;
}

.bgGray.infoIconData .infoIconItem h4 {
    font-weight: 700;
}

.fs-20 {
    font-size: 1.5rem;
}

.noDataBlock .Icon {
    max-width: 10rem;
    width: 100%;
    margin: auto;
}

.filterLeft,
.filterRight {
    max-width: 50%;
    width: 100%;
}

.filterLeft,
.filterRight {
    max-width: 50%;
    width: 100%;
}

.tableFilter .searchBar {
    position: relative;
}

.tableFilter .searchBar span {
    position: absolute;
    left: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
}

.tableFilter .searchBar {
    background: #fff;
    border-radius: 0.35rem;
    padding-left: 1rem;
    max-width: 20rem;
    width: 100%;
}

.tableFilter .searchBar input[type="text"] {
    background: transparent;
    border-bottom: none;
    border: none;
}

.noRadius {
    border-radius: 0;
}

.flexEnd {
    justify-content: flex-end;
}

.tableFilter .btn-blue.style2 {
    padding: 0.5rem;
    min-width: 8rem;
}

.p-3 {
    padding: 0.75rem;
}

.bgWhite {
    background: #fff;
}

.tableFilter.card.card--block {
    border-bottom: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.newDashboard.bgWhite .tableView {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: 0;
}

.tableView table tr th,
.tableView table tr td {
    border: 1px solid #ddd;
    padding: 0.75rem;
    text-align: left;
}

.tableView table tr th {
    white-space: nowrap;
}

.tableView table tr td {
    word-break: break-word;
}

.tableView {
    overflow-x: auto;
}

.tableView table {
    width: 100%;
}

.buyerDashboard .iconGroup a {
    max-width: 2rem;
    width: 100%;
}

.buyerDashboard .iconGroup .delete {
    max-width: 2rem;
    width: 100%;
}

.dummyData {
    text-align: center;
}

.dummyData .dummyImage {
    max-width: 20rem;
    width: 100%;
    margin: auto;
}

.transactionPrice {
    text-transform: capitalize;
}

.settingSideBar {
    border-right: 1px solid #ddd;
    max-width: 23%;
    width: 100%;
}

.settingSideBar ul li a {
    font-size: 1rem;
    color: #000;
    padding: 0.75rem;
    display: inline-block;
    width: 100%;
    font-weight: 500;
}

.settingSideBar ul li a.Active {
    background: #EEE;
}

.settingView {
    max-width: 75%;
    width: 100%;
    padding: 1rem;
}

.settingView .formView {
    margin-top: 1rem;
}

.settingView .pageTitle {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-weight: 700;
    color: #000;
}

.settingView .formView {
    margin-top: 1rem;
}

.settingView .formView form {
    max-width: 40%;
    width: 100%;
}

.settingView.deleteAccount form {
    max-width: 70%;
}

.searchResult {
    font-size: 1.125rem;
    font-weight: 400;
    color: #000;
}

.textCaps input[type="text"] {
    text-transform: capitalize;
}

/* Buyer Css End */
.customer__name_width {
    width: 10rem;
}

.ReactModal__Content.ReactModal__Content--after-open.buyerModal {
    max-width: 50rem;
}

a.close_btn--modal::after {
    content: "\f00d";
    font-family: fontawesome;
    font-size: 1.5rem;
    left: 50%;
    top: 50%;
    text-indent: 0;
    transform: translate(-50%, -50%);
    position: absolute;
    --tw-text-opacity: 1;
    color: #fff;
}

a.close_btn--modal {
    height: 2rem;
    width: 2rem;
    overflow: hidden;
    right: 0.5rem;
    top: 0.5rem;
    background: #004577;
    text-indent: -20rem;
    border-radius: 50%;
    position: relative;
}

.repeatList h6 {
    font-size: 1rem;
    font-weight: 700;
}

.repeatList {
    margin-bottom: 1rem;
}

.repeatList ul li {
    position: relative;
    margin-bottom: 0.5rem;
    margin-left: 2rem;
}

.repeatList ul li::before {
    content: "\f00c";
    font-family: fontawesome;
    left: -1.5rem;
    position: absolute;
}

/* Subscription Css Start */

.ReactModal__Content.ReactModal__Content--after-open.fullWidthModal {
    max-width: 98%;
    width: 100%;
}

/* .ReactModal__Content.ReactModal__Content--after-open.buyerModal {
    max-width: 100%;
} */

/* .withinfoIcon .ck.ck-dropdown.ck-toolbar__grouped-dropdown.ck-toolbar-dropdown button svg {
    display: none;
} */
.withinfoIcon.flexBox {
    display: flex;
    align-items: center;
}

.labelInfo p {
    margin-bottom: 0.6rem;
}

.bgBlueText {
    background: #D1ECFF;
    padding: 0.75rem;
    border-radius: 0.25rem;
}

.pageTitle {
    font-size: 1.35rem;
    color: #000;
    font-weight: 700;
}

.topBar {
    border-top: 1px solid #E2E2E2;
    border-bottom: 1px solid #E2E2E2;
    padding: 0.5rem;
    margin: 0.5rem 0;
}

.card.card--block.paymentView {
    padding-top: 0.25rem;
}

.cardList .subscriptionCard {
    max-width: 32%;
    width: 100%;
    box-shadow: 0px 0px 2px rgb(145 158 171 / 24%), 0px 0px 6px -4px #000000;
}

.subscriptionCard p {
    font-size: 0.75rem;
    margin: 0.5rem 0;
}

.subscriptionCard .pricingData {
    font-size: 0.75rem;
}

.subscriptionCard h4 {
    font-size: 1.5rem;
    font-weight: 700;
}

.subscriptionCard.card1 .pricingTable {
    border-top: 1px solid #E2E2E2;
}

.memberList .cardList {
    display: flex;
    gap: 1rem;
}

.cardList .subscriptionCard:not(:first-child) {
    text-align: center;
    max-width: 17%;
    width: 100%;
}

.subscriptionCard.card1 .hideText {
    visibility: hidden;
    margin-bottom: 0.45rem;
    /* margin-top: -0.1rem; */
}

.memberButton .btn {
    width: 100%;
    font-size: 1rem;
    font-weight: 700;
    color: #fff;
    padding: 0.75rem;
    border-radius: 3rem;
}

.memberButton .moreInfo {
    color: #004577;
    margin-top: 0.5rem;
    font-size: 0.875rem;
    text-decoration: underline;
}

.subscriptionCard .pricingTableRow .fa {
    color: #43A047;
}

.memberButton .btn1 {
    background: #96D1FC;
}

.memberButton .btn2 {
    background: #4BB3FD;
}

.memberButton .btn3 {
    background: #37A282;
}

.memberButton .btn4 {
    background: #004577;
}

.subscriptionCard .pricingTableRow {
    padding: 0.5rem;
}

.subscriptionCard .pricingTableRow:not(:last-child) {
    border-bottom: 1px solid #E2E2E2;
}

.ReactModal__Content.ReactModal__Content--after-open.fullWidthModal {
    max-height: 98%;
}

.topBar .taoggle_switch {
    max-width: 10rem;
    width: 100%;
    margin: auto;
    gap: 1rem;
}

.topBar .taoggle_switch label {
    color: #000;
    font-weight: 700;
}

.pageRow {
    display: flex;
    flex-wrap: wrap;
    column-gap: 2.5rem;
}

.pageRow .leftBlock {
    max-width: 30%;
    width: 100%;
}

.pageRow .rightBlock {
    max-width: 65%;
    width: 100%;
}

.repeatList ul {
    margin-top: 0.125rem;
}

.roundedCard {
    background: #E0E0E0;
    padding: 3rem 2.5rem 3rem 2rem;
    ;
    border-radius: 0 8rem 2.25rem 0;
    height: 100%;
    position: relative;
}

.roundedCard.free {
    background: #E0E0E0;
}

.roundedCard.gold {
    background: goldenrod;
}

.roundedCard.platinum {
    background: #E0E0E0;
}

.roundedCard.diamond {
    background: #E0E0E0;
}

.roundedCard h4 {
    font-size: 1.5rem;
    color: #8A7126;
    font-weight: 700;
    margin-bottom: 2rem;
}

.roundedCard.free h4 {
    color: #8A7126;
}

.roundedCard.gold h4 {
    color: #8a2626;
}

.roundedCard.platinum h4,
.roundedCard.diamond h4 {
    color: #2b8a26;
}

.btnBlueSecondary {
    font-size: 1.25rem;
    font-weight: 700;
    background: #2E75B6;
    color: #fff;
    padding: 1.75rem 2rem;
    border-radius: 5rem;
    text-align: center;
    margin: 1rem 0;
}

.subsDescription {
    font-size: 1.25rem;
    color: #575757;
}

.roundedCard .font-18 {
    font-weight: 400;
    line-height: 1.5;
}

.cardBottom {
    margin-top: 2rem;
}

.secureCheckout span {
    width: 1.75rem;
    height: 1.75rem;
    background: rgba(91, 91, 91, 1);
    border-radius: 50%;
    display: inline-block;
    font-size: 0.875rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.secureCheckout {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 0.5rem;
}

/* .benefit-list{
   box-shadow:  0px 0px 2px rgb(145 158 171 / 24%), 0px 0px 6px -4px #000000;
   
} */
.benefit-list li {
    padding: 0.3rem;
    height: 4rem;
    display: flex;
    align-items: baseline;
}

.cardBottom p {
    background-color: #fff;
    padding: .5rem;
    width: 16rem;
}

.secureCheckout span .fa {
    color: #fff;
}

.bgBlueText.bgRed {
    background: #FFCFCF;
    color: #f00;
}

/* Subscription Css End */

/* New Module Css Start */
.btn-blue.light {
    background: #96D1FC !important;
    color: #004577 !important;
    min-width: 11rem !important;
}

.inner-btn-box .btn.light svg {
    width: 1.125rem !important;
    height: 1.125rem !important;
}

.btnNoBg {
    min-width: 5rem;
    font-size: 0.875rem;
    color: #000;
    display: inline-block;
    width: 100%;
    font-weight: normal;
}

.attachItem {
    position: relative;
}

.attachItem .relative {
    position: relative;
}

.attachItem span {
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.attachItem label {
    cursor: pointer;
}

.attachItem span svg {
    width: 1rem;
    height: 1rem;
}

.customForm .form__item label {
    font-size: 0.65rem;
    text-transform: capitalize;
    font-weight: 700;
}

.customForm .form--item {
    background: #fff;
    border: 1.003px solid #C9C9C9;
}

.royal.noRadius {
    border-radius: 0;
    color: #fff;
    text-transform: capitalize;
    min-width: 10rem;
    padding: 0.5rem;
}

@media screen and (max-width: 991px) {
    .suggested {
        display: block;
    }

    .suggested .card.card--block.mb-6 {
        display: none;
    }

    .tab-content-right-section .ingredients__List {
        height: auto;
    }

    .addScroll .left__section-block .connection_scroll {
        position: inherit;
        max-width: 100%;
    }

    .tab-content-right-section .ingredients__List ul {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
}

/* .form__action.otp.access-verify input {
width: 60px !important;
border-radius: 50%;
margin: 5px;
height: 60px;
border-color: #333;
} */
/* for desktop */

.whatsapp_float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
}

.whatsapp-icon {
    margin-top: 16px;
}



.gidColRightpieceNo option[value=""][hidden] {
    display: none;
}

.react-loading-skeleton.avtarPhoto {
    position: absolute;
    height: 9.4rem
}

.user__pic.myProfile {
    border: 0.313rem solid rgb(142, 201, 187);
}

.react-loading-skeleton.coverPhoto {
    height: 21rem;
}

.product-main .sc-dnqmqq.bVoXXg .marketplaceBanner img {
    object-fit: contain;
}

.productImgSlider.recentProd {
    height: 14.5rem;
}

.date {
    width: 5.5rem;
}

.id {
    width: 5rem;
}

.qty {
    max-width: 5rem;
}

.total {
    max-width: 5rem;
}

.btn.BgTeals .loader {
    font-size: 0.15rem !important;
    float: right;
}

.card.card--block.modal_box .feed-user-info.home_page.block.edit.mt-3.mb-3.addReview {
    width: 100%;
}

.card.card--block.modal_box .feed-user-info.home_page.block.edit.mb-3.addReview {
    width: 100%;
}

.card.card--block.modal_box .feed-user-info.home_page.block.edit.mb-3.addReview .flex {
    align-items: center;
}

.product-desc h4 {
    word-break: break-all;
    text-transform: capitalize;
}

.card.card--block.modal_box .feed-user-info.home_page.block.edit.mb-3.addReview .flex {
    align-items: inherit !important;
}

body textarea::-webkit-scrollbar {
    width: 0.5rem !important;
    height: 0.5rem !important;
}

.cmnt-form.replay_cmnt #comment.cmnt-write {
    height: 5.5rem;
}

.msgBlock form.cmnt-form.replay_cmnt #comment.cmnt-write {
    max-height: 4rem;
}

.view__hub__btn a.buttonDisabled {
    cursor: not-allowed;
}

.isDisable a {
    cursor: not-allowed;
}

.msgView .post-icons-option.iconLeft {
    top: 0.75rem;
}

.msgView .post-icons-option.sendBtn {
    top: 0.75rem;
    position: absolute;
    top: 0rem;
    right: 0rem;
}

.msgView .post-icons-option.rightIcon {
    top: 0.75rem;
}

/* Phase 2 Hub View Css Start */
.hub--checkbox--area span {
    color: #004171;
    font-size: 0.9rem;
    font-weight: 700;
}

.hubCityList {
    max-width: 30%;
    width: 100%;
    border-radius: 0.5rem;
    margin-left: -0.5rem;
    margin-top: -0.5rem;
    margin-bottom: -0.5rem;
    overflow-y: auto;
    height: 21rem;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 1px solid #ddd;
}

.selectedCityHub {
    max-width: 50%;
    width: 100%;
}

.selectedHubMap {
    max-width: 30%;
    width: 100%;
}

.hubCityList .cityList input[type="checkbox"],
.cityList.select--all input[type="checkbox"] {
    display: none !important;
}

.hubCityList .cityList input[type="checkbox"]+label,
.cityList.select--all input[type="checkbox"]+label {
    position: relative;
    cursor: pointer;
    padding-left: 2rem;
    color: #004171;
    font-weight: 600;
    /* display: inline-block; */
    width: 100%;
    /* padding: 0.5rem 0 0.5rem 0.5rem; */
    padding-left: 2rem;
}

.cityList.select--all input[type="checkbox"]+label {
    color: #878787;
}

.cityList.react-tabs__tab--selected input[type="checkbox"]+label {
    background: #004171;
}

.hubCityList .cityList input[type="checkbox"]+label::before,
.cityList.select--all input[type="checkbox"]+label::before {
    content: "";
    position: absolute;
    left: 0.45rem;
    height: 1.25rem;
    width: 1.25rem;
    border-radius: 0.25rem;
    border: 0.063rem solid #004577;
    top: 50%;
    transform: translateY(-50%);
}

.cityList.select--all input[type="checkbox"]+label::before {
    border: 0.063rem solid #878787;
}

/* .hubCityList .cityList input[type="radio"]:checked+label::before {
    background: #47AAF0;
} */
.hubCityList .cityList input[type="checkbox"]:checked+label::after,
.cityList.select--all input[type="checkbox"]:checked+label::after {
    content: "";
    width: 0.35rem;
    transform: rotate(45deg);
    border: solid #004171;
    border-width: 0 0.125rem 0.125rem 0;
    left: 0.95rem;
    top: 0.125rem;
    position: absolute;
    height: 0.75rem;
}

.cityList.select--all input[type="checkbox"]:checked+label {
    color: #004577;
}

.cityList.select--all input[type="checkbox"]:checked+label::before {
    /* background: #004577; */
    background: #fff;
    border-color: #004577;
}

.hubCityList ul li.cityList:not(:last-child) {
    border-bottom: 1px solid #ddd;
}

.hubCityList ul li.cityList {
    padding: 0.5rem;
}

.gap--5 {
    gap: 1.25rem;
}

.selectAll {
    top: 1rem !important;
}

.hubCityList ul {
    border: 1px solid #ddd;
    border-radius: 0.375rem;
    overflow-y: auto;
    height: 22rem;
}

.product-type.tabView .react-tabs__tab-list {
    margin: 0.5rem 0.25rem 0.5rem 0.25rem;
}

.product-type.tabView .react-tabs__tab:focus {
    box-shadow: none;
}

.hub--row {
    display: flex;
    gap: 0.5rem;
}

.hub--tab--left {
    max-width: 70%;
    width: 100%;
}

.hub--tab--right {
    max-width: 30%;
    width: 100%;
}

.hub--tab--left .react-tabs {
    display: flex;
    gap: 0.5rem;
    border: 1px solid #ddd;
    padding: 0.5rem;
    padding-bottom: 0;
    border-radius: 0.5rem;
}

.city--bg--img {
    padding: 0;
    background-size: cover;
    background-position: 10% 40%;
    background-repeat: no-repeat !important;
    height: 6rem;
    border-radius: 0.5rem;
    width: 100%;
    padding: 0.7rem;
    border: 0.063rem solid #ddd;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
    margin-bottom: 0.5rem;
}

.hub--tab--left .react-tabs__tab-panel {
    display: none;
}

.hub--tab--left .react-tabs__tab-panel.react-tabs__tab-panel--selected {
    max-width: 70%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    display: block;
    overflow-y: auto;
    height: 21rem;
}

.city--list--row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
}

.hub--checkbox--area input[type="checkbox"] {
    display: none;
}

.hub--checkbox--area input[type="checkbox"]+label {
    position: relative;
    cursor: pointer;
    width: 100%;
    padding-left: 2rem;
}

.hub--checkbox--area input[type="checkbox"]+label::before {
    content: "";
    position: absolute;
    left: 0rem;
    height: 1.25rem;
    width: 1.25rem;
    border-radius: 0.25rem;
    border: 0.063rem solid #004577;
    top: 0;
}

.hub--checkbox--area input[type="checkbox"]:checked+label::after {
    content: "";
    width: 0.35rem;
    transform: rotate(45deg);
    border: solid #004171;
    border-width: 0 0.125rem 0.125rem 0;
    left: 0.95rem;
    top: 0.125rem;
    position: absolute;
    height: 0.75rem;
}

/* .hub--checkbox--area input[type="checkbox"]:checked+label::before{
    background: #47AAF0;
} */
.hub--checkbox--area input[type="checkbox"]:checked+label::after {
    content: "";
    width: 0.35rem;
    transform: rotate(45deg);
    border: solid #004171;
    border-width: 0 0.125rem 0.125rem 0;
    left: 0.875rem;
    top: 0.175rem;
    position: absolute;
    height: 0.75rem;
}

.hubCityList li {
    border-bottom: 1px solid #ddd;
}

.hubCityList li.cityList {
    padding: 0.5rem;
}

/* .hubCityList li{
    padding: 0.5rem 0 0.5rem 0.5rem;
} */
.hub--checkbox--area {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.cityList.react-tabs__tab--selected input[type="checkbox"]+label {
    color: #fff;
}

.cityList.react-tabs__tab--selected {
    background: #004171;
    position: relative;
    border-radius: 0;
}

.cityList.react-tabs__tab--selected input[type="checkbox"]+label::before {
    border-color: #fff;
    background: #fff;
}

.cityList.react-tabs__tab--selected::after {
    content: "";
    border-right: 0.45rem solid #fff;
    position: absolute;
    right: 0rem;
    height: -1.5rem;
    height: 1.5rem;
    border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
}

.cityList.select--all {
    border-bottom: 1px solid #ddd;
    padding: 0.5rem;
}

.about_profile.rightSide {
    position: absolute;
    right: 0.5rem;
    top: 1rem;
    z-index: 99;
}

.postView.profileOption {
    top: 2rem;
}

.about_profile.rightSide i.fa.fa-ellipsis-v {
    color: #fff;
}

.postView.profileOption ul {
    top: 1rem;
}

.ReactModal__Content.ReactModal__Content--after-open.reportModal {
    max-width: 22rem !important;
}

.ReactModal__Content.ReactModal__Content--after-open.incoTermModal {
    overflow-y: hidden !important;
}

:focus-visible {
    outline: none !important;
}

.reportModal .modalHeader {
    border-bottom: 1px solid #ddd;
    padding-bottom: 0.5rem;
}

.reportModal .report-post {
    padding-bottom: 0;
    padding-top: 0;
}

.reportModal .headerTitle {
    font-size: 1rem !important;
}

.report-post .problems li {
    font-weight: 700;
}

.font-11 {
    font-size: 0.7rem;
}

.bgPink {
    /* background: #ff00004f; */
    background: #cce5ff;
    padding: 0.25rem 0.5rem;
    color: #000;
    border-radius: 0.25rem;
}

.bgPink span {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}

.report-post input[type="checkbox"] {
    cursor: pointer;
}

.hubImg.myHub {
    border: 0.35rem solid #00ff54;
    padding: 0.135rem;
}

.successMsg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.product-type ul,
.product-type .react-tabs__tab {
    border: none;
}

.product-type ul {
    overflow-x: auto;
    padding-bottom: 0.25rem;
}

.product-type ul li.react-tabs__tab {
    background: #f2f2f2;
    border-radius: 2rem;
    padding: 0.35rem 1.25rem;
    white-space: nowrap;
}

.product-type ul li.react-tabs__tab.react-tabs__tab--selected {
    background: #47AAF0;
    color: #fff !important;
}

.product-type ul li.react-tabs__tab:not(:last-child) {
    margin-right: 0.5rem;
}

.product-type.tabView ul {
    display: flex;
    align-items: center;
}

.topTitle {
    display: flex;
    align-items: center;
}

.cityList.react-tabs__tab--selected .checkBox {
    color: #fff;
}

.topTitle .alert.alert-primary {
    margin-left: 3rem;
    padding: 0.5rem;
    max-width: 75%;
    width: 100%;
    margin-top: 0.5rem;
}

.cover__pic.withOverLay::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #0000002b;
}

#fda__certificate span {
    width: 100%;
    margin-left: 0;
    justify-content: flex-start;
}

.successMsg.profile {
    width: 100%;
    top: 24.65rem;
    text-align: center;
}

.middle__section-block.home_page {
    position: relative;
}

.postView.shareBlock {
    position: absolute;
    top: 0rem;
    right: 0rem;
    padding: 1rem;
}

.postView.shareBlock ul {
    min-width: 12rem;
    border-radius: 0rem;
    border: none;
}

.postView.shareBlock ul li {
    cursor: pointer;
    padding: 0.25rem 0.5rem;
    display: flex;
    align-items: center;
}

.postView.shareBlock ul li button svg {
    width: 2rem;
    height: 2rem;
}

.postView.shareBlock ul li:hover {
    background: #004577;
    color: #fff;
}

.postView.shareBlock ul li span {
    padding-left: 0.75rem;
    font-weight: 600;
    color: #000;
}

.postView.shareBlock ul li:hover span {
    color: #fff;
}

.postView.shareBlock ul li:not(:last-child) {
    padding-bottom: 0.5rem;
}

.postView.shareBlock ul li:hover .fa {
    color: #000 !important;
}

.postView.shareBlock ul li:hover .fa {
    color: #fff !important;
}

/* .reportModal form {
    margin-bottom: 1.25rem;
} */
/* FDA css start */

.fdaBlock {
    position: fixed;
    top: 6.5rem;
    background: #fff;
    width: 42rem;
    z-index: 9999;
    height: calc(100vh - 6.5rem);
    left: 100%;
    border-radius: 2rem;
    border: 0.25rem solid #33A386;
    overflow-y: auto;
    /* display: none; */
}

.fdaBlock::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.text-teal {
    color: #33A386;
}

.menu.closeFda {
    margin-left: auto;
}

.menu.closeFda .fa {
    font-size: 1rem;
    padding-right: 0;
}

.fdaLogo {
    /* display: flex; */
    align-items: center;
    margin-bottom: 1.5rem;
    width: 20rem;
}

.logoImg {
    width: 19rem;
    margin: auto;
    margin-bottom: 1.25rem;
}

.logoImg img {
    width: 100%;
}

.fdaLogo h2 {
    font-size: 4rem;
    font-weight: bold;
}

.fdaLogo h4 {
    font-size: 2rem;
    font-weight: 700;
    padding-left: 0.5rem;
    color: #233062;
}

.fdaLogo h4 span {
    color: #bf2242;
}

.detailAlign {
    padding: 0rem 1rem 0.5rem 1rem;
    display: flex;
    align-items: center;
    gap: 1rem;
}

.detailAlign h4.details {
    margin-top: 0.5rem;
}

.rowWrap {
    display: flex;
    align-items: center;
    gap: 2rem;
}

.contactDetail {
    /* margin-top: 2rem; */
}

h4.details {
    margin-top: 1.75rem;
    font-size: 1.35rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-weight: 800;
}

.flexBox {
    display: flex;
}

.editBlockIcon .fa,
.deleteBlockIcon .fa,
.blockListIcon .fa {
    border-radius: 0.25rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    cursor: pointer;
    padding: 0.5rem;
}

.__react_component_tooltip {
    visibility: visible !important;
}

.editBlockIcon .fa {
    background: #004577;
}

.deleteBlockIcon .fa {
    background: #FF3131;
}

.blockListIcon .fa {
    background: #EBB62E;
}

.fdaBlock .menu {
    max-width: 51%;
    width: 100%;
    padding: 1rem;
    margin-top: 1rem;
}

.fdaArea {
    position: relative;
}

.fdaBlock .menu p {
    font-size: 1.5rem;
    font-weight: 600;
    margin-top: 1rem;
    line-height: 2rem;
}

.bgImage img {
    width: 100%;
}

.bgImage {
    width: 100%;
    max-width: 18rem;
    top: 0rem;
    position: absolute;
    right: 0rem;
}

.fdaLogo a {
    cursor: pointer;
}

/* .bgImage{
    background-image: url('../assets/images/fda-bg.svg');
    background-size: cover;
    height: 10rem;
    background-repeat: no-repeat;
} */
.fdaBlock.active {
    left: 46.875%;
    /* display: block; */
}

.fdaArea .closeBtnBlock {
    display: none;
}

.fdaArea.active .closeBtnBlock {
    display: block;
}

.fdaBlock {
    transition: 600ms;
}

.closeFda {
    cursor: pointer;
    text-align: right;
}

.discover_page.createTrip .form__item.selectRegion .search-wrapper .chip {
    display: none;
}

.menu.closeFda {
    margin-left: auto;
    position: absolute;
    left: unset;
    top: 4rem;
    right: 2rem;
    width: 2rem;
    height: 2rem;
    background: #33A386;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    z-index: 9999;
}

@media screen and (min-width: 640px) {
    .register__options {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
}

.product-type li {
    list-style: none;
}

.home__page .ingredients__List li.recipe__detail .ingredient_img img {
    padding: 0.05rem;
}

.home__page .ingredients__List li.recipe__detail .ingredient_img {
    border: 0.313rem solid rgb(142, 201, 187) !important;
    cursor: pointer;
}

.postView.shareBlock ul li button {
    display: flex;
    align-items: center;
    width: 100%;
}

.iconWidth {
    width: 2rem;
    height: 2rem;
    background: #33a386;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.iconWidth .fa {
    color: #fff;
}

.modalClose .overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    background-color: rgb(0 0 0 / 60%);
    background-color: rgb(0 0 0 / 60%);
    overflow-x: hidden;
    transition: 0.5s;
}

.overlayContent {
    position: relative;
    width: 100%;
    height: 100%;
}

.bgImage.newDesign {
    top: 3rem;
    right: 2rem;
}

.ingredients__List li.recipe__detail h6.ingredient_name {
    font-weight: 500;
}

html body {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

/* body.home.new__class.modalClose #root::before {
    content: "";
    background: #0006;
    position: absolute;
    width: 100%;
    height: calc(100vh - 2rem);
    z-index: 9;
} */
/* .modalClose .left__section-block, .modalClose .middle__section-block{
    opacity: 0.5;
    filter: blur(2px);
    position: relative;
}
.modalClose .left__section-block::before{
    content: "";
    position: absolute;
    background: #00000073;
    width: 100%;
    height: 100%;
    z-index: 9;
}
.modalClose .middle__section-block::before{
    content: "";
    position: absolute;
    background: #00000073;
    width: 100%;
    height: 100%;
    z-index: 9;
} */
/* .modalClose .tab-content-right-section::before{
    content: "";
    position: absolute;
    background: #00000073;
    width: 100%;
    height: 100%;
    z-index: 9;
} */
/* .modalClose .addScroll .left__section-block .connection_scroll{
    max-width: 100%;
} */

.followLoader .loader {
    margin: 0px auto;
    font-size: 2px;
}

/* New Dashboard Css Start */
.tabLeftBlock {
    background: #F7F9FB;
}

.dashboardView {
    background: #fff;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
}

.card--items .card--item--block .font-20 {
    font-size: 0.875rem;
    font-weight: 400;
}

.item--count p {
    align-items: center;
    font-size: 1.125rem;
    justify-content: space-between;
}

.card--items .item--count p {
    align-items: center;
}

.tabLeftBlock .btn {
    padding: 1.25rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-weight: 700;
}

.dashboardView .tab-content-left-section {
    width: 100%;
}

.tabLeftBlock .tab_icon svg {
    margin-right: 0.625rem;
}

.tabLeftBlock .btn.active-button svg path {
    fill: #fff;
}

.tabLeftBlock .btn.active-button {
    background: #4BB3FD;
    color: #fff;
}

.tabLeftBlock .btn em {
    font-style: inherit;
    font-weight: 700;
    font-size: 0.65rem;
}

.grid-4.card--items {
    gap: 1.125rem;
}

.dashboardView .left__section-block {
    margin: 0;
}

.dashboardView .middleBlockBg {
    border: none;
}

.dashboardView .tab.home-section.active-tab.no-padding {
    display: block;
}

.mb--28 {
    margin-bottom: 1.75rem;
}

.mt--28 {
    margin-top: 1.75rem;
}

.mt--41 {
    margin-top: 2rem;
}

.mb--41 {
    margin-bottom: 2rem;
}

.grid-4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

.productCard {
    background-color: #D0FFD3;
}

.card--items .card--item--block {
    border-radius: 0.625rem;
    padding: 1.125rem;
}

.item--count p {
    font-weight: 700;
}

.categoryCard {
    background-color: #E3E4FF;
}

.ratingCard {
    background-color: #CCEDFF;
}

.inquiriesCard {
    background-color: #FFF2D9;
}

.dashboardView .middleBlockBg {
    /* max-width: 75%; */
    margin-left: 0;
    max-width: 59.35rem;
    width: 100%;
    padding: 0.5rem 1.5rem;
}

/* .left__section-block {
    max-width: 12.5rem;
    width: 100%;
} */
.dashboardView .left__section-block {
    max-width: 12.5rem;
    width: 100%;
}

.dashboardView .site-align.top-align {
    column-gap: 0rem;
}

.secBlockTitle {
    font-size: 0.875rem;
}

.secBlockGap,
.itemTable {
    margin-top: 1.25rem;
}

.secBlockTitle {
    font-size: 0.87rem;
    font-weight: 700;
}

.itemTable table tr td,
.itemTable table tr th {
    font-size: 0.675rem;
    line-height: normal;
}

.itemTable table tr td {
    font-weight: 500;
}

.itemTable table tr th {
    font-weight: 700;
}

.itemTable table {
    width: 100%;
}

.itemTable table {
    width: 100%;
}

.itemTable table tr td,
.itemTable table tr th {
    padding: 0.75rem;
    border: 1px solid #ddd;
    text-align: center;
    font-size: 0.67rem;
}

.actionBg img {
    margin: auto;
    cursor: pointer;
}

.marketplace.dashboard.header {
    padding: 1rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.grid-2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.dashboardView .product-title h4 {
    font-size: 1rem;
    color: #000;
    font-weight: 700;
}

.dashboardView .product-title {
    margin: 0;
    padding: 0;
    margin-bottom: 0.75rem;
    margin-top: 0.25rem;
}

.dashboardView .product-title .inner-btn-box {
    min-width: auto;
    padding: 0;
    gap: 0.75rem;
}

.dashboardView .product-title .btn-blue {
    min-width: 5rem;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0.55rem;
    border-radius: 0;
    font-size: 0.75rem;
    font-weight: 700;
    /* gap: 0.5rem; */
    justify-content: center;
}

.btn.btn--gray button {
    background: #C9C9C9;
    color: #000;
    min-width: 5rem;
    display: inline-block;
    align-items: center;
    width: 100%;
    padding: 0.39rem;
    border-radius: 0;
    font-size: 0.705rem;
    font-weight: 500;
    justify-content: center;
}

.dashboardView .btn.btn--gray {
    background: #C9C9C9;
    color: #000;
    min-width: 5rem;
    display: flex;
    align-items: center;
    width: 100%;
    /* padding: 0.39rem; */
    border-radius: 0;
    font-size: 0.75rem;
    font-weight: 500;
    justify-content: center;
    /* gap: 0.5rem; */
}

.calenderWrapper .calenderBlock input {
    width: 100%;
}

.dashboardView .product-title .btn-blue.light-green {
    background: #88A6A8;
    border: 0.0625rem solid #88A6A8;
}

.dashboardView .product-title .btn-blue .fa,
.dashboardView .product-title .btn-red .fa {
    margin-right: 0.25rem;
}

.dashboardView .product-title .btn-red {
    /* min-width: 5rem; */
    background: #FF3131;
    border-radius: 0;
    color: #FFF !important;
    display: flex;
    font-size: 0.75rem;
    align-items: center;
}

.calenderWrapper {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.productFilter.justify--between {
    justify-content: space-between;
}

.productFilter {
    display: flex;
    align-items: center;
    gap: 1.125rem;
    justify-content: space-between;
}

.calenderWrapper .calenderBlock {
    width: 11.5rem;
    display: flex;
    align-items: center;
}

.form--group {
    /* border: 1px solid #C9C9C9; */
    padding: 0.5rem;
}

.form--group input {
    padding: 0 0.25rem;
    max-width: 90%;
    width: 100%;
}

.mb--30 {
    margin-bottom: 1.575rem;
}

.mt--30 {
    margin-top: 1.575rem;
}

.product_mgmt_filter .productFilter .searchBar {
    max-width: 18.125rem;
}

.categoryList {
    max-width: 17rem;
}

.stockList {
    max-width: 13.25rem;
}

.productFilter .searchBar,
.categoryList,
.stockList {
    width: 100%;
}

.categoryList select,
.stockList select {
    width: 100%;
}

.productFilter .btn--gray button .fa {
    margin-right: 0.5rem;
}

.customer__table table .customer__name {
    text-align: left;
}

.customer__table table tr td:nth-child(1) {
    white-space: nowrap;
}

/* New Css  */
.dashboardView h4.font-18.text-black {
    margin-top: 1rem;
    font-size: 0.875rem;
    line-height: normal;
}

.selectOption label {
    padding-left: 0.5rem;
    font-weight: 700;
}

.paymentOptionSection {
    display: flex;
    align-items: center;
    gap: 1rem;
    justify-content: space-between;
}

.paymentOptionSection .customRadio label {
    padding-left: 0.5rem;
}

.paymentOptionSection .customRadio input[type="radio"] {
    display: none;
}

.customRadio label {
    position: relative;
    padding-left: 0.25rem;
    cursor: pointer;
}

.customRadio input[type="radio"] {
    cursor: pointer;
}

.checkboxData {
    margin-left: 10.5rem;
    padding: 1.5rem;
}

.Store_Tab {
    position: relative;
}

.checkboxData .w-50 {
    max-width: 50%;
    width: 100%;
}

.customRadio label::before {
    border: 0.125rem solid #004577;
    width: 1.25rem;
    height: 1.25rem;
    content: "";
    position: absolute;
    left: -1rem;
    border-radius: 50%;
    background: #fff;
}

.customRadio input[type="radio"]:checked+label::after {
    width: 0.75rem;
    height: 0.75rem;
    content: "";
    position: absolute;
    left: -0.75rem;
    border-radius: 50%;
    background: #004577;
    top: 50%;
    transform: translateY(-50%);
}

.dashboardView .tab-content-left-section {
    margin-top: 1.75rem;
}

.dashboardView .home-section p {
    line-height: normal;
    font-weight: 400;
    font-size: 0.875rem;
    color: #000;
}

.dashboardView .card--items .card--item--block .font-20 {
    font-size: 1.205rem;
    line-height: normal;
    margin-bottom: 0.607rem;
}

.dashboardView .home-section p.flexBox.font-28 {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: normal;
    color: #000;
}

.dashboardView .grid-4.card--items {
    gap: 1.059rem;
}

.dashboardView p.flexBox.font-28 img {
    height: 4.510rem;
    width: auto;
}

.dashboardView h4.secBlockTitle {
    line-height: normal;
    color: #000;
    font-size: 1.128rem;
}

.dashboardView .itemTable th {
    font-size: 0.75rem !important;
    line-height: normal !important;
    color: #000;
    white-space: nowrap;
}

.dashboardView .itemTable table tr td {
    font-weight: 400;
    line-height: normal;
    font-size: 0.75rem;
    color: #000;
    word-break: break-word;
}

.dashboardView .product-title h4 {
    margin-top: 0rem;
    line-height: normal;
    font-size: 0.875rem;
    color: #000;
}

.dashboardView .product-title {
    margin-top: 1rem;
    margin-bottom: 0;
}

.dashboardView .product-title .inner-btn-box {
    gap: 0.825rem;
    height: 2.002rem;
    /* margin-top: 1.1002rem; */
}

.buyerDashboard .filterData .whiteBg label {
    font-weight: 700;
}

.dashboardView .secBlockGap {
    margin-top: 0;
}

.phoneNumber .countryCode {
    left: 0.5rem;
    top: 0.5rem;
}

.dashboardView h4.secBlockTitle {
    margin-bottom: 1.265rem;
    margin-top: 2.3025rem;
    font-size: 0.85rem;
}

.dashboardView .inner-btn-box a.btn,
.dashboardView .inner-btn-box .btn {
    font-weight: 700;
    line-height: normal;
    font-size: 0.927rem !important;
    text-transform: capitalize;
}

.dashboardView .productFilter.disputesFilter .btn.btn--gray {
    padding: 0.5rem;
}

.transaction .inner-btn-box a.btn.btn-blue {
    background: #004577;
    border: 0.0625rem solid #004577;
}

.dashboardView .user__pic {
    margin-right: 0 !important;
}

.dashboardView .store-profile h4.font-16.ml-5 {
    line-height: normal;
    font-size: 1rem;
    color: #000;
}

.dashboardView .store-profile.mb-3 {
    margin-bottom: 0.4375rem;
}

.dashboardView .form__item label {
    padding-top: 0rem;
    margin-bottom: 0.6rem;
    color: #000;
    font-weight: 700;
    line-height: normal;
    font-size: 0.705rem;
    text-transform: capitalize;
}

.dashboardView .form__item input,
.dashboardView .form__item select {
    border-radius: 0 !important;
    border: 0.0625rem solid #C9C9C9 !important;
    font-weight: 500;
    line-height: normal;
    font-size: 0.705rem;
    color: #000;
    background: transparent !important;
    margin-top: 0;
    height: 3em;
}

.dashboardView .form__item input::placeholder,
.dashboardView .form__item select::placeholder {
    color: #000;
    font-weight: 500;
    line-height: normal;
    font-size: 0.705rem;
}

.dashboardView .form__item textarea {
    border-radius: 0 !important;
    border: 0.0625rem solid #C9C9C9 !important;
    font-weight: 400;
    line-height: normal;
    font-size: 0.705rem;
    color: #000 !important;
}

.dashboardView .form__item textarea::placeholder {
    color: #000 !important;
    font-weight: 500;
    line-height: normal;
    font-size: 0.705rem;
}

.form-item .cmnt-write::placeholder {
    color: #000;
}

.form-item .cmnt-write::placeholder {
    color: #000;
}

/*  new Css  */
.dashboardView .form__item .usd i.fa.fa-usd {
    color: #000;
}

.dashboardView .form__item i.fa.fa-exclamation-circle {
    color: #000;
}

.dashboardView .marketplace.add-product {
    border: 0.0625rem solid #DDD !important;
    border-radius: 0;
}

.dashboardView .form__item {
    margin-bottom: 1.17rem;
}

.dashboardView .col-padding .col-100:last-child .form__item {
    margin-bottom: 0;
}

.dashboardView .col-padding .w-100:last-child .form__item {
    margin-bottom: 0;
}

.dashboardView .page-main .mt-3 {
    margin-top: 0;
}

/*product page*/
.dashboardView .productFilter .form--group {
    font-size: 0.706rem;
    font-weight: 500;
    height: 2rem;
    line-height: normal;
}

.product_mgmt_filter input.search {
    min-width: 87%;
}

/* .product_mgmt_filter  */
.searchBar,
.categoryList,
.stockList,
.calenderBlock {
    border: 0.0625rem solid #c9c9c9;
}

.product_mgmt_filter .searchBar {
    position: relative;
}

.product_mgmt_filter i.fa.fa-search {
    position: absolute;
    bottom: 0.525rem;
    left: 15.5rem;
}

.filterBtn svg {
    height: 1rem;
    width: 1rem;
}

.product_mgmt_filter .form--group {
    border: 0;
}

.dashboardView .searchBar .search::placeholder {
    color: #868686;
}

.dashboardView span.searchIcon {
    color: #868686;
}

.dashboardView .categoryList select {
    color: #868686;
}

.dashboardView .stockList select {
    color: #000;
}

.dashboardView .productFilter.mb--30 {
    margin-top: 1.5rem;
}

.dashboardView .btn--gray {
    background: #EAEAEA !important;
}

.dashboardView .btn.btn--gray button {
    background: #EAEAEA;
    /* min-width: 9.108rem; */
}

.dashboardView .productFilter .btn.btn--gray {
    /* border: 1px solid #C9C9C9; */
    width: auto;
    /* min-width: auto; */
    /* height: 2rem; */
}

.dashboardView .productFilter .form--group::placeholder {
    background: #fff;
}

.product_mgmt_filter .categoryList,
.product_mgmt_filter .stockList {
    width: 100%;
    max-width: 16rem;
}

.hub--checkbox--area input[type="checkbox"]+label::before {
    border-color: #CECECE;
}

.resetBtn button {
    color: #004577;
    padding-bottom: 1rem;
}

.dashboardView .product-list td:last-child {
    width: 0;
}

.dashboardView .product-list td:last-child span {
    border-radius: 0.375rem;
    height: 1.7rem;
    width: 1.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dashboardView span.editBlock {
    background: #004577;
    color: #FFF;
}

.dashboardView span.deleteBlock {
    background: #FF3131;
    color: #FFF;
}

.dashboardView span.blockList {
    background: #EBB62E;
    color: #FFF;
}

.dashboardView .quantity.bgGreen {
    background: rgba(65, 195, 141, 0.59);
}

.dashboardView .quantity.bgPink {
    background: rgba(255, 49, 49, 0.30);
}

.dashboardView .product-list .itemTable td img {
    height: auto;
    width: 2rem;
}

.btn.btnPrimary.p-0 {
    padding: 0;
}

.dashboardView .product-list td:last-child .flexBox {
    align-items: center;
    gap: 0.5rem;
}

.dashboardView .flexBox span a {
    color: #fff;
}

.dashboardView .flexBox span a:hover {
    color: #fff;
}

.dashboardView .quantity {
    text-align: center;
}

.Host.Description .desTitle span {
    font-family: system-ui !important;
    font-size: 0.75rem !important;
    padding-left: 0;
}

.itemCenter {
    text-align: center;
}

.dashboardView .quantity.bgGreen {
    border: none;
    border-radius: 0.4054rem;
    margin-top: 0;
}

.dashboardView .form-checkbox-contents {
    margin-left: 0;
}

.dashboardView .hub--checkbox--area {
    display: block;
}

.dashboardView .hub--checkbox--area input[type="checkbox"]+label::before {
    left: 0.3875rem;
    top: 0rem;
}

.dashboardView .itemTable .flexBox span {
    cursor: pointer;
}

.dashboardView button:focus,
input:focus,
optgroup:focus,
select:focus,
textarea:focus {
    outline: none;
}

/* .dashboardView .itemTable table tr td {
    font-size: 0.675rem;
} */
/* label textarea  */

.imgBottomLine {
    padding: 0 !important;
    margin: 1.05rem 0;
}


.dashboardView .itemTableScroll {
    overflow-x: auto;
}

.dashboardView .transactionMgmt .payStatus {
    font-size: 0.605rem;
    color: #fff;
    padding: 0.3075rem;
    min-width: 5.985rem;
    border-radius: 0.4054rem;
    text-align: center;
}

.dashboardView .transactionMgmt .succeeded {
    font-size: 0.605rem;
    color: #fff;
    background-color: #5CAF3E !important;
    padding: 0.3075rem;
    min-width: 5.985rem;
    border-radius: 0.4054rem;
    text-align: center;
}

.dashboardView .transactionMgmt .notReq {
    background: #E89F32;
    line-height: 1.5;
    width: 9rem;
}

.dashboardView .transactionMgmt .paid {
    font-size: 0.605rem;
    color: #fff;
    background-color: #5CAF3E !important;
    padding: 0.3075rem;
    min-width: 5.985rem;
    border-radius: 0.4054rem;
    text-align: center;
}

.dashboardView .transactionMgmt .hold {
    background: #E85E32;
}

.dashboardView .orderStatus {
    background: #D0D0D0;
    border-radius: 0.4054rem;
    padding: 0.3075rem;
    line-height: 1.5;
    text-align: center;
}

.transactionStatus {
    width: 9rem;
}

.inner-btn-box a.btn.btn-blue {
    border-radius: 0;
    padding: 0.5rem;
}

.dashboardView .btn--gray {
    border: 0.0625rem solid #EAEAEA;
}

.generate_transaction .product-list {
    border: 0.0625rem solid #C6C6C6;
    padding: 0 1.202rem 1.202rem 1.202rem;
    margin-top: 0.875rem;
}

.generate_transaction .productFilter h4 {
    margin-top: 1.25rem;
}

.generate_transaction .productFilter.innderHeader {
    margin: 0;
}

.generate_transaction .itemTable table tr td {
    font-weight: 400;
}

.dashboardView .itemTable table tr td,
.itemTable table tr th {
    border-color: #C6C6C6;
    text-align: left;
}


/* customer_mgmt detail page  */
.dashboardView .custom__tab .react-tabs__tab-list {
    display: flex;
    gap: 0.625rem;
    border-bottom: none;
}

/* .custTotal {
    min-width: 7rem;
} */
.lastOrder {
    min-width: 7rem;
}

.iconGroup {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    justify-content: flex-start;
}

.iconGroup span img {
    cursor: pointer;
}

.newOrder select {
    background: #D2D2D2;
    padding: 0.455rem;
}

/* 
 .dashboardView .custom__tab .react-tabs ul li:hover {
    border-color: #004577;
    background: #004577;
    color: #fff;
} */
.CustomId {
    /* min-width: 7rem; */
}

.CustomName {
    /* min-width: 9rem; */
    text-align: left;
}

.dashboardView .custom__tab .react-tabs ul li {
    /* border: 0.0625rem solid #E1E1E1; */
    border-radius: 0.0625rem;
    /* background: #E1E1E1; */
    color: #000;
    font-size: 0.705rem;
    line-height: normal;
    font-weight: 700;
    padding: 0.52rem;
}

.orderComplete {
    min-width: 7rem;
}

.country {
    min-width: 7rem;
}

/* .customer_mgmt .servics_blocks.middle-long__section-block.middleBlockBg {
    padding: 0;
} */

.customer_mgmt .searchBar {
    max-width: 11rem;
}

.customer_mgmt .inner-btn-box a.btn.btn-red i.fa.fa-ban {
    margin-right: 0;
}

.leftFilter select.form--group {
    min-width: 11rem;
}

.customer_mgmt .inner-btn-box a.btn.btn-red {
    min-width: 7.5rem;
    justify-content: space-between;
}

.dashboardView .itemTable table tr td img {
    width: 2rem;
    height: auto;
}

.dashboardView span.blockList.Teal {
    background: #37A282;
}

/*generate page*/
.generate_transaction .product-list td:last-child span {
    height: auto;
    width: auto;
    display: block;
}

/*customermgmt page*/
.dashboardView .custom__tab ul.react-tabs__tab-list {
    margin-left: 0 !important;
    margin-bottom: 1rem;
}

.dashboardView .custom__tab .react-tabs__tab--selected {
    border: 0.0625rem solid #004577;
    border-radius: 0.0625rem;
    background: #004577 !important;
    color: #fff !important;
    box-shadow: none;
}

.dashboardView .custom__tab .react-tabs ul li.react-tabs__tab:first-child {
    min-width: 4.756rem;
    text-align: center;
}

.dashboardView .custom__tab .react-tabs ul li.react-tabs__tab:not(:first-child) {
    min-width: 6.922rem;
    text-align: center;
}

.dashboardView .custom__tab .react-tabs ul li:focus {
    border: none;
    box-shadow: none;
}

.dashboardView .custom__tab .react-tabs__tab--selected:focus {
    color: #fff;
}

.leftFilter {
    display: flex;
    gap: 1rem;
}

.customer_mgmt .showData {
    border: 0.0625rem solid #C6C6C6;
    padding: 0 1.202rem 1.202rem 1.202rem;
    margin-top: 0.875rem;
}

.customer_mgmt .formData {
    margin-top: 1.275rem;
    flex-direction: column;
}

.customer_mgmt .gridRow3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
}

.customer_mgmt .formData p {
    padding-bottom: 0.5rem;
}

.customer_mgmt .formData p {
    padding-bottom: 0.5rem;
    /* font-size: 0.606rem; */
    line-height: normal;
    font-weight: 700;
    color: #000;
}

.customer_mgmt .dataControl {
    border: 0.0625rem solid #C9C9C9;
    line-height: normal;
    font-weight: 400;
    color: #000;
    /* font-size: 0.606rem; */
    padding: 0.546rem;
}

.customer_mgmt .gridRow2 .formData:nth-child(odd) {
    margin-right: 1rem;
}

/* .customer_mgmt .gridRow2 .formData .dataControl.address {
    height: 6.502rem;
} */

.customer_mgmt .gridRow2 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

/* offer mgmt */
.actionBg .flexBox {
    justify-content: space-between;
    gap: 0.575rem;
    margin: auto;
}

/* notification page */
.notificationDate {
    min-width: 9rem;
}

.dashboardView .notification_list th:nth-child(2) {
    text-align: left;
}

.dashboardView .notification_list td:nth-child(2) {
    text-align: left;
}

.customer_mgmt .inner-btn-box a.btn.btn-red i.fa.fa-ban {
    margin-right: 0.5rem;
}

.customer_mgmt .inner-btn-box a.btn.btn-red {
    min-width: fit-content;
}

.customer_mgmt {
    border: 0;
}

.order_mgmt {
    border: 0;
}

.dateField {
    /* min-width: 9rem; */
}

.order_mgmt .servics_blocks.middle-long__section-block.middleBlockBg {
    padding: 0;
}

.custPoduct {
    text-align: left;
    min-width: 7rem;
}

.custCategory {
    /* min-width: 9rem; */
    text-align: left;
}

.dashboardView ul.pagination {
    gap: 0.625rem;
}

/* new csss */
.dashboardView .middleBlockBg {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
}

.dashboardView .tab.home-section .product-list ul {
    margin: 0;
}

.dashboardView .productListing {
    margin-bottom: 2rem;
}

.dashboardView .pagination li a {
    font-size: 0.805rem;
    line-height: normal;
    color: #000;
}

.dashboardView .pagination li.active a {
    background-color: #E1E1E1;
    border-color: #E1E1E1;
}

.dashboardView .custom__tab__data {
    margin-top: 1rem;
}

.dashboardView .pagination li.previous a {
    background: #E1E1E1;
}

.dashboardView .pagination li.next a {
    background: #E1E1E1;
}

/* taxpop up */
.taxClassForm .popup_header {
    align-items: center;
    box-shadow: none;
    margin-bottom: 0;
    border-bottom: 0.0625rem solid #C6C6C6;
    border-radius: 0;
    padding: 0.625rem 1rem;
}

.taxClassForm {
    padding: 0.625rem 1rem;
}

.taxClassForm .tax_Input {
    display: grid;
}

.taxClassForm .tax_Input input {
    border: 0.0625rem solid #C6C6C6;
    margin: 0.5rem 0;
    line-height: normal;
    font-size: 0.805rem;
    padding: 0.3125rem;
    height: 2rem;
}

.taxClassForm .select_Tax select {
    border: 0.0625rem solid #C6C6C6;
    line-height: normal;
    height: 2rem;
    width: 100%;
    font-size: 0.925rem;
    font-weight: 500;
    padding: 0.3125rem;
    color: #868686;
    text-transform: capitalize;
}

.taxClassForm .tax_Input label {
    font-size: 0.925rem;
    color: #000;
    line-height: normal;
    font-weight: 500;
}

.taxClassForm .select_Tax {
    margin-top: 0.5rem;
}

.taxClassForm form button[type="submit"] {
    border-radius: 0;
    border-color: #004577;
    background: #004577;
    line-height: normal;
    color: #fff;
    font-size: 0.925rem;
    font-weight: 500;
    height: 2rem;
    padding: 0.3125rem;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
}

.taxClassForm .popup_header button.close_btn--modal::after {
    font-size: 1rem;
}

/* .tax__mgmt */
.tax__mgmt .taxClassForm {
    padding: 0;
}

.tax__mgmt h4.secBlockTitle {
    margin-top: 1.265rem;
    margin-bottom: 0;
}

.TabFlex {
    margin-top: 1rem;
}

.Tax_Tab ul.react-tabs__tab-list {
    gap: 0.875rem;
}

.Tax_Tab .react-tabs__tab {
    background: #e1e1e1;
    border: 1px solid #e1e1e1;
    color: #000;
    font-size: 0.705rem;
    line-height: normal;
    font-weight: 700;
    padding: 0.55rem;
    min-width: 10.105rem;
    height: 2rem;
    text-align: center;
    cursor: pointer;
}

.Tax_Tab li.react-tabs__tab--selected {
    background: #004577;
    color: #fff !important;
}

.TaxTabFlex {
    gap: 0.875rem;
    position: relative;
}

.Tax-btn-box {
    position: absolute;
    right: 0;
    height: 2.307rem;
}

.tax__mgmt .itemTable table tr td:not(:first-child, :last-child) {
    border-bottom: 0.0625rem solid #c6c6c6;
}

/* .tax__mgmt .itemTable table  .Tax_Name {
    text-align: left;
} */
/* modal select tax input */
.SelectTaxInput input {
    border: 0.0625rem solid #C6C6C6 !important;
    line-height: normal;
    font-size: 0.805rem;
    padding: 0.3125rem;
    height: 2rem;
    width: 100%;
    background: #fff !important;
}

a.btn.btn-red {
    background: #FF3131;
}

/* mystore */
.slider__gallery.startFirst {
    flex-wrap: nowrap;
    gap: 1.6875rem;
}

.dashboardView .formData .gallery_data h4.black {
    font-size: 0.75rem;
    line-height: normal;
    color: #000;
    margin: 0.625rem 0;
}

.dashboardView .formData .gallery_data p {
    font-size: 0.75rem;
    margin-bottom: 0.625rem;
}

/* new css 21-07-23 */
.add_Cart td.cart-product-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.add_Cart .cart-product-list span img {
    width: 2rem;
    height: auto;
}

.add_Cart .cart-product-list p.remove-btn {
    font-weight: 500;
    line-height: normal;
    font-size: 0.657rem;
    background: #FF3131;
    color: #fff;
    padding: 0.5rem;
    border-radius: 0.4054rem;
    cursor: pointer;
}

.add_Cart button.checkout-btn {
    background: #004577;
    color: #fff;
    font-weight: 700;
    line-height: normal;
    font-size: 0.657rem;
    padding: 0.52rem 0.625rem;
    border: 0.0625rem solid #004577;
    margin-top: 2rem;
    float: right;
    cursor: pointer;
}

/* add details page */
.order__Checkout p {
    background: #004577;
    color: #fff;
    font-weight: 500;
    line-height: normal;
    font-size: 0.657rem;
    max-width: max-content;
    padding: 0.5rem;
    cursor: pointer;
    margin: 0.5rem 0;
}

/* addbilling form popup  */
.billing-form h2 {
    margin-bottom: 0.8rem;
    font-size: 1rem;
    font-weight: 700;
    line-height: normal;
    color: #000;
}

.btnRight {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
}

.btnBgBlue {
    min-width: 8rem;
    background: #004577;
    padding: 0.5rem;
    color: #fff;
    border: 1px solid #004577;
    border-radius: 0.25rem;
}

.addressItem select {
    width: 100%;
    padding: 0.5rem;
}

.btnborderBlue {
    min-width: 8rem;
    padding: 0.5rem;
    color: #004577;
    border: 1px solid #004577;
    border-radius: 0.25rem;
}

.billing-form .shipClassForm {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.8rem;
}

.shipClassForm .form-group {
    border: 0.0625rem solid #C6C6C6;
    line-height: normal;
    font-size: 0.805rem;
    padding: 0.3125rem;
    height: 2rem;
}

.form-item input,
.form-item select,
.form-item textarea {
    width: 100%;
}

.shipClassForm .form-group [type="text"] {
    width: 100%;
}

.shipClassForm .form-group textarea {
    width: 100%;
}

.shipClassForm .form-group select {
    width: 100%;
    border-color: #C6C6C6;
    font-weight: 500;
    color: #868686;
}

/* addproduct page */
.add-product-page .searchBar {
    width: auto;
}

.add-product-page .searchBar.SearchImport .search-wrapper {
    min-height: inherit;
    border: 0 !important;
}

.add-product-page .searchBar.SearchImport .searchBox {
    line-height: normal;
    border: 0.0625rem solid #c9c9c9 !important;
    margin-top: 0;
    height: 2rem;
    width: 15rem;
    font-size: 0.706rem;
}

.dashboardView li.uploadedImg.formLoadImg {
    margin: 0 1%;
}

.add-product-page .form--group {
    border: 0.0625rem solid #c9c9c9;
}

.create-offer .searchBar {
    display: grid;
}

.create-offer .outer .productFilter .stockList {
    min-width: 18rem;
}

.searchImporter .multiselect-container span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 98%;
    margin: 0 0.25rem;
    font-size: 0.706rem;
}

.searchImporter .multiselect-container input {
    margin-top: 0;
    height: 2rem;
    background: transparent !important;
}

.searchImporter .multiselect-container .search-wrapper {
    min-height: 2rem;
}

.searchImporter .multiselect-container i.icon_cancel {
    bottom: -0.2rem;
}

.searchImporter .multiselect-container {
    height: 2rem;
}

.add-product-page input.search {
    width: 100%;
}

.add-product-page .searchBar span.searchIcon {
    position: relative;
}

.add-product-page .productFilter .searchBar,
.add-product-page .productFilter .stockList,
.add-product-page .productFilter .categoryList {
    border: 0;
}

.add-product-page .searchBar span.searchIcon i.fa.fa-search {
    position: absolute;
    top: -1.879rem;
    right: 15px;
}

.categoryList {
    max-width: 13.25rem;
    display: grid;
}

.create-offer .searchBar input.form--group {
    color: #676767;
}

.productFilter.outer {
    flex-wrap: wrap;
}

.create-offer .add-product-page {
    border: 0.0625rem solid #C6C6C6;
    padding: 1.25rem;
    margin-top: 1rem;
}

.create-offer .product-title h4 {
    font-size: 1.03rem;
}

.create-offer .stockList select.form--group {
    color: #676767;
}

.create-offer .searchBar,
.stockList {
    display: grid;
}

.create-offer .iconGroup {
    display: block;
    margin-top: 0.2rem;
}

.create-offer .iconGroup span svg {
    background: #ff3131;
    cursor: pointer;
    width: auto;
    height: 2rem;
    padding: 0.4rem;
}

.add-product-page input.search {
    width: 100%;
}

.add-product-page .searchBar span.searchIcon {
    position: relative;
}

.add-product-page .searchBar span.searchIcon i.fa.fa-search {
    position: absolute;
    top: -1.879rem;
    right: 15px;
}

.add-product-page .productFilter {
    justify-content: inherit;
}

.add-product-page .productFilter .inner-btn-box a.btn.btn-blue {
    background: #004577;
}

.categoryList {
    max-width: 13.25rem;
    display: grid;
}

.create-offer .searchBar input.form--group::placeholder {
    color: #676767;
}

.create-offer .searchBar input.form--group {
    color: #000;
}

.create-offer button.btn.btn-blue {
    color: #fff;
    font-size: 0.657rem;
    line-height: normal;
    font-weight: 700;
    border-radius: 0;
    border-color: #004577;
    height: 2.705rem;
    margin-top: 1.5rem;
    text-transform: capitalize;
}

.add-product .col-padding {
    padding: 0;
    margin-bottom: 1.17rem;
}

.dashboardView a.btn.btn-blue {
    padding: 0.55rem;
    border-radius: 0;
}

/* inquiry page */
/* .dashboardInquiry  */
.mrktInquiry .inquiryLeftBlock {
    border: 0.063rem solid #C6C6C6;
    max-width: 20.05rem;
    width: 100%;
}

/* .dashboardInquiry  */
.mrktInquiry {
    gap: 1rem;
}

/* .dashboardInquiry  */
.mrktInquiry .inquiryRightBlock {
    border: 0.063rem solid #C6C6C6;
}

.chatListHeader .about_profile.ml-4 {
    margin-left: 0.5rem;
}

.dashboardView .inquiryChatBody::-webkit-scrollbar {
    display: none;
}

/* .dashboardInquiry  */
.dashboardView .sendInquiry form.cmnt-form.replay_cmnt {
    max-width: 36.705rem;
    width: 100%;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    position: absolute;
    right: 0;
    bottom: -6rem;
    border-color: #C6C6C6;
}

.recentOrder .itemTable h4 {
    text-transform: none;
}

/* .dashboardInquiry .iconGroup {
    justify-content: space-between;
} */
.dashboardInquiry .feed-user-info .user__name a {
    font-size: 0.978rem;
    font-weight: 700;
    line-height: 1.067rem;
}

.dashboardInquiry .feed-user-info .user__name span.text-gray {
    font-weight: 500;
    font-size: 0.978rem;
    line-height: 1.067rem;
    padding-top: 0.25rem;
}

.dashboardInquiry .feed-user-info .user__pic {
    max-width: 2.5rem;
    height: auto;
    padding-right: 0.5rem;
}

/* .dashboardInquiry  */
.feed-user-info .user__pic img {
    width: 2.5rem;
    height: auto;
}

.user-badge {
    position: absolute;
    right: 0.5rem;
    top: 7.5rem;
}

.user-badge.free {
    background: #D0C9FF;
    color: #6D58FF;
}

.user-badge.gold {
    background: #FFF4DA;
    color: #FFB709;
}

.user-badge.platinum {
    background: #ECF0F2;
    color: #7C8293;
}

.user-badge.diamond {
    background: #BDE1FE;
    color: #127AD0;
}

.dashboardInquiry .headerLeft h4.storeNm {
    font-size: 0.875rem;
    font-weight: 700;
    line-height: normal;
}

.dashboardView .prdctHead .prdtImg img {
    border-radius: 50%;
}

.dashboardInquiry .product-title h4 {
    /* padding-top: 1rem; */
    color: #000;
}

/* .dashboardInquiry  */
.mrktInquiry {
    margin-top: 1rem;
}

.InquiryHead .flex {
    margin-top: 1rem;
}

/* myorder */
.myOrder .react-tabs {
    margin-top: 1rem;
}

/* notification css */

.shopping-Cart .Qty_List {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.desTitle blockquote {
    margin: 0 !important;
}

.dashboardInquiry .TaxTabFlex .discover__events {
    border-bottom: 1px solid #ddd;
    padding-bottom: 1rem;
}

.shopping-Cart span.increase-decrease-btn {
    border-radius: 50%;
    border: 1px solid #000;
    padding: 0.34rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 1.5625rem;
    width: 1.5625rem;
    font-weight: 700;
    padding-top: 0.25rem;
}

.shopping-Cart button.checkout-btn {
    margin-top: 0;
}

.formGap .form__item label {
    padding-left: 0;
}

.marketplace .configure-store-form.formGap .form__item select {
    border: 1px solid #ddd;
}

.noWhiteSpace {
    white-space: nowrap;
}

.mrkt-place .configure-store-form .form__item:not(:last-child) {
    margin: 0rem !important;
}

.configure-store-form.formGap .form__item textarea {
    min-height: 10rem;
}

.marketplace .configure-store-form.formGap .form__item select {
    height: 3em;
}

.marketplace .configure-store-form .form__item select {
    color: #000;
}

/* New Lead Css Start */
.newLead .dataTitle strong {
    white-space: nowrap;
}

/* New Lead Css End */

/* Disputes Css Start */
.disputesFilter .form__item {
    margin-bottom: 0;
}

.attachItem .slectedFiles {
    left: 2rem;
}

.showDataRow {
    border: 1px solid #C6C6C6;
    padding: 1rem;
    column-gap: 2rem;
    row-gap: 1rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.showDataRow .dataTitle {
    display: flex;
    justify-content: flex-start;
    align-items: baseline;
}

.showDataRow .dataTitle span {
    text-align: left;
}

.dataTitle strong {
    margin-right: 0.75rem;
}

.messageQuery:not(:last-child) {
    border-bottom: 1px solid #ddd;
}

.messageText textarea {
    border: 1px solid #ddd;
    width: 100%;
    padding: 1rem;
}

.replyMessage {
    margin-top: 1.5rem;
}

.messageText {
    margin-bottom: 1rem;
}

.chatButtons {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.attachFile label {
    display: flex;
    align-items: center;
}

.attachFile label input[type="file"] {
    display: none;
}

.chatButtons .attachFile .icon .fa {
    padding-right: 0.75rem;
}

.chatButtons .attachFile .icon {
    cursor: pointer;
}

.chatButtons .attachFile {}

.chatButtons .attachFile .icon {
    cursor: pointer;
}

.rightButtons .btn-white {
    border: 1px solid #ddd;
    min-width: 8rem;
    padding: 0.5rem;
    color: #5B5B5B;
    font-size: 1rem;
    font-weight: 600;
}

.rightButtons .btn-blue {
    min-width: 8rem;
    padding: 0.5rem;
    color: #fff;
    font-size: 1rem;
    font-weight: 600;
    text-transform: capitalize;
    border-radius: 0;
    border: 1px solid #004577;
}

.attachedFile {
    /* margin-top: 0.5rem; */
}

.attachItem input[type="file"] {
    display: none;
}

.attachItem label {
    border: 1.003px solid #C9C9C9;
    height: 2.5rem;
}

/* .attachedFile {
    max-width: 10rem;
    width: 100%;
} */
.attachedFileGroup {
    display: grid;
    column-gap: 1rem;
    row-gap: 1rem;
    grid-template-columns: repeat(4, 1fr);
}

.btnBlueDark {
    background: #004577 !important;
    color: #fff !important;
    font-weight: 700;
    line-height: normal;
    font-size: 0.927rem !important;
    text-transform: capitalize;
    padding: 0.35rem 0.75rem !important;
    min-width: 5rem;
}

.select-item.skyBlue select {
    width: max-content;
}

.allChat {
    height: calc(100vh - 15rem);
    overflow-y: auto;
}

.uploadImg {
    /* max-width: 25%;
    width: 100%; */
    height: 5rem;
    overflow: hidden;
    position: relative;
}

.uploadImg img {
    width: 8rem;
    height: 100%;
}

.attachedFile p,
.attachedFile span .fa {
    color: #004577;
}

.marketplace .configure-store-form .form__item input::placeholder {
    font-weight: normal;
}

.Tax_Tab li.react-tabs__tab--selected .infoIcon {
    color: #fff !important;
}

.tab.home-section .product-list ul li span {
    padding-left: 1rem;
}

/* .attachedFile img{
    width: 100%;
} */
.attachedFile span {
    padding-right: 0.25rem;
}

.messageQuery .prdtDes p {
    font-weight: 700;
}

.dashboardView .skyBlue select {
    background: #4BB3FD !important;
    color: #fff;
    height: auto;
    padding: 0.42rem;
    font-size: 0.75rem;
    font-weight: 700;
    border: none !important;
}

.Status .btn-blue {
    padding: 0.35rem !important;
    border: none;
    font-size: 0.9rem !important;
    background: #004577 !important;
}

.Status.gap-2 {
    gap: 0.825rem;
}

.orderStatus {
    text-transform: capitalize;
}

.iconGroup svg {
    width: 1.7rem;
    height: 1.7rem;
}

.btn-blue.stripe {
    color: #fff;
    text-transform: capitalize;
    padding: 0.5rem;
    border-radius: 0.25rem;
}

.dashboardView .skyBlue select option {
    background: #fff !important;
    color: #000 !important;
}

.disputeList .listItem {
    width: 100%;
    display: flex;
    align-items: center;
}

.disputeList .noCount {
    background: #004577;
    max-width: 1.5rem;
    width: 100%;
    height: 1.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 0.65rem;
}

.disputes .orderStatus {
    min-width: 5rem;
    width: 100%;
    display: inline-block;
    text-align: center;
}

.resolved {
    background: #004577 !important;
    color: #fff;
}

.pendingStatus {
    background: #D0D0D0;
    padding: 0.25rem 1rem;
    border-radius: 2rem;
    color: #000;
    text-transform: capitalize;
}

.Status.gap-2 label {
    font-size: 0.875rem;
}

.resolvedStatus {
    background: #004577;
    padding: 0.25rem 1rem;
    border-radius: 2rem;
    color: #fff;
}

.activeRow td {
    font-weight: 700 !important;
}

.activeRow td .orderStatus {
    font-weight: normal;
}

table tr td {
    text-transform: capitalize;
}

.newSubject span.New {
    background: #4BB3FD;
    color: #fff;
    padding: 0.35rem;
    border-radius: 3rem;
    line-height: 1;
    font-size: 0.75rem;
}

.newSubject {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.uploadImg a svg {
    height: 100%;
    width: 100%;
}

/* wishlist */
.dashboardInquiry h1 {
    text-align: center;
}

.del_Btn {
    color: #f00;
    cursor: pointer;
}

.more_det a {
    border-bottom: 0.0625rem solid #004577;
}

.marketplace.add-product .gallery__images .uploadedImg.editImage {
    height: 12.75rem;
}

.editBlock,
.deleteBlock {
    cursor: pointer;
    position: relative;
}

.MsoNormal b span {
    font-weight: 700 !important;
    color: #000 !important;
}

.add_Btn {
    color: #fff;
    background: #004577;
    font-size: 0.805rem;
    padding: 0.3rem;
    border-radius: 0.4375rem;
    cursor: pointer;
    text-align: center;
}

.itemTable.my_Wishlist th {
    color: #5a5a5a;
    text-align: left;
    border: 0;
    border-bottom: 0.0625REM solid #C6C6C6;
}

.itemTable.my_Wishlist td:not(:last-child) {
    text-align: left;
}

.itemTable.my_Wishlist td {
    border: 0;
}

.itemTable.my_Wishlist table {
    border: 0.0625rem solid #C6C6C6;
}

/* myoffer */
.my_Offers th {
    color: #5A5A5A !important;
}

.my_Offers td:nth-child(2) {
    font-weight: 700 !important;
}

.accept_Btn,
a.accept_Btn {
    background: #37A282;
    color: #fff;
    font-size: 0.805rem;
    padding: 0.575rem;
    border-radius: 0.4375rem;
    min-width: 10.757rem;
    text-align: center;
    font-weight: normal;
    line-height: 1.5;
}

.dashboardView .product-list td:last-child .flexBox .accept_Btn {
    min-width: 7rem;
}

.gallery__images span .fa {
    margin-right: 0.5rem;
}

.slider__gallery .fileUpload.noBg .fa {
    background: transparent;
    color: #5b5b5b;
    width: auto;
    height: auto;
    font-size: 1.5rem;
}

.slider__gallery .fileUpload.ngBg label {
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

a.accept_Btn {}

.itemTable.my_Offers th,
.itemTable.my_Offers td {
    border: 0;
}

.itemTable.my_Offers tr {
    border: 0.0625rem solid #C6C6C6;
}

/*myorder list*/
.dashboardInquiry .iconGroup span svg {
    height: 1.7rem;
    width: auto;
}

/* inquirychat header */
.dashboardInquiry .feed-user-info .user__name a {
    font-size: 0.725rem;
    line-height: normal;
}

.dashboardInquiry .feed-user-info .user__name span.text-gray {
    font-size: 0.725rem;
    line-height: normal;
}

.dashboardInquiry .chatListHeader h4.text-black {
    font-size: 0.725rem;
    line-height: normal;
    font-weight: 500;
}

/* orderview */
.ship_table {
    padding: 1.675rem;
    border: 0.0625rem solid #c6c6c6;
    margin-top: 1.25rem;
}

.order_Span p {
    display: flex;
    justify-content: space-between;
}

.order_Span p:last-child {
    font-weight: 700;
}

table.order_Table tr th,
table.order_Table tr td {
    text-align: left;
}

.ship_table h1 {
    font-size: 0.925rem;
    font-weight: 700;
    line-height: normal;
    color: #000;
}

/* shoppingcart */

.add_Cart table {
    border: 1px solid #c6c6c6;
}

.shopping-Cart .add_Cart tr th {
    border: 0;
    border-bottom: 1px solid #c6c6c6;
    /* color: #7c7c7c; */
}

.shopping-Cart .add_Cart tr td {
    border: 0;
}

.continue_Btn {
    border: 0.0625rem solid #004577;
    border-radius: 0.4375rem;
    color: #004577;
    line-height: normal;
    padding: 0.5rem;
    text-transform: capitalize;
    min-width: 17.05rem;
}

.check_Btn {
    border: 0.0625rem solid #004577;
    border-radius: 0.4375rem;
    background: #004577;
    color: #fff;
    line-height: normal;
    padding: 0.5rem;
    text-transform: capitalize;
    min-width: 17.05rem;
}

.fs-40 {
    font-size: 1.68rem;
}

.last_btn {
    padding: 1.25rem 0;
    display: flex;
    justify-content: space-between;
}

.add_Cart p.remove-btn {
    color: #f00;
    cursor: pointer;
}

.shopping-Cart .add_Cart tr th:first-child {
    text-align: left;
}

.shopping-Cart .add_Cart tr th:nth-child(2) {
    text-align: left;
}

.shopping-Cart .add_Cart tr td:nth-child(2) {
    text-align: left;
}

.shopping-Cart .add_Cart tr td:first-child img {
    float: left;
}

.dashboardView .pagination li a {
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.dashboardView .pagination li.previous a,
.dashboardView .pagination li.next a {
    position: relative;
    color: transparent;
    background: transparent;
}

.dashboardView .pagination li.previous a::before {
    content: "\f104";
    position: absolute;
    left: 0;
    font-family: fontawesome;
    color: #000;
    left: 50%;
    transform: translateX(-50%);
}

.dashboardView .pagination li.active a {
    background: #37A282;
    border-color: #37A282;
    color: #fff;
}

.dashboardView .itemTable table tr .Qty_List_data {
    text-align: center;
}

.item_price {
    width: 10rem;
}

.fileUpload input[type="file"] {
    display: none;
}

label.fileUpload {
    padding: 1rem;
    width: 100%;
    display: inline-block;
    border: 3px dotted #ddd;
    text-align: center;
    cursor: pointer;
    gap: 1rem;
}

label.fileUpload span {
    gap: 1rem;
    display: flex;
    align-items: center;
}

.fileUpload .fa {
    background: #03a776;
    width: 1.75rem;
    height: 1.75rem;
    color: #fff;
    border-radius: 0.25rem;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

label.fileUpload span .fa {
    background: transparent;
    color: #000;
    width: auto;
    height: auto;
}

.dashboardView .pagination li.next a::before {
    content: "\f105";
    position: absolute;
    left: 0;
    font-family: fontawesome;
    color: #000;
    left: 50%;
    transform: translateX(-50%);
}

/* adddetails */
.order__Checkout h1 {
    font-size: 1rem;
    font-weight: 700;
    line-height: normal;
    color: #000;
}

/* backto cart */
.order-Detail {
    padding: 1.5625rem;
    border-bottom: 1px solid #c6c6c6;
}

.orderDetailAddress {
    width: 20rem;
}

p.add_billing {
    background: #fff;
    color: #004577;
    line-height: normal;
    font-size: 0.765rem;
    font-weight: 400;
}

.check_Flex {
    display: flex;
    gap: 1.5625rem;
    background: #fff;
}

.check_Flex .checkout {
    width: 70%;
    border: 0.0625rem solid #c6c6c6;
    background-color: #fff;
}

.check_Flex .checkout_left {
    width: 30%;
    background: #fff;
}

.order_summm {
    border: 0.0625rem solid #c6c6c6;
    position: fixed;
    width: 100%;
    max-width: 21rem;
}

.rightBlockheight {
    height: calc(100vh - 6rem);
    position: relative;
}

.scrollBlock {
    height: calc(100vh - 5.5rem);
    overflow-y: auto;
    background: #fff;
    z-index: 9;
    padding-bottom: 4rem;
}
.scrollBlock::-webkit-scrollbar {
    display: none;
  }
.resetBtn button.pb-0 {
    padding-bottom: 0;
}

.fixedBottom {
    position: absolute;
    width: 100%;
    bottom: 0;
    z-index: 99;
    background: #fff;
}

.dashboardView .custom__tab .react-tabs .discover__events li {
    background: transparent;
    cursor: inherit;
}

.address {
    max-width: 95%;
    width: 100%;
}

.editIcon {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.order-Detail .hub--checkbox--area input[type="checkbox"]:checked+label::after {
    left: 0.5rem;
}

.addtocart {
    padding: 0.5rem;
    font-weight: 500;
    border-radius: 0.25rem;
    background: #004577;
    border-color: #004577;
    color: #fff;
    min-width: 8.5rem;
}

.editIcon .fa {
    font-size: 1rem;
    cursor: pointer;
}

.inner-btn-box p.add_billing {
    font-weight: 600;
}

/* h4 {
    text-transform: capitalize;
} */

.bghjHX {
    margin: 0;
}

.checkDetails {
    display: flex;
    gap: 1rem;
    padding-top: 1.125rem;
    padding-left: 1.125rem;
    padding-right: 1.125rem;
    padding-bottom: 0.605rem;
    /* border-bottom: 1px solid #c6c6c6; */
}

.checkDetails img {
    width: auto;
    height: 4rem;
}

.addressItem ul li .edit_btn {
    margin: 0;
}

.checkDetails p {
    font-size: 0.765rem;
    line-height: normal;
    font-weight: 400;
    color: #000;
    text-transform: capitalize;
}

.sum_Details {
    display: flex;
    justify-content: space-between;
}

p.order_head {
    font-size: 0.902rem;
    font-weight: 700;
    line-height: normal;
    color: #000;
}

.order_total h4 {
    font-size: 0.905rem;
    line-height: normal;
    font-weight: 700;
    color: #004577;
}

.sum_Details p {
    font-size: 0.825rem;
    line-height: normal;
    font-weight: 400;
    color: #000;
    text-transform: capitalize;
}

.order_total {
    /* display: flex; */
    /* justify-content: space-between; */
    border-top: 0.0625rem solid #c6c6c6;
    padding: 0.625rem 1.125rem;
}

.summ_Details {
    padding: 1.125rem;
    padding-top: 0.605rem;
}

p.edit_btn {
    background: #fff;
    color: #004577;
    line-height: normal;
    font-size: 0.765rem;
    font-weight: 400;
}

.edit_Border {
    border: 1px solid #c6c6c6;
    margin-bottom: 1.125rem;
    padding: 0.5rem;
}

/* emptycart */
.shopEmpty {
    text-align: center;
    max-width: 50rem;
    width: 100%;
    margin: auto;
}

.confetti-explosion-container-0-2-308 {
    margin: auto;
}

.centerBlock {
    width: 100%;
    display: flex;
    justify-content: center;
}

.shopEmpty h1 {
    margin-bottom: 1rem;
}

.shopEmpty a {
    border: 0.0625rem solid;
    border-radius: 0.25rem;
    color: #fff;
    line-height: normal;
    padding: 0.5rem;
    background: #37A282;
    border-color: #37A282;
}

.discover_page.newLayout .discover__events .event__list li .event__img img {
    object-fit: inherit;
    padding: 2rem;
    padding-bottom: 0;
}

.discover_page.newLayout .discover__events .event__list li .event__img {
    height: 8rem;
}

.TaxTabFlex .discover__events {
    margin-bottom: 1rem;
}

.custom_modal_width {
    max-width: 40rem;
    width: 100%;
    margin: auto;
}

.noteBlock {
    font-weight: 700;
    color: #000;
}

.textGreen {
    color: #008000 !important;
}

.gallery_data .textGreen {
    text-transform: capitalize;
}

/* .event__deatil.itemCenter {
    text-align: center;
} */
.titleGroup span {
    max-width: 2.5rem;
    width: 100%;
}

.titleGroup a {
    color: #4bb3fd;
}

.discover__events .event__deatil .Host.titleGroup {
    display: flex;
    margin-bottom: 1rem;
    justify-content: center;
}

.discover__events .event__deatil .Host.titleGroup p {
    font-weight: 700;
}

.discover__events .event__deatil.itemCenter .desTitle {
    font-size: 1.5rem;
}

.cartIcon svg {
    margin: auto;
}

.empty-cart .font-16 {
    font-weight: normal;
}

.add_Cart .product-title {
    margin-bottom: 1rem;
}

.cartIcon .fa {
    font-size: 4rem;
    color: #37a282;
}

.add-product .col-padding.mt-0 {
    margin-top: 0;
}

.reqBtn {
    background: #D0D0D0;
    border-radius: 0.4054rem;
    padding: 0.3075rem;
    width: 9rem;
    line-height: 1.5;
    text-align: center;
}

.reqForPay {
    background: #5CAF3E;
    border-radius: 0.4054rem;
    padding: 0.3075rem;
    width: 9rem;
    color: #fff;
    ;
    line-height: 1.5;
    text-align: center;
    cursor: pointer;
}

.cart-product-list img {
    width: 2rem;
    height: auto;
    margin: auto;
}

.innderHeader {
    display: inherit;
}

th.Order__Summary {
    width: 20rem;
}

.ship_table .itemTable tr td,
.ship_table .itemTable tr th {
    text-align: left;
    width: 8rem;
}

.dashboardView.order_mgmt_detail .product-list td:last-child span {
    width: auto;
    display: block;
    text-align: right;
}

.order_mgmt_detail a.btn.btn-blue.royal:first-child {
    min-width: 11rem;
}

.order_mgmt_detail .inner-btn-box a.btn-blue,
.generate_transaction .inner-btn-box a.btn-blue {
    background: #004577;
}

.orderDetailHeader {
    border: 0.0625rem solid #c6c6c6;
    padding: 1.675rem;
}

.orderDetailHeader h1,
.OrderDetailP {
    font-size: 0.857rem;
    line-height: normal;
    font-weight: 700;
    color: #000;
}

.OrderDetailP {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.TransactionDetail {
    margin-top: 1.755rem;
}

.TransTable th.transactionDate {
    width: 24rem;
    text-align: left;
}

.TransTable table tr th:first-child,
.TransTable table tr td:first-child,
.TransTable table tr td:last-child {
    text-align: left;
}

table.CustomDept tr th,
table.CustomDept tr td {
    text-align: left;
}

table.CustomDept th.CustAddress {
    width: 20rem;
}

.CustomDept td.CustomData span {
    text-align: left;
}

.transStatus {
    width: 20rem;
}

.CustDate {
    width: 44rem;
}

.CustDateTable table tr th,
.CustDateTable table tr td {
    text-align: left;
}

.transProduct .transactionPrice {
    text-align: left;
}

.transBilling table tr th,
.transBilling table tr td {
    text-align: left;
}

.TransOrderSum {
    width: 15rem;
}

a.btn.btn-blue.SaveOffer {
    min-width: 9rem;
    background: #37A282;
    border-color: #37A282;
}

a.btn.btn-blue.SaveBtn {
    background: #37A282;
    border-color: #37A282;
}

span.iconRed {
    background: #EB2E2E;
    color: #fff;
}

/* mystore */
.dashboardView .right-banner.bannerImg {
    border-radius: 0.625rem;
}

/* .dashboardView .rightCameraImg {
    display: none;
} */
.addressData {
    font-weight: 700;
}

.addressData span {
    display: flex;
    text-align: left;
    font-weight: normal;
}

.profileCameraIcon .hide-input svg {
    padding: 0.4rem;
    height: 1.705rem;
    width: 1.705rem;
    bottom: 0;
    right: -0.5rem;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    line-height: 2;
    color: #fff;
    border-radius: 50%;
    left: inherit;
    background: #616161;
    transform: translate(-50%, -30%);
}

.Store_Tab .storeBtn {
    display: flex;
    flex-direction: row-reverse;
    width: auto;
    justify-content: center;
    align-items: center;
    height: 2rem;
}

.Store_Tab .storeBtn svg {
    height: 1rem;
    width: 1rem;
}

.Store_Tab ul.react-tabs__tab-list {
    display: flex;
    gap: 0.875rem;
}

.Store_Tab ul.react-tabs__tab-list .react-tabs__tab {
    background: #E1E1E1;
    font-size: 0.705rem;
    line-height: normal;
    font-weight: 700;
    padding: 0.55rem;
    width: 7rem;
    text-align: center;
    cursor: pointer;
}

.Store_Tab ul.react-tabs__tab-list .react-tabs__tab--selected {
    background: #004577;
    color: #fff !important;
}

.Store_Tab .inner-btn-box.ml-auto.w-10 {
    position: relative;
}

.Store_Tab .inner-btn-box a.btn.btn-blue {
    position: absolute;
    top: -3.7rem;
    left: 2.725rem;
}

.SavebtnBox .inner-btn-box a.btn.btn-blue.paymentBtn {
    position: inherit;
    width: auto;
}

/* .SavebtnBox{
    position: relative;
} */
.SavebtnBox .product-title {
    position: absolute;
    margin-top: 0;
    top: 0;
    right: 0;
}

.sum_Details p:not(:last-child) {
    margin-top: 0.25rem;
}

.MessageDis {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form_Field label {
    font-size: 0.605rem;
    font-weight: 700;
    line-height: normal;
}

.form_Field input {
    border: 1px solid #c6c6c6;
    font-size: 0.605rem;
    font-weight: 400;
    line-height: normal;
}

span.editBlock,
span.deleteBlock {
    border-radius: 0.375rem;
    height: 1.7rem;
    width: 1.7rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.taxTitle {
    padding-top: 2.5rem;
}

.tax__mgmt .flexBox {
    justify-content: center;
    gap: 0.5rem;
}

/* mystore paymentform  */
.paymentForm {
    gap: 1.25rem;
    padding: 1.675rem;
    border: 0.0625rem solid #c6c6c6;
}

/* .SavebtnBox {
    position: relative;
} */
.SavebtnBox .paymentBtn {
    /* position: absolute;
    top: -22.75rem;
    right: 0; */
    background: #37A282;
    color: #fff;
    border-radius: 0;
    border-color: #37A282;
    padding: 0.5rem;
    font-size: 0.927rem;
    line-height: normal;
    font-weight: 700;
    text-transform: capitalize;
}

.paymentBtn svg {
    height: 1rem;
    width: 1rem;
}

.formLeft {
    width: 50%;
}

.SearchImport .multiselect-container .search-wrapper {
    border: 0.0625rem solid #c6c6c6 !important;
    border-radius: 0;
}

.ImportCharges {
    position: relative;
}

.ImportCharges .hub--checkbox--area {
    position: absolute;
    display: contents;
}

.ImportCharges .hub--checkbox--area input[type="checkbox"]+label::before {
    top: -0.03rem;
}

.create-offer .productFilter.outer {
    justify-content: space-between;
    position: relative;
}

.dashboardView .form__item textarea,
.dashboardView .form__item select {
    background: #fff;
}

/* addtax class */
.addTax .productFilter {
    justify-content: space-between;
}

.addTax .productFilter .iconGroup span {
    margin-top: 1.5rem;
}

.addTax .productFilter .iconGroup span svg {
    height: auto;
    width: 2rem;
}

.addClassBtn {
    text-align: right;
}

.addClassBtn a.btn-blue {
    color: #fff;
    text-transform: capitalize;
}

.addTax .product-list {
    padding-top: 1.202rem;
}

.dashboardView .form__item textarea.cmnt-write::placeholder {
    color: #676767;
}

.addTax .sm\:w-full.col-padding:last-child {
    padding: 0 0.905rem 0 0.5rem;
}

.addTax .sm\:w-full.col-padding:first-child {
    padding: 0 0.5rem 0 0.905rem;
}

.min_menu-list li.noti_Fy a svg {
    height: 1.5rem;
    width: auto;
}

.inner-btn-box a.btn svg {
    margin-right: 0.35rem;
}

.filterBtn {
    display: flex !important;
}

.filterBtn svg {
    margin-right: 0.35rem;
}

.percentField.w-full {
    padding-top: 1.202rem;
}

.inner-btn-box a.btn.btn-blue svg {
    width: 1rem;
    height: 0.75rem;
}

.product-desc p {
    font-size: 1em !important;
}

/* responsive codecss */
.dashboardView .site-align.top-align {
    flex-wrap: nowrap;
}

/* .addScroll .tab-content-right-section {
	top: -9rem;
} */

.tab-content-right-section .ingredients__List::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.addressDrop .edit_Border {
    cursor: pointer;
    margin-bottom: 0.5rem;
}

.addressItem {
    background: #fff;
    border: 1px solid #ddd;
}

.addressItem ul li {
    padding: 0.25rem;
}

.add_billing.text-blue {
    cursor: pointer;
    padding-left: 0;
}

.addressItem ul li:not(:last-child) {
    border-bottom: 1px solid #ddd;
}

.addressItem ul li span {
    cursor: pointer;
}

.notAvailable td:not(:last-child) {
    background: #f2f2f2;
    opacity: 0.5;
}

.shopping-Cart .notAvailable span.increase-decrease-btn {
    cursor: auto;
}

.shopping-Cart .notAvailable a {
    cursor: auto;
}

.notAvailable .outOfStock {
    padding-left: 0.5rem;
}

.reviewMob .reviw-border .review {
    justify-content: center;
}

.clearBtn {
    min-width: 5rem;
    border: 1px solid #f00;
    color: #f00;
}

.text-red {
    color: #f00;
}

.order-address.order__Checkout {
    position: relative;
    /* opacity: 0.5; */
}

/* .order-address.order__Checkout .loader {
    position: absolute;
    z-index: 9;
    left: 50%;
    top: 35%;
    transform: translate(-50%, -50%);
} */
.order-Detail h1 {
    width: max-content;
    padding-right: 1.5rem;
}

.sum_Details .crossIcon {
    font-size: 0.825rem;
    text-transform: lowercase;
}

.Host.Description .desTitle span span {
    font-weight: 700 !important;
    color: #000 !important;
    padding-left: 0;
}

.dashboardView .productFilter.flexEnd {
    justify-content: flex-end;
}

.loaderInner {
    max-width: 14rem;
    width: 100%;
    margin: auto;
    position: absolute;
    top: 30%;
    left: 50%;
}

.fullPageLoader {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    z-index: 1;
    opacity: 1;
    background: #ffffff91;
}

.loaderInner {
    max-width: 14rem;
    width: 100%;
    margin: auto;
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
}

.fullPageLoader h4 {
    color: #000;
    font-size: 1rem;
    font-weight: 700;
}

.producerScore .product-img a {
    display: inline-block;
}

.card-item-content span.more_count {
    padding: 0.25rem;
    font-size: 0.75rem;
}

.producerScore .product-img .item-img {
    height: 14.5rem;
    overflow: hidden;
}

.card-content-wrapper .paraparahideMob {
    height: 5rem;
    overflow: hidden;
}

.item-img {
    height: 14.5rem;
    overflow: hidden;
}

.item-img img {
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.store__detail {
    padding-left: 1rem;
    padding-right: 1rem;
}

.page_w_right {
    max-width: 28rem;
    margin-left: 1rem;
}

.newGridView.listPage .item-img {
    height: 11rem;
}

.rating__bg .font-12 {
    color: #fff;
}

.newGridView.listPage {}

.producerScore .product-img img {}

.grid4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
}

.grid3 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.5rem;
}

.product-type .prdcr-store-title span {
    font-size: 1.125rem !important;
}

.fs-16 {
    font-size: 1em;
}

.newGridView .product-title {
    font-weight: 500;
}

.newGridView.listPage .card-content-wrapper .paraparahideMob {
    height: auto;
}

.card-content-wrapper .btn.btnTeal.addtocart {
    padding: 0.35rem;
    min-width: 6rem;
    font-weight: 700;
    border-radius: 0;
}

.card-content-wrapper p.rating span {
    padding: 0.35rem;
    line-height: 1;
}

.rating__bg {
    padding: 0;
}

.rating__bg span .fa {
    font-size: 0.75em;
}

.rating__bg span {
    line-height: 1;
    color: #fff;
    padding: 0.35rem;
    font-size: 0.75rem;
}

.card-content-wrapper h4 {
    text-transform: capitalize;
}

.addtocart.withIcon {
    min-width: 12rem;
    font-weight: 700;
}

.addtocart.withIcon .pretext {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.inner-btn-box.border .btn-blue {
    border: 1px solid #4bb3fd;
    background: transparent;
    color: #4bb3fd !important;
    font-weight: 700;
    border-radius: 0.25rem !important;
    padding: 0.5rem !important;
    display: flex;
    align-items: center;
    min-width: 12rem;
}

.inner-btn-box.border a.btn-blue svg {
    width: 1.25rem;
    height: 1.25rem;
}

.inner-btn-box.border {
    border: none;
    padding: 0;
}

.pagination {
    padding-top: 2rem;
}

.card-content-wrapper .storeName {
    max-width: 50%;
    width: 100%;
    text-transform: capitalize;
}

.card-content-wrapper .productName {
    max-width: 70%;
    width: 100%;
}

.productName {
    text-transform: capitalize;
}

.withinfoIcon.onlyUsd .usd {
    right: 1rem;
    top: 1rem;
}

.textBlue {
    color: #004577;
}

.marketplace p.textBlue {
    color: #004577;
}

.itemTotal {
    font-weight: 600;
    padding: 0.5rem;
}

.dashboardView .home-section p.itemTotal {
    font-weight: 600;
}

/* updated css newissue */
td.category-width {
    width: 6.475rem;
}

.important-notes {
    display: flex;
    align-items: center;
}

.important-notes i.fa {
    margin-left: 0.8rem;
    margin-bottom: 0.6rem;
    font-size: 0.7rem;
}

.dashboardView .home-section .rating-field td,
.dashboardView .home-section .rating-field td p {
    font-weight: 700;
}

.send__Enquiry {
    display: flex;
    align-items: center;
    margin-right: 1rem;
}

.sendenquiry__btn .textBlue:hover {
    color: #fff;
}

.sendenquiry__btn .textBlue {
    border-radius: 0.313rem;
    font-weight: 500;
    font-size: 0.75em;
    color: #fff;
    line-height: 1.5;
    padding: 0.325rem 0.625rem;
}

.incoTerms .aadWarning {
    right: -0.75rem;
}

.incoTerms {
    width: max-content;
}

.w-90 {
    width: 90%;
}

.offer__quantity {
    width: 100%;
    max-width: 14rem;
}

.searchBar.form__item span.aadWarning {
    left: -15rem;
}

.create-offer .productFilter {
    align-items: baseline;
}

.dashboardView .react-datepicker-popper .react-datepicker__month-container {
    float: inherit;
}

.dashboardView .react-datepicker-popper span.react-datepicker__aria-live {
    display: none;
}

.create-offer .flexBox {
    justify-content: space-between;
}

.outer select {
    width: 15.15rem;
}

.create-offer .form__item .inner-btn-box {
    margin-top: 1.5rem;
    padding: 0;
}

.ImportCharges .hub--checkbox--area label {
    margin-top: 1.975rem;
}

.create-offer .form__item .aadWarning {
    left: -15rem;
}

.searchImporter {
    width: 15.15rem;
}

.ImportCharges {
    width: 13rem;
}

.dashboardView .form__item textarea.cmnt-write {
    min-height: 0;
    height: 2.025rem;
}

.create-offer .form__item a.btn.btn-blue {
    color: #fff !important;
    font-weight: 700;
    line-height: 1.275;
    font-size: 0.675rem !important;
    text-transform: capitalize;
    margin-top: 0.975rem;
}

.form__item .iconGroup {
    margin-top: 1.625rem;
}

.form__item.offer-price {
    width: 16rem;
}

.form__item.offer-field {
    width: 11.5rem;
}

.form__item.offer-field.destination {
    width: 100%;
}

.form__item.offer-field.destination input {
    width: 20rem;
}

.topSearchbox {
    padding: 0;
    padding-bottom: 0.6rem;
}

.topSearchbox .banner__search-form__center {
    margin: inherit;
}

.topSearchbox .banner__search-form__center .top-search input {
    box-shadow: none;
    border: 0.0625rem solid #EAEAEA;
    padding-top: 0.275rem;
    padding-bottom: 0.5rem;
    min-width: 35.5rem;
}

.top___Title .alert.alert-primary {
    max-width: 27rem;
    margin-left: 0;
}

.topTitle.top___Title {
    justify-content: space-between;
}

.location-map {
    background: #fff;
}

.location-map span.map-icon {
    position: absolute;
}

.location-map input.pac-target-input {
    padding-left: 1rem;
    width: 100%;
}

.hubcity___img .hub--checkbox--area input[type="checkbox"]:checked+label::after {
    left: 0.45rem;
}

td.text-center {
    text-align: center !important;
}

.add-Warning {
    position: relative;
}

.add-Warning i.fa {
    position: absolute;
    top: -1.5rem;
    left: 4rem;
}

.dashboardView span.blockList.active___Btn {
    background: #37A282;
}

.offer__view {
    display: flex;
}

.important__Notes {
    border: 0.0625rem solid #C6C6C6;
    border-left: none;
    width: 9rem;
}

.dashboardView .home-section .important__Notes p.noteHead {
    font-size: 0.75rem !important;
    line-height: 1.35;
    color: #000;
    padding: 0.75rem;
    font-weight: 700;
    border-bottom: 0.0625rem solid #C6C6C6;
}

.dashboardView .home-section .important__Notes p {
    font-weight: 400;
    line-height: 1.5;
    font-size: 0.75rem;
    color: #000;
    padding: 0.75rem;
}

.finalTotal {
    width: max-content;
}

.mrktplaceMembership .storeListData {
    height: auto;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
}

.mrktplaceMembership .storeListData .storeList {
    max-width: 50%;
    width: 100%;
}

.mrktplaceMembership {
    max-width: 52rem;
    width: 100%;
    margin: 2rem auto;
    border-radius: 1.25rem;
}

.mrktplaceMembership .freeMembership {
    border-top-left-radius: 1rem;
    border-top-right-radius: 1rem;
}

.mrktplaceMembership .hub--checkbox--area input[type="checkbox"]:checked+label::after {
    left: 0.5rem;
}

.mrktplaceMembership .form-checkbox-contents {
    border-top: 1px solid #ddd;
    margin-left: 1rem;
    margin-right: 1rem;
    padding-top: 1rem;
}

.mrktplaceMembership .hub--checkbox--area input[type="checkbox"]+label {
    width: auto;
}

.mrktplaceMembership .hub--checkbox--area .fa {
    color: #4BB3FD;
}

.mrktplaceMembership .hub--checkbox--area {
    justify-content: inherit;
}

.mrktplaceMembership .textBlock span {
    font-size: 0.875rem;
}

.mrktplaceMembership {
    border: 0.125rem solid #4bb3fd;
}

.whatsappWeb.demo {
    left: 1rem;
    right: inherit;
    top: inherit;
    bottom: 1rem;
}

.whatsappWeb.demo a {
    left: 1rem;
    right: inherit;
    background: #33A386;
    color: #fff;
    padding: 0.5rem;
    border-radius: 1.5rem;
}

.inner-btn-box.isDisable .btn-blue {
    opacity: 0.5;
}

.navBtn button {
    color: #004577;
    display: flex;
    align-items: center;
    width: 100%;
}

.navBtn button span {
    font-weight: 500;
}

.navBtn button:hover {
    color: #000;
}

.navBtn button:focus {
    outline: none;
    outline-offset: inherit;
}

.copyText {
    background: #fff;
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.infoIcon {
    cursor: pointer;
    color: #000 !important;
}

.sum_Details table tr td {
    padding-right: 0.25rem;
}

/* .infoIcon{}
.ProductTitleName {
	max-width: 13rem;
	width: 100%;
} */
.checkDetails h4 {
    max-width: 70%;
    width: 100%;
}

.create-offer .flexBox.flexStart {
    justify-content: flex-start;
}

.dashboardView .form__item.mb--0 {
    margin-bottom: 0;
}

.contentModal h4 {
    padding-bottom: 1rem;
    padding-top: 0.5rem;
}

.contentModal p {
    margin: 0.5rem 0;
}

form.configure-store-form .form__item p.pl--0 {
    padding-left: 0;
}

.home__page .ingredients__List li.recipe__detail.active_circle .ingredient_img {
    /* border-color: rgb(168, 170, 29) !important; */
    background: linear-gradient(to right, rgb(255, 0, 0), rgb(255, 192, 0));
    /* padding: 0.313rem; */
    padding: 0.25rem;
    border: none !important;
}

.contentModal {
    overflow-y: auto;
    height: 30rem;
    padding-bottom: 2rem;
}

.bgRed.newBadge a {
    color: #fff !important;
}

.text--black {
    color: #000 !important;
}

.customer__name.product span {
    display: inline-block;
    width: 100%;
    text-align: left;
}

.sum_Details table {
    width: 100%;
}

.invitebtn {
    border: 0.125rem solid #004577;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    padding: 0.5rem;
    margin: 0.5rem auto;
    width: 100%;
    justify-content: center;
    gap: 0.5rem;
    background: #fff;
}

/* .addScroll .user__profile__banner img {
    display: none;
} */
.addScroll .discoverPage {
    display: none;
}

.addScroll .left__section-block .connection_scroll {
    background: #fff;
}

.scheduleDemo {
    text-align: right;
}

.scheduleDemo a {
    background: #33A386;
    padding: 0.2rem;
    color: #fff;
    border-radius: 2rem;
    text-transform: uppercase;
    display: inline-block;
    /* display: flex;
	align-items: center;
	justify-content: center; */
    /* width: 12rem; */
    position: relative;
    margin-left: auto;
    padding-right: 1rem;
}

.invitebtn span {
    color: #004577;
}

.scheduleDemo a .flexBox {
    align-items: center;
    justify-content: center;
}

.scheduleDemo a span img {
    width: 75%;
}

.scheduleDemo a span {
    width: 1.75rem;
    height: 1.75rem;
    background: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0.5rem;
}

.scheduleDemo a:hover {
    color: #fff;
}

.chatFooter {
    position: relative;
    margin-top: 1rem;
}

.noCursor {
    cursor: auto !important;
}

.home__page .ingredients__List li.recipe__detail:nth-child(1) .ingredient_img.highLight {
    background: linear-gradient(to right, rgb(255, 0, 0), rgb(255, 192, 0));
    /* padding: 0.313rem; */
    padding: 0.25rem;
    border: none !important;
}

.allProduct .card-content-wrapper .paraparahideMob {
    height: 2.5rem;
}

.marketplace .bghjHX {
    margin: 0 !important;
}

.englishList li {
    margin-bottom: 0.125rem !important;
    /* font-weight: 600 !important;
    font-size: 0.75rem !important; */
}

.englishList {
    /* padding-left: 1rem; */
    margin: 0.5rem 0;
}

.englishList.noDesc li span {
    border-bottom: 1px solid #000;
}

.englishData {
    border-top: 2px dashed;
    border-color: #00000047;
    padding: 0.5rem 0;
    margin-top: 1rem;
}

.borderDotted {
    border-top: 2px dashed;
    border-color: #00000047;
    padding-bottom: 0.25rem;
}

.noBorder {
    border: none;
}

/* .dashboardView .right-banner.bg-gray-200{
    min-height: 16rem;
    height: 100%;
} */

.storeBannerCropper {
    height: 16.65rem;
}

.cover__pics .cropper-crop-box,
.bannerImg .cropper-crop-box,
.right-banner .cropper-crop-box {
    width: 100% !important;
    /* transform: inherit !important; */
    /* width: auto !important; */
}

/* .storeBannerCropper .cropper-crop-box {
    width: auto !important;
} */
.addRightScroll .tab-content-right-section {
    top: -3.5rem;
}

.englishList li::before {
    content: "\f00c";
    position: absolute;
    left: -1.5rem;
    font-family: fontawesome;
    font-size: 0.75rem;
}

.englishList li {
    position: relative;
    list-style: none;
    margin-left: 1.5rem;
}

.englishList.noDesc li::before {
    display: none;
    position: inherit;
    left: 0;
}

.englishList.innerSubList.number li {
    list-style: url;
}

.englishList.innerSubList li::before {
    display: none;
}

.englishList.innerSubList li {
    list-style: circle;
}

.englishList.noDesc li {
    margin-left: 0;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.flagCountry .flagDropDown {
    padding-bottom: 0;
}

/* .cropper-container img{
    max-width: 100% !important;
    width: 100% !important;
}
.cropper-wrap-box{
    max-width: 100% !important;
    width: 100% !important;
    transform: inherit !important; 
} */

.slectedFiles {
    padding-left: 10px;
    font-weight: 600;
}

.selectedCountry .flagCountry {
    max-width: 100%;
}

.flagDropDown span {
    z-index: 9;
}

button .ReactFlagsSelect-module_selectFlag__2q5gC {
    width: 5rem;
}

.countryCode input {
    padding-left: 0;
}

.countryFlagLeft {
    max-width: 50%;
    width: 100%;
}

span.ReactFlagsSelect-module_label__27pw9 {
    max-width: 70%;
    width: 100%;
    padding-left: 1rem;
}

.selectedCountry button {
    border-radius: 0;
}

/* Pricing Table Css Start */
.planRightBlock {
    width: 100%;
    max-width: 55%;
    padding-left: 1.25rem;
}

.planLeftBlock {
    width: 100%;
    max-width: 45%;
    padding-right: 1.25rem;
}

.currentPlan {
    background: #004577;
    padding: 1rem;
    border-radius: 0.3125rem;
}

.currentPlan .flexBox {
    justify-content: space-between;
    margin-bottom: 1.4375rem;
}

.currentPlan .flexBox p {
    font-weight: 400;
    color: #fff;
}

h3.planAmount {
    font-weight: 500;
    color: #fff;
}

.planRightBlock .flexBox {
    justify-content: space-between;
    margin-bottom: 0.5rem;
}

.planRightBlock .progressBar {
    border-top: 0.125rem solid #004577;
    margin-top: 0.5rem;
}

.subscriptionPlans .planRightBlock .usageAmt {
    padding-top: 0.375rem;
}

.planRightBlock .usageTitle {
    margin-bottom: 0.975rem;
}

.subscriptionPlans .fs-18 {
    font-size: 1rem;
    font-weight: 700;
    color: #000;
}

.subscriptionPlans .fs-13 {
    font-size: 0.75rem;
}

.subscriptionPlans .renewsPlan {
    display: flex;
    margin-top: 1.5rem;
    justify-content: space-between;
    align-items: center;
}

.subscriptionPlans .cardList {
    margin-top: 1.5rem;
}

.subscriptionPlans .cardList .subscriptionCard {
    width: 100%;
    max-width: 25%;
    box-shadow: none;
    text-align: center;
}

.subscriptionPlans .btn-blue {
    font-weight: 700;
    color: #004577;
    border: 0.0625rem solid #004577;
    padding: 0.5rem;
    line-height: 1;
    background: #fff;
    border-radius: 0;
    text-transform: capitalize;
}

.subscription_Benefit {
    text-align: center;
    margin-top: 2rem;
}

.subscription_Benefit .btn {
    color: #000;
    width: inherit;
    min-width: 12rem;
    border: 0.0625rem solid #E2E2E2;
    background: #F8F8F8;
}

.planTopBlock {
    border-bottom: 0.0625rem solid #C5BEBE;
}

.upgradeTitle {
    text-align: center;
    padding-top: 1.5rem;
}

/* benefitModal css */
.benefitModal a.close_btn--modal {
    background: none;
}

.benefitModal a.close_btn--modal::after {
    color: #000;
    font-size: 1.125rem;
    font-weight: 400;
}

.benefitModal .popup_header {
    box-shadow: none;
}

.benefitModal .subscriptionCard .pricingTableRow .fa-times {
    color: #F44336;
}

.benefitModal .memberButton.pricingTableRow {
    padding-top: 0;
}

.benefitModal .moreInfo {
    margin-top: 0;
    font-size: 0.975rem;
}

.admin_approval {
    position: relative;
}

.admin_approval .overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 35, 82, 0.7);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
}

.overlay_content {
    color: #FFF;
    left: auto;
    top: 10%;
    position: relative;
    text-align: center;
}

.overlay_content p {
    color: #2a2a2a;
}

.error_message {
    color: red;
}

.success_message {
    color: green;
}

.dashboardView .itemTable table tr td img.full-width {
    width: 100%;
}

/* Pricing Table Css End */
/* Logo Slider css start*/
.partnerView {
    overflow: hidden;
}

.partnerLogoContainer {
    display: flex;
    animation: scrolling 20s linear infinite;
}

.partnerLogo {
    flex: 0 0 15vw;
    margin: 0 3vw;

}

.partnerLogoContainer:hover .partnerLogo {
    animation-play-state: paused;
}

.dashboardView .itemTable .flexBox span.disable {
    background-color: #bdbdbd;
    cursor: initial;
}

.slick-arrow {
    display: none !important;
}

@keyframes scrolling {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translatex(-50vw);
    }
}

/* Logo Slider css end*/
/* subscription modal css */
.subs-modal-title {
    font-size: 1.7rem;
    font-weight: 800;
}

/* subscription modal css end*/
/* slick slider css */
.slick-track {
    display: flex;
}
.video-slider .slick-arrow{
    display : flex !important;
}
/* Position arrows at the bottom of the slider */
.slick-arrow {
    position: absolute;
    bottom: -35px; /* Adjust this value to position below the dots */
    z-index: 1000;
    color: #000;
    border: 2px solid #000;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  /* Center the arrows horizontally */
  .slick-prev {
    left: 47%;
  }
  
  .slick-next {
    right: 47%;
  }
  
  /* Hover effect */
  .slick-arrow:hover {
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
  }
  
  .slick-prev.slick-disabled,
  .slick-next.slick-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
/* slick slider css end */


@media screen and (max-width: 767px) {
    .slick-prev {
        left: 40%;
      }
      
      .slick-next {
        right: 40%;
      }
    .whatsapp-icon {
        margin-top: 10px;
    }

    .whatsapp_float {
        width: 40px;
        height: 40px;
        bottom: 20px;
        right: 10px;
        font-size: 22px;
    }

    .user-badge {
        position: absolute;
        right: 0.5rem;
        top: 7.5rem;
    }
    .leading-heading{
        line-height:38px;
    }
    
    .w-55,.w-45{
        width: 100%
    }
    .navBar{
        @apply p-4
    }
    .navBar-list.active{
    left: 0;
    }
    .navBar-list{
        flex-direction: column;
        position: absolute;
        background: #fff;
        width: 100%;
        top: 70px;
        left: -600px;
        padding: 1rem;
        height: 100vh;
        transition: all 2ms;
        z-index: 5;
        border-top: 1px solid #d9d9d9;
        font-size: 16px;
        height: calc(100vh - 4rem);
        overflow-y: auto;
    }
    .navBar.top .navBar-list{
        color: black
    }
    .btn-royal{
        @apply px-3 py-2;
    }
    .flexRow{
        flex-direction: column;
    }
    .flexRowReverse{
        flex-direction: column;
    }
    .contact-container{
        height: calc(100vh - 4rem);
        overflow-y: auto;
    }
    .footer-logo{
        @apply h-10 static ;
        transform: none;
      }
     
}